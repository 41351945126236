import { ButtonTypes } from "../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { GridActionEnum } from "../../../enums/GridActionEnum";
import { StakeholdersGridModel } from "./StakeholdersGridView_model";

export const GetStakeholdersGridActions = (model: StakeholdersGridModel) => {
  return [
    {
      id: GridActionEnum.add,
      label: I18n.t("phrases.add"),
      type: "uiAction",
      onAction: ev => model.showStakeholderFormModal(),
      componentProps: {
        type: ButtonTypes.PRIMARY,
        symbol: IconSymbols.Plus
      },
      disabledWhenNoItemSelected: false,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.email,
      label: I18n.t("phrases.email"),
      type: "uiAction",
      onAction: ev => model.showEmailsModal(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Mail
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.delete,
      label: I18n.t("phrases.delete"),
      type: "uiAction",
      onAction: ev => model.showStakeholderConfirmDeleteModal(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Trash
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.export,
      label: I18n.t("phrases.export"),
      type: "uiAction",
      onAction: ev => model.exportRows(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Download
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: "devider",
      label: "",
      type: "devider",
      onAction: ev => {},
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.textWrap,
      label: I18n.t("phrases.textWrap"),
      type: "uiAction",
      onAction: ev => {
        model.textWrapAddon.toggleTextWrap();
        model.generateActions();
      },
      componentProps: {
        type: model.textWrapAddon.isWrappedText ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY,
        symbol: IconSymbols.TextWrap
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.clearFilters,
      label: I18n.t("phrases.clearFilters"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.filterStoreAddon.clearFilters();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.ClearFilter,
        isDisabled: !model.isFilterChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.resetColumns,
      label: I18n.t("phrases.resetColumns"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.columnStateAddon.resetColumns();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.MonitorBack,
        isDisabled: !model.isColumnStateChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    }
  ];
};
