import { ITimelineItem } from "@flightpath/coreui/dist/widgets/timeline/Timeline_model";
import React from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import { Link } from "react-router-dom";
import Pages from "../../../../../../../routes/InsightRoutes";

export const TimelineItem: {
  (orgId: number, shouldNotLink?: boolean): React.FC<ReactCalendarItemRendererProps<ITimelineItem>>;
} = (organisationId, shouldNotLink) => props => {
  const { item, getItemProps } = props;
  const itemProps = getItemProps({});
  const top = parseInt((itemProps.style.top as string).replace("px", ""));
  itemProps.style = {
    ...itemProps.style,
    height: "12px",
    lineHeight: "12px",
    background: "#58C8D1",
    border: `1px solid ${"#58C8D1"}`,
    top: top + 6 + "px"
  };

  const key = itemProps.key;
  delete itemProps.key;
  if (shouldNotLink) {
    return <div key={key} {...itemProps} title={null}></div>;
  }

  const splitItem = item.id.toString().split("_");
  const itemParentId = splitItem.length > 1 && +splitItem[0];
  const itemId = itemParentId ? +splitItem[1] : +splitItem[0];
  const itemUrl = !!itemParentId
    ? Pages.projects.index.generateLink(organisationId, itemId)
    : Pages.programmes.index.generateLink(organisationId, itemId);

  return (
    <Link key={item.id} target="_blank" to={`${itemUrl}`}>
      <div {...itemProps} title={null}></div>
    </Link>
  );
};
