import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { ReadinessUploaderModel, ReadinessUploaderViews } from "./ReadinessUploader_model";
import "./_readiness-uploader.scss";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Tooltip } from "../../../../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "@flightpath/coreui";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import I18n from "../../../../../../core/localization/I18n";

interface ReadinessUploaderProps {
  pulseId: number;
  onAssignSuccess: any;
}

export const ReadinessUploader: React.FC<ReadinessUploaderProps> = observer(({ pulseId, onAssignSuccess }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ReadinessUploaderModel(organisationId, +projectId, pulseId, onAssignSuccess));

  return (
    <div className="readiness-uploader pb-5 px-7">
      <div className="mb-3 d-flex align-items-center">
        <Button onClick={model.downloadTemplate} type={ButtonTypes.OUTLINE_PRIMARY}>
          <Icon symbol={IconSymbols.Download} /> <span className="ml-3">Download Template</span>
        </Button>
        <Tooltip
          position={TooltipPositions.RIGHT}
          shownElement={<Icon symbol={IconSymbols.Info} size={UiSizes.MD} style={{ marginLeft: "8px" }} />}
          triggeredOn="hover"
        >
          <Panel.Panel
            style={{ width: "400px" }}
            className="py-1 px-3"
            background={Panel.PanelBackgrounds.BG_PRIMARY_LIGHT}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <ul style={{ listStyleType: "number" }}>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_1")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_2")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_3")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_4")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_5")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_6")}</li>
            </ul>
            <p className="p-1 mt-1">
              {I18n.t("phrases.noteLabelCap")} <em>{I18n.t("phrases.uploadTemplateInstruction_7")}</em>
            </p>
          </Panel.Panel>
        </Tooltip>
      </div>
      <FileDrop onDrop={(files, event) => model.processFiles(files, event)}>
        <IF condition={model.viewState === ReadinessUploaderViews.IsUploading}>
          <div className="readiness-uploader__progress">
            <PositionedSpinner />
          </div>
        </IF>

        <IF condition={model.viewState === ReadinessUploaderViews.Initial}>
          <div className="readiness-uploader__drop-area">
            <h2>{I18n.t("phrases.uploadReadiness")}</h2>
            <p>{I18n.t("phrases.uploadReadinessPrompt")}</p>
          </div>
        </IF>
        <IF condition={model.viewState === ReadinessUploaderViews.IsNotValidFileError}>
          <div className="readiness-uploader__drop-area">
            <h2>{I18n.t("phrases.uploadError")}</h2>
            <p>{I18n.t("phrases.wrongFileTypeOnlyCsv")}</p>
            <Button
              onClick={() => model.setViewState(ReadinessUploaderViews.Initial)}
              type={ButtonTypes.OUTLINE_PRIMARY}
            >
              {I18n.t("phrases.tryAgain")}
            </Button>
          </div>
        </IF>
        <IF condition={model.viewState === ReadinessUploaderViews.InvalidDataError}>
          <div className="readiness-uploader__drop-area">
            <h2>{I18n.t("phrases.uploadError")}</h2>
            <p>{I18n.t("phrases.invalidDataFileUploadError")}</p>
            <Button
              onClick={() => model.setViewState(ReadinessUploaderViews.Initial)}
              type={ButtonTypes.OUTLINE_PRIMARY}
            >
              {I18n.t("phrases.tryAgain")}
            </Button>
          </div>
        </IF>

        <IF condition={model.viewState === ReadinessUploaderViews.IsNotOneFileError}>
          <div className="readiness-uploader__drop-area">
            <h2>{I18n.t("phrases.uploadError")}</h2>
            <p>{I18n.t("phrases.moreThanOneFileUploadedError")}</p>
            <Button
              onClick={() => model.setViewState(ReadinessUploaderViews.Initial)}
              type={ButtonTypes.OUTLINE_PRIMARY}
            >
              {I18n.t("phrases.tryAgain")}
            </Button>
          </div>
        </IF>

        <IF condition={model.viewState === ReadinessUploaderViews.InvalidColumnError}>
          <div className="readiness-uploader__drop-area">
            <h2>{I18n.t("phrases.uploadError")}</h2>
            <p>{I18n.t("phrases.invalidColumnUploadedError")}</p>
            <Button
              onClick={() => model.setViewState(ReadinessUploaderViews.Initial)}
              type={ButtonTypes.OUTLINE_PRIMARY}
            >
              {I18n.t("phrases.tryAgain")}
            </Button>
          </div>
        </IF>
      </FileDrop>
    </div>
  );
});
