import { useParams } from "react-router-dom";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Panel } from "../../../../../components/ui/Panel";
import I18n from "../../../../../core/localization/I18n";
import { UiSizes } from "../../../../../enums";
import Pages from "../../../../../routes/InsightRoutes";
import { StakeholderColDefFieldNamesEnum } from "../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";

const stakeholderMapUrl = (organisationId: number, projectId: number, tabId?: number, mapId?: number) => {
  return Pages.projects.stakeholders.visualisations.parameters.generateLink(
    organisationId,
    projectId,
    tabId || 1,
    mapId || 0
  );
};

export const QuickLinksPanel: React.FC<{
  impactPercentComplete: number;
  actionPercentComplete: number;
  stakeholdersTotal: number;
  audiencesTotal: number;
  firstName: string;
  lastName: string;
}> = ({ impactPercentComplete, firstName, lastName, actionPercentComplete, stakeholdersTotal, audiencesTotal }) => {
  const { organisationId, projectId } = useParams();
  return (
    <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3">
      <h3>Quick Links</h3>
      {isNaN(impactPercentComplete) && isNaN(actionPercentComplete) && !stakeholdersTotal && !audiencesTotal ? (
        <small>{I18n.t("phrases.projectDashboardNoQuickLinks")}</small>
      ) : null}
      {!isNaN(impactPercentComplete) ? (
        <LinkButton
          href={`${Pages.projects.impacts.listView.generateLink(
            organisationId,
            projectId
          )}?owner=${firstName}%20${lastName}`}
          target="_blank"
          size={UiSizes.MD}
          type={ButtonTypes.OUTLINE_PRIMARY}
          className="mb-2 mr-2"
        >
          <small>{I18n.get("phrases.myImpacts")}</small>
        </LinkButton>
      ) : null}

      {!isNaN(actionPercentComplete) ? (
        <>
          <LinkButton
            href={`${Pages.projects.actions.listView.generateLink(
              organisationId,
              projectId
            )}?owner=${firstName}%20${lastName}`}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.myActions")}</small>
          </LinkButton>
          <LinkButton
            href={`${Pages.projects.actions.listView.generateLink(
              organisationId,
              projectId
            )}?assignedToName=${firstName}%20${lastName}`}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.assignedActions")}</small>
          </LinkButton>
        </>
      ) : null}
      {!!stakeholdersTotal ? (
        <>
          <LinkButton
            href={`${Pages.projects.stakeholders.listView.generateLink(organisationId, projectId)}?${
              StakeholderColDefFieldNamesEnum.OwnerNames
            }=${firstName}%20${lastName}`}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.myStakeholders")}</small>
          </LinkButton>

          <LinkButton
            href={stakeholderMapUrl(+organisationId, +projectId, 1, 3)}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.commitmentStakeholderMap")}</small>
          </LinkButton>
          <LinkButton
            href={stakeholderMapUrl(+organisationId, +projectId, 1, 1)}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.sentimentStakeholderMap")}</small>
          </LinkButton>
          <LinkButton
            href={stakeholderMapUrl(+organisationId, +projectId, 1, 2)}
            target="_blank"
            type={ButtonTypes.OUTLINE_PRIMARY}
            size={UiSizes.MD}
            className="mr-2 mb-2"
          >
            <small>{I18n.t("phrases.receptivenessStakeholderMap")}</small>
          </LinkButton>
        </>
      ) : null}
      {!!audiencesTotal ? (
        <LinkButton
          href={`${Pages.projects.audiences.listView.generateLink(organisationId, projectId)}?`}
          target="_blank"
          type={ButtonTypes.OUTLINE_PRIMARY}
          size={UiSizes.MD}
          className="mr-2 mb-2"
        >
          <small>{I18n.t("phrases.audiences")}</small>
        </LinkButton>
      ) : null}
    </Panel.Panel>
  );
};
