import { Panel } from "../../../../../../../../components/ui/Panel";
import { Animations } from "../../../../../../../util/Animations";
import { ActionLinkModal } from "./ActionLinkModal_view";

export const TRAINING_COURSE_ACTIONS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  trainingCourse: FP.Entities.ITrainingCourse,
  onAssignSuccess?: any
) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    componentProps: {
      wrapHeight: "large",
      wrapWidth: "large",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_LIGHT,
        className: "h-auto min-h-100",
        hasShadow: true
      }
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    },
    content: (
      <ActionLinkModal
        projectId={projectId}
        trainingCourseId={trainingCourse.id}
        trainingCourse={trainingCourse}
        onAssignSuccess={onAssignSuccess}
      />
    )
  };
};
