import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const OWNER_NAME_COLUMN_CONFIG: (field: string, list: any) => ISdColDef<any, any> = (field, list) => {
  return {
    cellEditorParams: {
      field: field,
      getValueLabel: (ee: any) => {
        if (!list) return "";

        const user = list.find(e => e.id === ee.id);
        if (!user) return "";
        return user.firstName + " " + user.lastName;
      },
      options: !!list ? list : [],
      others: list,
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const OWNER_NAME_FILTER_CONFIG: ISdFilterDef = {};
