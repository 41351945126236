import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BenefitField } from "../../../../enums";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class BenefitApi extends FilteredProjectApiModel<FP.Entities.IBenefit> {
  controller: string = "benefits";

  constructor(http: AxiosInstance) {
    super(http, "benefits");
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    benefitId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: BenefitField,
    config?: AxiosRequestConfig
  ):Promise<IFlightPathApiResponse<FP.Entities.IBenefit>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      numericField : numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/${field}`,
      data,
      config
    );
    return res.data;
  };
  deleteField = async (
    organisationId: number,
    projectId: number,
    benefitIds: number,
    field: BenefitField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitIds}/${field}`,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  getDetailedById = async (
    organisationId: number,
    projectId: number,
    benefitId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IBenefit>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}`;
    let res = await this.http.get(url);
    return res.data;
  };

  getGridData = async (organisationId: number, projectId: number, config?: any) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/grid-data`,
      config
    );
    return res.data;
  };
   getComments = async (organisationId: number, projectId: number, benefitId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/comments`,
      config
    );
    return res.data;
  };
   addComment = async (
    organisationId: number,
    projectId: number,
    benefitId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/comments`,
      comment,
      config
    );
    return res.data;
  };
}

const instance = new BenefitApi(http);
export default instance;
