import { action, makeObservable, observable } from "mobx";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import I18n from "../../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { getProjectProcessScopeItemsSidebarFields } from "./ScopeItemsSidebar_fields";

export class ScopeItemsSidebarModel {
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref scopeItems: FP.Entities.IScopeItem[];
  @observable searchValue: string = "";
  organisationId: number;
  projectId: number;
  projectProcessProvider: IProjectProcessesApi;
  formModel: SingleFormModel;
  projectProcessId: number;
  process: FP.Entities.IProjectProcess;
  /**
   *
   */
  constructor(organisationId: number, projectId: number, process: FP.Entities.IProjectProcess) {
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.isLoading = true;
    this.process = process;
    this.projectProcessId = process.id;
    this.initForm();
  }

  onMount = async () => {
    await this.loadScopeItems(this.projectProcessId);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.hideSearchMode();
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
  };

  @action
  setScopeItems = (scopeItems: FP.Entities.IScopeItem[]) => {
    this.scopeItems = scopeItems;

    this.isLoading = false;
  };

  @action
  loadScopeItems = async (processId: number) => {
    let res = await this.projectProcessProvider.getScopeItems(this.organisationId, this.projectId, processId);
    if (!res || res.isError) return;

    this.setScopeItems(res.payload);
    this.setFormModel();
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getProjectProcessScopeItemsSidebarFields(
      this.organisationId,
      this.projectId,
      this.projectProcessProvider,
      this.process,
      this.scopeItems,
      this.loadScopeItems
    );
  };
}
