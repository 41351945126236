import { action, makeObservable, observable } from "mobx";
import EulaApi, { EulaApi as IEulaApi } from "../../../../services/api/v2/eulaApi/eula.api";

export class EulaModel {
  eulaProvider: IEulaApi;
  @observable eula: any[] = [];
  @observable isAccepted: boolean = false;
  @observable isChecked: boolean = false;
  @observable isDisabled: boolean = true;
  @observable isLoading: boolean = true;

  constructor() {
    makeObservable(this);
    this.eulaProvider = EulaApi;
  }

  @action
  onMount = async () => {
    await this.loadLatestEula();
  };

  @action
  onScrollBottom = (event: React.ChangeEvent): void => {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    if (event.target.scrollTop === limit) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
      this.isChecked = false;
    }
  };

  @action
  loadLatestEula = async () => {
    let res = await this.eulaProvider.getCurrentEula();
    if (!res) return;

    this.setLatestEula(res.payload);
  };

  @action
  addUserEula = async () => {
    await this.eulaProvider.acceptEula();
  };

  @action
  setLatestEula = async (eula: any[]) => {
    this.eula = eula;
    this.isLoading = false;
  };

  @action
  setIsChecked = (isChecked: boolean) => {
    this.isChecked = isChecked;
  };

  @action
  onClickProceed = async () => {
    this.isAccepted = true;
    await this.addUserEula();
    window.location.reload();
  };
}
