import React, { useEffect, useState } from "react";
import { StakeholdersSettingsModel } from "./StakeholdersSettings_model";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { Tabs, Tab } from "../../../../../components/ui/Tabs";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { UploadFile } from "./uploadFile/UploadFile_view";
import { UploadGrid } from "./uploadGrid/UploadGrid_view";
import { UploadFileList } from "./uploadFileList/UploadFileList_view";
import Pages from "../../../../../routes/InsightRoutes";
import { StakeholdersGrid } from "../../../../../core/grids/builder/directorBuilder/grids/StakeholdersGrid/StakeholdersGridView_view";

export interface StakeholdersProps {}

const StakeholdersSettings: React.FunctionComponent<StakeholdersProps> = observer(() => {
  const organisation = useCurrentOrganisation();
  const [model] = useState(() => new StakeholdersSettingsModel(organisation.id));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
    // eslint-disable-next-line
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  const setSelectFileId = (id: number) => {
    model.selectedFileId = id;
  };

  return (
    <div className="stakeholder-settings">
      <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>
              {organisation.name} | {I18n.t("phrases.manageStakeholders")}
            </h1>
            <p>{I18n.t("phrases.manageStakeholdersDescription")}</p>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`${Pages.dashboards.index.generateLink(organisation.id)}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Tabs className="tabs--primary mb-5" ulClassName="tabs__menu--primary" liClassName="tabs__item--primary">
        <Tab
          key="individual-tab-item"
          data-testid="individual"
          title={<h2 className="mb-0">{I18n.t("entities.people")}</h2>}
        >
          <div className="people-grid-view insight-tab-content__space-filler" style={{ height: "100%" }}>
            <StakeholdersGrid
              data={model.stakeholders}
              isLoading={model.isLoading}
              refreshDataHandler={model.loadStakeholders}
              orgUsers={model.orgUsers}
              orgBusinessAreas={model.orgBusinessAreas}
              orgRoles={model.orgRoles}
            />
          </div>
        </Tab>
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.uploadPeople")}</h2>} key={2}>
          <div className="organisation-stakeholders--upload row mr-0 ml-0">
            <div className="col-9 pl-0 pr-0">
              {model.selectedFileId === 0 && <UploadFile organisationId={organisation.id} />}
              {model.selectedFileId > 0 && (
                <UploadGrid organisationId={organisation.id} fileId={model.selectedFileId} key={model.selectedFileId} />
              )}
            </div>
            <div className="col-3 organisation-stakeholders--upload--file-list">
              <UploadFileList
                selectedFileId={model.selectedFileId}
                onClickFn={setSelectFileId}
                organisationId={organisation.id}
              />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
});

export { StakeholdersSettings };
