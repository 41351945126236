import { LineSvgProps, ResponsiveLine } from "@nivo/line";
import * as _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { ForwardRefRenderFunction, FunctionComponent, useMemo, useState } from "react";
import { Panel } from "../../../components/ui/Panel";
import { minimumDate } from "../../../enums";
import {
  IReadinessLineChartGroup,
  IReadinessLineChartGroupMember,
  IReadinessLineChartPoints
} from "../../../pages/insight/projects/AudiencesView/ReadinessChartsView/ReadinessChartsView_model";
import { stringToMomentDateForComparison } from "../../grids/builder/directorBuilder/utils/helpers";
import "./_readiness-line-chart.scss";

interface ReadinessLineChartProps {
  data: IReadinessLineChartPoints[];
  groups: IReadinessLineChartGroup[];
  dateRange: any;
  groupMembers: IReadinessLineChartGroupMember[];
  markers: any[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  dateFormat?: string;
}

const colors = ["#22D5C3", "#B340E0", "#40A9E0", "#A0E040", "#0011A3"];
const userLang = window.navigator.language;
const defaultDateFormat = "DD/MM/YYYY";
const dateFormat = `${userLang === "en-US" ? "MM/DD/YYYY" : defaultDateFormat}`;

const ReadinessLineChartView: ForwardRefRenderFunction<unknown, ReadinessLineChartProps> = ({
  data,
  groups,
  dateRange,
  groupMembers,
  markers,
  xAxisLabel,
  yAxisLabel
}) => {
  const [hiddenLegendsClicked, setHiddenLegendsClicked] = useState<string[]>([]);
  const [activeLegendHovered, setActiveLegendHovered] = useState<string>(null);

  const pulseMap = useMemo(
    () =>
      groupMembers.reduce((acc, pulse) => {
        const pulseDate = moment(pulse.startDate, defaultDateFormat).format(dateFormat);
        acc[pulseDate] = pulse.name;
        return acc;
      }, {}),
    [groupMembers]
  );

  const lineChartProps: LineSvgProps = {
    curve: "monotoneX",
    data: data,
    enableSlices: "x",
    margin: { top: 50, right: 110, bottom: 110, left: 60 },
    xFormat: `time:%d/%m/%Y`,
    xScale: {
      type: "time",
      format: `%d/%m/%Y`,
      precision: "day",
      useUTC: false,
      min: moment(dateRange.startDate, defaultDateFormat).toDate(),
      max: moment(dateRange.endDate, defaultDateFormat).toDate()
    },
    yScale: {
      type: "linear",
      min: 0,
      max: 7,
      stacked: false,
      reverse: false
    },
    axisTop: null,
    axisRight: null,
    axisBottom: {
      format: tickValue => {
        const tickValueFormatted = moment(tickValue, defaultDateFormat).format(dateFormat);

        const startDateFormatted = moment(dateRange.startDate, defaultDateFormat).format(dateFormat);

        if (!pulseMap[startDateFormatted]) {
          pulseMap[startDateFormatted] = startDateFormatted;
        }

        const res = pulseMap[tickValueFormatted] || tickValueFormatted;

        // check if the same to hide the dates from the x axis
        // if true means that the tick value is a date and we don't want to show it
        return res;
      },
      legend: xAxisLabel ?? "",
      legendOffset: 80,
      legendPosition: "middle",
      tickRotation: 45,
      tickValues: [
        moment(dateRange.startDate, defaultDateFormat).toDate(),
        ...groupMembers
          .filter(e => stringToMomentDateForComparison(e.startDate) > stringToMomentDateForComparison(minimumDate))
          .map(groupMember => moment(groupMember.startDate, defaultDateFormat).toDate()),
        moment(dateRange.endDate, defaultDateFormat).toDate()
      ]
    },
    axisLeft: {
      tickSize: 5,
      tickValues: [1, 2, 3, 4, 5],
      tickPadding: 5,
      tickRotation: 0,
      legend: yAxisLabel ?? "",
      legendOffset: -40,
      legendPosition: "middle",
      truncateTickAt: 0
    },
    pointSize: 5,
    pointColor: { theme: "background" },
    pointBorderWidth: 5,
    pointBorderColor: { from: "serieColor" },
    pointLabel: "y",
    lineWidth: 1,
    enableTouchCrosshair: true,
    useMesh: true,
    legends: [
      {
        data: data.map((e, i) => {
          return { id: e.id, label: getInforLabel(e.name), color: colors[i] ?? "black" };
        }),
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 105,
        translateY: -40,
        itemsSpacing: 5,
        itemDirection: "left-to-right",
        itemWidth: 100,
        itemHeight: 25,
        itemOpacity: 0.75,
        symbolSize: 18,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        toggleSerie: true,
        onClick: (legend, ev) => {
          const idx = hiddenLegendsClicked.indexOf(_.lowerCase(legend.label.toString()));
          const lowercaseLbl = _.lowerCase(legend.label.toString());
          if (!(idx > -1)) {
            setHiddenLegendsClicked([...hiddenLegendsClicked, lowercaseLbl]);
            (ev.target as any).closest("g").querySelector("text").style.opacity = "0.5";
          } else {
            setHiddenLegendsClicked([...hiddenLegendsClicked.filter(e => e !== lowercaseLbl)]);
            (ev.target as any).closest("g").querySelector("text").style.opacity = "1";
          }
        },
        onMouseEnter: (legend, ev) => {
          setActiveLegendHovered(getInforLabelReverse(_.lowerCase(legend.label.toString())));
        },
        onMouseLeave: () => {
          setActiveLegendHovered(null);
        },
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
              symbolSize: 20
            }
          }
        ]
      }
    ],
    colors: colors,
    markers: groupSeparator(groups, dateFormat, markers),
    layers: [
      "grid",
      "axes",
      "legends",
      ({ xScale, yScale }) => (
        <TargetHorizontalMarker
          xScale={xScale}
          yScale={yScale}
          groups={groups}
          dateRange={dateRange}
          dateFormat={dateFormat}
          activeLegendHovered={activeLegendHovered}
        />
      ),
      "markers",
      DashedSolidLine,
      "points"
    ]
  };

  return (
    <Panel.Panel className="m-0 container-fluid" background={Panel.PanelBackgrounds.BG_LIGHT} hasShadow={true}>
      <div className="wrapper">
        <div className="graphContainer">
          <ResponsiveLine {...lineChartProps} />
        </div>
      </div>
    </Panel.Panel>
  );
};

const groupSeparator = (groups, dateFormat, extraMarkers) => {
  const markerList = [];

  for (var i = 0; i < groups.length; i++) {
    markerList.push({
      axis: "x",
      value: moment(groups[i].startDate, defaultDateFormat).toDate(),
      legend: groups[i].name + " (" + moment(groups[i].startDate, defaultDateFormat).format(dateFormat) + ")",
      lineStyle: {
        stroke: "rgba(20, 112, 192, 0.6)",
        strokeWidth: 2,
        opacity: 1,
        zIndex: 0
      },
      legendOrientation: "horizontal",
      legendOffsetY: 0,
      legendOffsetX: 0,
      textStyle: {
        fontSize: 12,
        fill: "rgba(20, 112, 192, 0.6)",
        transform: `translate(7px, ${100}px) rotate(-90deg)`,
        transformOrigin: "20px 20px"
      }
    });
  }

  _.forEach(extraMarkers, (marker, index) => {
    let momentMarkerDate = moment(marker.deadline);
    markerList.push({
      axis: "x",
      value: momentMarkerDate.toDate(),
      legend: marker.name + " (" + momentMarkerDate.format(dateFormat) + ")",
      lineStyle: {
        stroke: "#1ac541",
        strokeWidth: 2,
        opacity: 1,
        zIndex: 0
      },
      legendOrientation: "horizontal",
      legendOffsetY: 0,
      legendOffsetX: 0,
      textStyle: {
        fontSize: 12,
        fill: "#1ac541",
        transform: `translate(7px, ${100}px) rotate(-90deg)`,
        transformOrigin: "20px 20px"
      }
    });
  });

  markerList.push({
    axis: "x",
    value: moment().toDate(),
    legend: "Today",
    lineStyle: {
      stroke: "rgba(255, 153, 0, 0.8)",
      strokeWidth: 2,
      opacity: 1,
      zIndex: 0
    },
    legendOrientation: "vertical",
    legendOffsetY: 10,
    legendOffsetX: 0,
    textStyle: {
      fontSize: 12,
      fill: "rgba(255, 153, 0, 0.8)",
      transform: `translate(40px, ${i * 2}px)`,
      transformOrigin: "20px 20px"
    }
  });
  return markerList;
};

const TargetHorizontalMarker: FunctionComponent<any> = ({
  xScale,
  yScale,
  groups,
  dateRange,
  dateFormat,
  activeLegendHovered
}) => {
  const res = groups.map((group, i) => {
    const xStart = xScale(moment(group.startDate, defaultDateFormat));
    const xEnd = xScale(
      !!groups[i + 1]
        ? moment(groups[i + 1].startDate, defaultDateFormat)
        : moment(dateRange.endDate, defaultDateFormat).toDate()
    );

    return (
      <React.Fragment key={i}>
        {group.awareness > 0 && (!activeLegendHovered || activeLegendHovered === "awareness") && (
          <line
            key={"awareness_" + i + group.id}
            x1={xStart}
            y1={yScale(group.awareness)}
            x2={xEnd}
            y2={yScale(group.awareness)}
            stroke={colors[0]}
            strokeWidth={2}
            // style={{ transform: `translateY(0px)` }}
          />
        )}
        {group.understanding > 0 && (!activeLegendHovered || activeLegendHovered === "understanding") && (
          <line
            key={"understanding_" + i + group.id}
            x1={xStart}
            y1={yScale(group.understanding)}
            x2={xEnd}
            y2={yScale(group.understanding)}
            stroke={colors[1]}
            strokeWidth={2}
            // style={{ transform: `translateY(1px)` }}
          />
        )}
        {group.capability > 0 && (!activeLegendHovered || activeLegendHovered === "capability") && (
          <line
            key={"capability_" + i + group.id}
            x1={xStart}
            y1={yScale(group.capability)}
            x2={xEnd}
            y2={yScale(group.capability)}
            stroke={colors[3]}
            strokeWidth={2}
            // style={{ transform: `translateY(2px)` }}
          />
        )}
        {group.commitment > 0 && (!activeLegendHovered || activeLegendHovered === "commitment") && (
          <line
            key={"commitment_" + i + group.id}
            x1={xStart}
            y1={yScale(group.commitment)}
            x2={xEnd}
            y2={yScale(group.commitment)}
            stroke={colors[2]}
            strokeWidth={2}
            // style={{ transform: `translateY(3px)` }}
          />
        )}
        {group.adoption > 0 && (!activeLegendHovered || activeLegendHovered === "adoption") && (
          <line
            key={"adoption_" + i + group.id}
            x1={xStart}
            y1={yScale(group.adoption)}
            x2={xEnd}
            y2={yScale(group.adoption)}
            stroke={colors[4]}
            strokeWidth={2}
            // style={{ transform: `translateY(4px)` }}
          />
        )}
      </React.Fragment>
    );
  });

  return <>{res}</>;
};

const DashedSolidLine: FunctionComponent<any> = ({ series, lineGenerator, xScale, yScale, dateFormat }) => {
  return series.map(({ id, data, color }) => {
    data.sort((a, b) => {
      return moment(a.data.x, defaultDateFormat).diff(moment(b.data.x, defaultDateFormat));
    });

    const filteredData = data.filter(e => moment(e.data.x) > moment(minimumDate));

    return (
      <path
        key={"dashed_solid_line" + id}
        d={lineGenerator(
          filteredData.map(d => {
            return {
              x: xScale(moment(d.data.x, defaultDateFormat)),
              y: yScale(d.data.y)
            };
          })
        )}
        fill="none"
        stroke={color}
        style={{
          strokeDasharray: "6, 3",
          strokeWidth: 2
        }}
      />
    );
  });
};

const getInforLabel = (label: string) => {
  switch (label) {
    case "awareness":
      return "Awareness";

    case "understanding":
      return "Understand";

    case "capability":
      return "Acquire";

    case "commitment":
      return "Commit";

    case "adoption":
      return "Apply";

    default:
      break;
  }
};
const getInforLabelReverse = (label: string) => {
  switch (label) {
    case "awareness":
      return "awareness";

    case "understand":
      return "understanding";

    case "acquire":
      return "capability";

    case "commit":
      return "commitment";

    case "apply":
      return "adoption";
    default:
      break;
  }
};
export const ReadinessLineChart = observer(React.forwardRef(ReadinessLineChartView));
