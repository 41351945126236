import { ColDef } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProcessRolesApi from "../../../../../../services/api/v2/processRoles/ProcessRoles.api";
import I18n from "../../../../../localization/I18n";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import ToasterService, { ToasterService as IToasterService } from "../../../../../toaster/ToasterService";
import { CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { AppGridState } from "../base/AppGrid_view";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { GetProcessRolesGridActions } from "./ProcessRolesGridView_actions";
import { ProcessRolesGridColumnBuilder } from "./ProcessRolesGridView_columns";
import { SHOW_PROCESS_ROLE_DELETE_CONFIRM_MODAL } from "./ProcessRolesGridView_modals";

export class ProcessRolesGridModel {
  organisationId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  userCanViewProcesses: boolean;
  userCanEditProcesses: boolean;
  processRolesGridColumnBuilder: ProcessRolesGridColumnBuilder;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  microProcessRoleForm: SingleFormModel;
  processRoleProvider = ProcessRolesApi;
  refreshDataHandler: any;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;

  constructor(organisationId: number, authUser: FP.Entities.IUser, refreshDataHandler: any, onFieldUpdate: () => void) {
    makeObservable(this);
    this.userCanViewProcesses = PermissionsContext.canViewField(PermissionFields.PROCESSES, organisationId, null);
    this.userCanEditProcesses = PermissionsContext.canEditField(PermissionFields.PROCESSES, organisationId, null);
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.httpProgress = ProgressIndicatorModel;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
    this.setMicroAddForm();
    this.generateAddons();
  }

  @action
  onMount = async () => {
    this.generateFn();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  @action
  generateGridConfig = () => {
    this.processRolesGridColumnBuilder = new ProcessRolesGridColumnBuilder({
      canEdit: true,
      organisationId: this.organisationId,
      onFieldUpdate: this.onFieldUpdate,
      columns: [CommonColDefFieldNamesEnum.Selected, CommonColDefFieldNamesEnum.Name]
    });
    this.gridColumns = this.processRolesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetProcessRolesGridActions(this));
  };

  @action
  setMicroAddForm = () => {
    this.microProcessRoleForm = new SingleFormModel();
    this.microProcessRoleForm.formFields = getEntityNameMicroFormFields(
      this.createMicroProcessRole,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.processRole") })
    );
  };

  @action
  createMicroProcessRole = async () => {
    let processRoleFormRes = await this.microProcessRoleForm.submit();
    this.microProcessRoleForm.isSaving = true;
    if (!processRoleFormRes) return;

    const res = {
      ...processRoleFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.processRoleProvider.create(this.organisationId, res as FP.Entities.IProcessRole);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const processRole = result.payload;
    await this.onFieldUpdate();
    this.microProcessRoleForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.processRole") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return processRole;
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(null, EntityTypes.PROCESS_ROLES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: null,
      gridType: EntityTypes.PROCESS_ROLES,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: null,
      gridType: EntityTypes.PROCESS_ROLES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  showProcessRoleConfirmDeleteModal = () => {
    return SHOW_PROCESS_ROLE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.processRoleProvider.deleteRange(this.organisationId, null, itemIds);
    if (res && !res.isError) {
      await this.refreshDataHandler();
    }

    this.httpProgress.hideOverlay();
    return res.payload;
  };
}
