import { ColDef, ICellEditorParams } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import { ProgressStatus } from "../../../../../../components/ui/ProgressStatus";
import { RagStatus } from "../../../../../../components/ui/RagStatus";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { BenefitField, Enums, defaultDateTimeString } from "../../../../../../enums";
import BenefitApi from "../../../../../../services/api/v2/benefits/Benefits.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { RAGStatusEditor } from "../../../../editors/editorRAGStatus";
import { laterDateComparator } from "../../../../GridHelpers";
import { BenefitColDefFieldNamesEnum, CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapProgressStatuses
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  OWNER_NAME_COLUMN_CONFIG,
  OWNER_NAME_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/ownerName/OwnerNameColumn_config";
import {
  PROGRESS_STATUS_COLUMN_CONFIG,
  PROGRESS_STATUS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/progressStatus/ProgressStatusColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { BENEFIT_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSideBar/BenefitsNotesSidebarModal_config";

import { BENEFITS_TAGS_SIDEBAR_MODAL_CONFIG } from "./tagsSidebar/BenefitsTagsSideModal_config";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { SIMPLE_TEXT_COLUMN_CONFIG } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_config";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import Pages from "../../../../../../routes/InsightRoutes";
import { RagStatusColumnBuilder } from "../../columns/commonColumns/RagStatusColumn/RagStatusColumn_builder";
import { RAG_STATUS_FILTER_CONFIG } from "../../columns/commonColumns/RagStatusColumn/RagStatusColumn_config";

export interface BenefitGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewBenefits: boolean;
  projectTeamMembers: any[];
  columns: string[];
  onFieldUpdate: () => void;
}

export class BenefitGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: BenefitGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectTeamMembers: any[];
  projectId: number;
  onFieldUpdate: () => void;

  constructor(gridProps: BenefitGridColumnBuilderProps) {
    super(BenefitApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.projectTeamMembers = gridProps.projectTeamMembers;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }
  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [BenefitColDefFieldNamesEnum.RagStatus]: () => this.buildRagStatusColumn(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.Owner]: () => this.buildOwnerColumn(),
      [BenefitColDefFieldNamesEnum.Type]: () => this.buildTypeColumn(),
      [BenefitColDefFieldNamesEnum.Value]: () => this.buildValueColumn(),
      [CommonColDefFieldNamesEnum.ProgressStatus]: () => this.buildProgressStatusColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [CommonColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          BENEFITS_TAGS_SIDEBAR_MODAL_CONFIG,
          null,
          this.onFieldUpdate
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };
  generateColumnDefs = (): ColDef[] => {
    return _.map(this.columnDefs, e => {
      return e();
    });
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG)
      .withLinkCell(benefitId =>
        Pages.projects.benefits.extendedView.generateLink(this.organisationId, this.projectId, benefitId)
      );
    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildOwnerColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Owner, headerName: I18n.t("grids.owner") }),
        ...OWNER_NAME_COLUMN_CONFIG(CommonColDefFieldNamesEnum.Owner, this.projectTeamMembers)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...OWNER_NAME_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[CommonColDefFieldNamesEnum.Owner]) return;
        return `${e.data[CommonColDefFieldNamesEnum.Owner] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data[CommonColDefFieldNamesEnum.Owner] = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateOwnerName(params.data.id, params.newValue.id);
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };
  buildProgressStatusColumn = () => {
    const progressStatuses = mapProgressStatuses();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(AUTOCOMPLETE_COLUMN_CONFIG({ field: "progressStatus", headerName: I18n.t("grids.status") }))
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateProgressStatus(params.data.id, params.newValue.key).then(() => {
          if (
            params.newValue === Enums.ProgressStatus.COMPLETED ||
            params.oldValue === Enums.ProgressStatus.COMPLETED
          ) {
            params.api.redrawRows();
          }
        });

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.progressStatus || !progressStatuses) return null;
        const progress = progressStatuses.find(e => e.key === params.data.progressStatus + "");

        if (!progress) return "";
        return <>{progress.label}</>;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(progressStatuses));

    return model.generateColumnOptions();
  };
  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(DATE_COLUMN_CONFIG({ field: "endDate", headerName: I18n.t("grids.measurmentDate") }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor("endDate", "")
      .setValueFormatter("endDate")
      .setValueGetterByFieldFn("endDate")
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => true)
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let endDate = moment(e);
            let startDate =
              params.data[CommonColDefFieldNamesEnum.StartDate] &&
              params.data[CommonColDefFieldNamesEnum.StartDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.StartDate]);

            if (
              !(endDate === startDate) &&
              (!startDate || laterDateComparator(endDate.toDate(), startDate.toDate()) === true)
            ) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions({ ...NOTE_COUNT_COLUMN_CONFIG(), field: CommonColDefFieldNamesEnum.NoteCount })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      const notesModal = (item: FP.Entities.IBenefit) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(
            BENEFIT_NOTES_SIDEBAR_MODAL_CONFIG(this.organisationId, this.projectId, item, this.onFieldUpdate)
          )
          .generateModal();

      model.setEditableOnDoubleClick(notesModal);
    }
    return model.generateColumnOptions();
  };
  buildRagStatusColumn = () => {
    let model = new RagStatusColumnBuilder()
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setColumnOptions({
        field: BenefitColDefFieldNamesEnum.RagStatus,
        headerName: I18n.t("forms.ragStatus"),
        width: 100,
        cellEditorSelector: () => ({ component: RAGStatusEditor, popup: true, popupPosition: "under" }),
        cellRenderer: columnProps => {
          let obj = columnProps.data;
          return obj.progressStatus === Enums.ProgressStatus.COMPLETED ? (
            <ProgressStatus state={obj.progressStatus} />
          ) : (
            <RagStatus state={obj.ragStatus} />
          );
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueA = 4;
          if (nodeB.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueB = 4;

          return valueA - valueB;
        },
        filterValueGetter: params => {
          return params.data.progressStatus === Enums.ProgressStatus.COMPLETED ? 4 : params.data.ragStatus;
        },
        cellDataType: "any"
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        this.updateRagStatus(+params.data.id, params.newValue);
        return true;
      })
      .setFilterOptions(RAG_STATUS_FILTER_CONFIG);

    return model.generateColumnOptions();
  };
  buildTypeColumn = () => {
    const symbols = [
      { key: "%", label: "%" },
      { key: "$", label: "$" },
      { key: "£", label: "£" },
      { key: "-", label: "-" }
    ];

    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        field: BenefitColDefFieldNamesEnum.Type,
        headerName: I18n.t("grids.type"),
        width: 100,
        cellEditorParams: {
          options: symbols
        }
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG
      })

      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(params => {
        return params.data[BenefitColDefFieldNamesEnum.Type] || "";
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data[BenefitColDefFieldNamesEnum.Type] = params.newValue.key;
        this.updateType(params.data.id, params.newValue.key);
        return true;
      });

    return model.generateColumnOptions();
  };
  buildValueColumn = () => {
    let model = new SimpleTextColumnBuilder({
      field: BenefitColDefFieldNamesEnum.Value,
      headerName: I18n.t("grids.value")
    })
      .setColumnOptions(
        SIMPLE_TEXT_COLUMN_CONFIG({
          field: BenefitColDefFieldNamesEnum.Value,
          headerName: I18n.t("grids.value")
        })
      )
      .makeSelectable(this.canEdit)
      .makeEditable(this.canEdit)
      .makeReadOnly(!this.canEdit)
      .setCellDataType("text")
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateValue);
    }

    if (this.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(BenefitField.description, entityId, text);
    await this.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(BenefitField.name, entityId, text);
    await this.onFieldUpdate();
  };

  updateProgressStatus = async (entityId: number, data: any) => {
    await this.updateIdField(BenefitField.progressStatus, entityId, data);
  };

  updateOwnerName = async (entityId: number, data: any) => {
    await this.updateIdField(BenefitField.owner, entityId, data);
    await this.onFieldUpdate();
  };

  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(BenefitField.endDate, entityId, date);
    await this.onFieldUpdate();
  };
  updateType = async (entityId: number, symbol: string) => {
    await this.updateTextField(BenefitField.type, entityId, symbol);
    await this.onFieldUpdate();
  };
  updateRagStatus = async (entityId: number, data: any) => {
    await this.updateIdField(BenefitField.ragStatus, entityId, data);
    await this.onFieldUpdate();
  };
  updateValue = async (entityId: number, data: any) => {
    await this.updateTextField(BenefitField.value, entityId, data);
    await this.onFieldUpdate();
  };
  //#endregion
}
