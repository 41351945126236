import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { BenefitField, EntityTypes, GridTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { AppGridToolbarType } from "../../types/AppGrid_types";

import { BenefitColDefFieldNamesEnum, CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";

import BenefitApi, { BenefitApi as IBenefitApi } from "../../../../../../services/api/v2/benefits/Benefits.api";
import { SHOW_BENEFIT_DELETE_CONFIRM_MODAL, SHOW_CONFIRM_CREATION_MODAL } from "./BenefitsGrid_modals";
import { GetBenefitsGridActions } from "./BenefitsGrid_actions";
import { BenefitGridColumnBuilder } from "./BenefitsGrid_columns";
export class BenefitsGridModel {
  organisationId: number;
  projectId: number;
  progressStatuses: FP.Generic.IKeyLabel[];
  benefitProvider: IBenefitApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewBenefits: boolean;
  userCanEditBenefits: boolean;
  userCanViewActions: boolean;
  userCanViewProcesses: boolean;
  type: GridTypes = GridTypes.BENEFITS_GRID;
  benefitsGridColumnBuilder: BenefitGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  @observable microBenefitForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  @observable projectTeamMembers: any[];
  gridColumns: ColDef<any, any>[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;
  gridType: EntityTypes | GridTypes;
  @observable isLoading: boolean = true;
  onFieldUpdate: () => void;
  refreshDataHandler?: any;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes,
    onFieldUpdate: () => void,
    refreshDataHandler?: any
  ) {
    makeObservable(this);
    this.benefitProvider = BenefitApi;
    this.onFieldUpdate = onFieldUpdate;
    this.refreshDataHandler = refreshDataHandler;
    this.userCanViewBenefits = PermissionsContext.canViewField(PermissionFields.PROJECTS, organisationId, projectId);
    this.userCanEditBenefits = PermissionsContext.canEditField(PermissionFields.PROJECTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.setMicroAddForm();
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.userCanEditBenefits && this.gridToolbarType !== "link-modal") {
      await this.loadProjectTeamMembers();
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.BENEFITS);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.BENEFITS,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });

    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.BENEFITS,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.benefitsGridColumnBuilder = new BenefitGridColumnBuilder({
      canEdit: this.userCanEditBenefits && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewBenefits: this.userCanViewBenefits,
      projectTeamMembers: this.projectTeamMembers,
      onFieldUpdate: this.onFieldUpdate,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        CommonColDefFieldNamesEnum.Owner,
        CommonColDefFieldNamesEnum.NoteCount,
        CommonColDefFieldNamesEnum.Tags,
        BenefitColDefFieldNamesEnum.RagStatus,
        BenefitColDefFieldNamesEnum.Type,
        BenefitColDefFieldNamesEnum.Value
      ]
    });
    this.gridColumns = this.benefitsGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetBenefitsGridActions(this));
  };

  // #region Initial data loading methods
  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  // #endregion

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  deleteFieldData = async (itemId: number, field: BenefitField) => {
    const res = await this.benefitProvider
      .deleteField(this.organisationId, this.projectId, itemId, field)
      .then(async e => {
        await this.onFieldUpdate();
        return e;
      });

    if (res?.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.benefitProvider
      .deleteRange(this.organisationId, this.projectId, itemIds)
      .then(async e => await this.refreshDataHandler());

    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  setSelectedNodes = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  @action
  setMicroAddForm = () => {
    this.microBenefitForm = new SingleFormModel();
    this.microBenefitForm.formFields = getEntityNameMicroFormFields(
      this.createMicroBenefit,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.benefit") })
    );
  };

  @action
  createMicroBenefit = async () => {
    if (!this.microBenefitForm.isSaving) {
      let microBenefitFormRes = await this.microBenefitForm.submit();
      this.microBenefitForm.isSaving = true;
      if (!microBenefitFormRes) {
        this.microBenefitForm.isSaving = false;
        return;
      }

      microBenefitFormRes = {
        ...microBenefitFormRes,
        projectId: this.projectId
      };
      const res = {
        ...microBenefitFormRes
      };
      this.httpProgress.showOverlay();
      const result = await this.benefitProvider
        .create(this.organisationId, this.projectId, res as FP.Entities.IBenefit)
        .then(e => this.refreshDataHandler());

      this.httpProgress.hideOverlay();

      if (!result || result.isError) {
        this.microBenefitForm.isSaving = false;
        return;
      }
      this.microBenefitForm.isSaving = false;

      const benefit = result.payload;
      this.microBenefitForm.resetFields();
      this.toasterService
        .showSuccessToast()
        .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.benefit") })}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return benefit;
    }
  };

  //#region Grid Action Toolbar Modals Methods [#11111190]
  confirmCreateBenefit = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  showBenefitConfirmDeleteModal = () => {
    return SHOW_BENEFIT_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  //#endregion
}
