import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import Http from "../Http";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";

export class ProjectsApi extends FilteredOrganisationApiModel<FP.Entities.IProject> {
  controller: string = "projects";

  constructor(http: AxiosInstance) {
    super(http, "projects");
  }

  getInsights = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectInsights>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/insights`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getByProgrammeId = async (
    organisationId: number,
    programmeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/programme/${programmeId}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getMilestones = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/milestones`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getHistory = async (
    organisationId: number,
    projectId: number,
    entityId: number,
    historyType: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IRevisionHistory[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/${historyType}/${entityId}/history`;
    let res = await this.http.get(url, config);

    return res.data;
  };

  //#region Impacts

  getImpacts = async (
    organisationId: number,
    projectId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impacts${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getImpactsSummaryCount = async (
    organisationId: number,
    projectId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impacts/count`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  getImpactsAndImpactGroupsCount = async (
    organisationId: number,
    projectId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impacts-impact-group/count`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  //#endregion

  //#region Impact Groups

  getImpactGroups = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impact-groups`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  //#endregion

  //#region Actions

  getActions = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/actions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getActionsCount = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/actions/count`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region users

  getUsers = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/projectUsers`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region Stakeholder impacts

  addProjectStakeholderImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impacts: FP.Entities.IImpact[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholders/impacts/${projectStakeholderId}`;
    let res = await this.http.post(url, impacts, config);
    return res.data;
  };

  //#endregion

  //#region permissions

  addPermission = async (
    organisationId: number,
    projectId: number,
    permissions: FP.Entities.IPermission,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IPermission>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions/create`;
    let res = await this.http.post(url, permissions, config);
    return res.data;
  };

  getPermissionUsers = async (
    organisationId: number,
    projectId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  removeUserPermission = async (
    organisationId: number,
    projectId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions/${userId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  getInheritUsers = async (
    organisationId: number,
    projectId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/inherited-users`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  //#endregion

  getProjectImpactCreators = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/project-impact-creators`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getDashboardData = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/dashboard`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getReadinessSpiderChartData = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/audience-reporting/spider-chart-data`;
    let res = await this.http.post(url, config);
    return res.data;
  };

  getBenefitsGridData = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/benefits/grid-data`;
    let res = await this.http.get(url, config);
    return res.data;
  };
}

let instance = new ProjectsApi(Http);

export default instance;
