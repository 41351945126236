import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Avatar } from "../../../components/ui/Avatar";
import { Animations } from "../../../core/util/Animations";
import { Enums, UiSizes } from "../../../enums";
import iconLogo from "../../../components/widgets/navigation/logos/logo-sysdoc-main.svg";
import { Tooltip } from "../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "../../../components/ui/Panel";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import I18n from "../../../core/localization/I18n";
import { UiActionRenderers } from "../../../core/uiAction/IUiAction";
import { ButtonTypes, LinkButton } from "../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../components/ui/Icon";
import { Colours, Hr } from "@flightpath/coreui/dist/ui/hr";
import { parseUrlForProgrammeId, parseUrlForProjectId } from "../../../constants";
import { useCurrentOrganisationId } from "../../../services/local/organisationContext/OrganisationContextModel";
import { useGetProgrammeByProjectId } from "../../../contexts/permissions/PermissionHooks";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../contexts/permissions/PermissionsTypes";
import Pages from "../../../routes/InsightRoutes";

export interface SideNavigationProviderProps extends React.HTMLProps<HTMLDivElement> {}

export const SideNavigationProvider: React.FC<SideNavigationProviderProps> = props => {
  const path = useLocation();
  const pathName = path.pathname;
  const baseUrl = window.appConfig.baseUrl;
  const location = window.location.href;
  const organisationRegex = /organisations\/[0-9]*(\d|\/)visualisations\/(([a-zA-Z0-9]*)\/?[a-zA-Z0-9]*)*$/g;
  const organisationId = useCurrentOrganisationId();
  const [, projectId] = parseUrlForProjectId(pathName);
  const [, programmeId] = parseUrlForProgrammeId(pathName);
  const currentProgramme = useGetProgrammeByProjectId(organisationId, projectId);
  const [isProgramme, setIsProgramme] = useState(false);
  // programme is not null if the url is going to be
  // organistions/:num/programme/:num

  const isOrganisationDashboardActive =
    organisationRegex.test(pathName) ||
    pathName.indexOf("organisations/visualisations") > -1 ||
    pathName.endsWith("organisation");
  const isMyDashboardActive = location.endsWith(`${baseUrl}/dashboard`);

  let getProgrammeId = programmeId || currentProgramme?.id;
  let programme = null;
  if (!!getProgrammeId) {
    programme = PermissionsContext.getProgramme(organisationId, getProgrammeId);
  }
  useEffect(() => {
    setIsProgramme(!!programme);
  }, [programme]);

  let dashboard = {
    id: "dashboard",
    label: I18n.t("phrases.home"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 
        navigation-view__action 
        ${Animations.ZOOM_IN} 
        speed-3 
        delay-${0} 
        ${isMyDashboardActive ? "navigation__action--active" : ""}`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.HomeFilled,
      href: Pages.dashboards.index.generateLink(organisationId)
    }
  };

  let organisationDashboardUrl = projectId
    ? `/organisations/${organisationId}/projects/${projectId}/organisations/visualisations/heatmap/projects`
    : `/organisations/${organisationId}/visualisations/heatmap/projects`;

  let organisationDashboard = {
    id: "content",
    label: I18n.t("phrases.organisationDashboard"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 
        navigation-view__action 
        navigation-view__action--content 
        ${Animations.ZOOM_IN} 
        speed-3 
        delay-${1}
        ${isOrganisationDashboardActive ? "navigation__action--active" : ""}`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.Stats,
      href: organisationDashboardUrl
    }
  };

  let projectRootUrl = `/organisations/${organisationId}/projects/${projectId}`;

  let currentProgrammeUrl = currentProgramme ? `${projectRootUrl}/programmes/${currentProgramme.id}` : "";

  let programmeUrl = programme && projectId ? `${projectRootUrl}/programmes/${programme.id}` : currentProgrammeUrl;

  let programmeUrlProgrammeId = programme ? programme.id : currentProgramme?.id;

  if (!projectId && (currentProgramme || programme)) {
    programmeUrl = `${projectRootUrl}/programmes/${programmeUrlProgrammeId}`;
  }

  let programmeDashboard = programmeUrl => ({
    id: "programme",
    label: `${I18n.t("phrases.programme")}`,
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 
        navigation-view__action 
        navigation-view__action--content 
        ${Animations.ZOOM_IN} 
        speed-3 
        delay-${1}
        ${!isProgramme ? "navigation__action--disabled" : ""}
        ${!!programmeId ? "navigation__action--active" : ""}`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.Programmes,
      href: programmeUrl,
      isDisabled: !isProgramme
    }
  });

  return (
    <div className="navigation">
      <aside className="navigation__main">
        <div className={`navigation__brand mb-4 ${Animations.FADE_IN} speed-3`}>
          <Link to="/">
            <Avatar size={UiSizes.MD} imgSrc={iconLogo} />
          </Link>
        </div>
        <div className="navigation__actions">
          <div className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={dashboard} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{dashboard.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
          <Hr style={{ width: "100%" }} colour={Colours.GRAY_400} />
          <div className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={organisationDashboard} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{organisationDashboard.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>

          <Routes>
            <Route
              path="/organisations/:organisationId/programmes/:programmeId/*"
              element={
                <>
                  <Hr style={{ width: "100%" }} colour={Colours.GRAY_400} />
                  <div className={`navigation__action`} data-testid={`nav-action-home`}>
                    <Tooltip
                      triggeredOn="hover"
                      position={TooltipPositions.RIGHT}
                      shownElement={<UiActionComponent action={programmeDashboard("#")} />}
                    >
                      <Panel.Panel
                        hasBorderRadius={true}
                        background={Panel.PanelBackgrounds.BG_WHITE}
                        hasShadow={true}
                        className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
                      >
                        <p className="text-dark mb-0">{programmeDashboard(programmeUrl).label}</p>
                      </Panel.Panel>
                    </Tooltip>
                  </div>
                </>
              }
            ></Route>
            <Route
              path="/organisations/:organisationId/projects/:projectId/*"
              element={
                <>
                  <Hr style={{ width: "100%" }} colour={Colours.GRAY_400} />
                  <div className={`navigation__action`} data-testid={`nav-action-home`}>
                    <Tooltip
                      triggeredOn="hover"
                      position={TooltipPositions.RIGHT}
                      shownElement={<UiActionComponent action={programmeDashboard(programmeUrl)} />}
                    >
                      <Panel.Panel
                        hasBorderRadius={true}
                        background={Panel.PanelBackgrounds.BG_WHITE}
                        hasShadow={true}
                        className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
                      >
                        <p className="text-dark mb-0">{programmeDashboard(programmeUrl).label}</p>
                      </Panel.Panel>
                    </Tooltip>
                  </div>
                  <Hr style={{ width: "100%" }} colour={Colours.GRAY_400} />
                  <ProjectUrls />
                </>
              }
            ></Route>
          </Routes>
          <SettingsNavigation />
        </div>
      </aside>
    </div>
  );
};

const ProjectUrls: React.FC<any> = props => {
  const path = useLocation();
  const pathName = path.pathname;
  const { projectId, organisationId } = useParams<{ organisationId: string; projectId: string }>();

  const projectRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*$/g;
  const stakeholderRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)stakeholders/g;
  const audienceRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)audiences/g;
  const impactRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)impacts/g;
  const impactGroupsRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)impact-groups/g;
  const actionRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)actions/g;
  const processRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)processes/g;
  const trainingRegex = /organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)training/g;

  let actions = [
    {
      id: "projectOverview",
      label: I18n.t("phrases.projectOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(projectRegex.test(pathName), 1),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Projects,
        href: `/organisations/${organisationId}/projects/${projectId}`
      }
    },
    {
      id: "processesOverview",
      label: I18n.t("phrases.processOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(processRegex.test(pathName), 4),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.CurlyNodes,
        href: `/organisations/${organisationId}/projects/${projectId}/processes`
      }
    },
    {
      id: "stakeholderOverview",
      label: I18n.t("phrases.stakeholderOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(stakeholderRegex.test(pathName), 2),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.User,
        href: `/organisations/${organisationId}/projects/${projectId}/stakeholders`
      }
    },
    {
      id: "audienceOverview",
      label: I18n.t("phrases.audienceOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(audienceRegex.test(pathName), 2),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Stakeholders,
        href: `/organisations/${organisationId}/projects/${projectId}/audiences`
      }
    },
    {
      id: "impactOverview",
      label: I18n.t("phrases.impactOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(impactRegex.test(pathName) || impactGroupsRegex.test(pathName), 3),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Impacts,
        href: `/organisations/${organisationId}/projects/${projectId}/impacts`
      }
    },
    {
      id: "actionsOverview",
      label: I18n.t("phrases.mitigationOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(actionRegex.test(pathName), 4),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Actions,
        href: `/organisations/${organisationId}/projects/${projectId}/actions`
      }
    },
    {
      id: "trainingOverview",
      label: I18n.t("phrases.trainingOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(trainingRegex.test(pathName), 5),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.BookOpen,
        href: `/organisations/${organisationId}/projects/${projectId}/training/courses`
      }
    }
  ];
  return (
    <>
      {actions.map(e => {
        return (
          <div key={e.id} className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={e} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{e.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};

const GetNavItemClassName = (isActive: boolean, delay) => {
  return `mb-1 
    navigation-view__action 
    navigation-view__action--content 
    ${Animations.ZOOM_IN} 
    speed-3 
    delay-${delay}
    ${isActive ? "navigation__action--active" : ""}`;
};

const SettingsNavigation: React.FC<any> = props => {
  const [isTooltipContentShown, setIsTooltipContentShown] = useState(false);
  const path = useLocation();
  let pathName = path.pathname;
  const organisationSettingsRegex = /organisations\/([0-9]*)\/settings(\d|\/|$)/g;
  const programmeSettingsRegex = /organisations\/([0-9]*)\/programmes\/([0-9]*)\/settings(\d|\/|$)/g;
  const projectSettingsRegex = /organisations\/([0-9]*)\/projects\/([0-9]*)\/settings(\d|\/|$)/g;
  const organisationId = useCurrentOrganisationId();
  const [, projectId] = parseUrlForProjectId(pathName);
  const [, programmeId] = parseUrlForProgrammeId(pathName);
  const currentProgramme = useGetProgrammeByProjectId(organisationId, projectId);
  const isSettingsActive =
    organisationSettingsRegex.test(pathName) ||
    programmeSettingsRegex.test(pathName) ||
    projectSettingsRegex.test(pathName);

  let isOrganisation = !!organisationId;
  let isProject = !!projectId;
  const isOrganisationReader = PermissionsContext.isOrganisationReader(organisationId);
  const isOrganisationOwner = PermissionsContext.isOranisationOwnerOrAdmin(organisationId);
  const canEdiProject =
    isOrganisation && isProject
      ? PermissionsContext.canEditField(PermissionFields.PROJECTS, organisationId, projectId)
      : false;
  const canContributeToOrg = PermissionsContext.canContributeOrg(organisationId) || canEdiProject;

  return (
    <div
      className={`navigation-settings 
        ${isTooltipContentShown ? "navigation-settings--display-tooltip" : ""}`}
      tabIndex={0}
      onBlur={() => setTimeout(() => setIsTooltipContentShown(false), 300)}
    >
      <div className="navigation-settings__label-tooltip">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">
            {!canContributeToOrg ? I18n.t("phrases.settingsNoAccess") : I18n.t("phrases.settings")}
          </p>
        </Panel.Panel>
      </div>

      <div
        className={`btn 
          d-flex 
          btn--sm 
          btn--icon 
          btn--shape-rectangle 
          navigation-settings__action 
          ${Animations.ZOOM_IN} 
          ${isSettingsActive ? "navigation__action--active" : ""}
          speed-3`}
        onClick={() => (!canContributeToOrg ? null : setIsTooltipContentShown(!isTooltipContentShown))}
      >
        <Icon
          symbol={IconSymbols.SettingsFilled}
          size={Enums.UiSizes.MD}
          disabled={!canContributeToOrg}
          className={`navigation-settings__icon 
            ${isTooltipContentShown ? "navigation-settings__icon--active" : ""}`}
        />
      </div>

      <div>
        {isTooltipContentShown && (
          <Panel.Panel
            hasBorderRadius={true}
            hasShadow={true}
            background={Panel.PanelBackgrounds.BG_WHITE}
            className={`p-2 
              ml-3 
              ${Animations.FADE_IN} 
              speed-3 
              d-flex 
              flex-column 
              navigation-settings__tooltip-content`}
            style={{ width: "160px" }}
          >
            <LinkButton
              className="navigation-settings_item"
              href={Pages.organisations.settings.index.generateLink(organisationId)}
              isDisabled={!isOrganisation || !isOrganisationOwner}
              type={ButtonTypes.LINK}
            >
              {I18n.t("phrases.organisationSettings")}
            </LinkButton>
            <LinkButton
              className="navigation-settings_item"
              href={Pages.organisations.coreData.index.generateLink(organisationId)}
              isDisabled={!isOrganisation || isOrganisationReader}
              type={ButtonTypes.LINK}
            >
              {I18n.t("phrases.coreData")}
            </LinkButton>
            <LinkButton
              className="navigation-settings_item"
              href={
                programmeId || currentProgramme
                  ? `${Pages.programmes.settings.index.generateLink(
                      organisationId,
                      programmeId || currentProgramme.id
                    )}`
                  : pathName
              }
              isDisabled={(!!!programmeId && !!!currentProgramme) || isOrganisationReader}
              type={ButtonTypes.LINK}
            >
              {I18n.t("phrases.programmeSettings")}
            </LinkButton>
            <LinkButton
              className="navigation-settings_item"
              href={projectId ? `${Pages.projects.settings.index.generateLink(organisationId, projectId)}` : pathName}
              isDisabled={!canEdiProject}
              type={ButtonTypes.LINK}
            >
              {I18n.t("phrases.projectSettings")}
            </LinkButton>
          </Panel.Panel>
        )}
      </div>
    </div>
  );
};
