import * as React from "react";
import { Pill } from "../../Pill";
import { observer } from "mobx-react";
import { Icon, IconSymbols } from "../../Icon";
import { Tag } from "../../../ui/Tag";
import { Autocomplete, AutocompleteProps } from "../Autocomplete";
import { Panel, PanelBackgrounds, PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { Tooltip, TooltipIcon } from "../../Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

export interface MultiSelectorProps {
  autocompleteProps: AutocompleteProps;
  selectedItems: any[];
  optionElement: React.ReactNode;
  removeItem: (item: any, index: number) => void;
  valueLabelFn: (obj: any) => string;
  isNewFn: (obj: any) => boolean;
  lowerTooltipLabel?: string;
  lowerTooltipContent?: React.ReactNode;
  isTagSelector?: boolean;
  shouldHidePills?: boolean;
}

const tooltipHtml = (content: React.ReactNode): React.ReactElement => {
  return (
    <Panel background={PanelBackgrounds.BG_WHITE} className="p-3" hasBorderRadius={true} type={PanelTypes.OUTLINES}>
      <p style={{ width: "150px" }} className="mb-0">
        {content}
      </p>
    </Panel>
  );
};

const tooltipLowerLabel = (text: string): React.ReactElement => {
  return (
    <div>
      <small>{text}</small> <TooltipIcon />
    </div>
  );
};

const MultiSelector: React.FunctionComponent<MultiSelectorProps> = observer(props => (
  <div className="multi-selector">
    <div>
      <Autocomplete {...props.autocompleteProps}>{props.optionElement}</Autocomplete>
    </div>
    {props.lowerTooltipContent !== null && props.lowerTooltipContent !== undefined && (
      <Tooltip
        shownElement={tooltipLowerLabel(props.lowerTooltipLabel)}
        triggeredOn="hover"
        className="float-right"
        position={TooltipPositions.TOP}
      >
        {tooltipHtml(props.lowerTooltipContent)}
      </Tooltip>
    )}
    {!props.shouldHidePills && (
      <div className="multi-selector_menu mt-2">
        {Array.isArray(props.selectedItems) &&
          props.selectedItems.map((e, i) => {
            if (e === null) {
              return null;
            }
            if (props.isTagSelector) {
              return (
                <Tag
                  key={`tag-${i}`}
                  isEditable={true}
                  tagText={props.valueLabelFn(e)}
                  testId={`tag-${e.id}`}
                  onClick={ee => {
                    props.removeItem(e, i);
                  }}
                  isNew={props.isNewFn(e)}
                />
              );
            } else {
              return (
                <Pill className="m-1" key={i}>
                  <p className="mb-0 d-inline-block">{props.valueLabelFn(e)}</p>
                  <span
                    onClick={ee => {
                      props.removeItem(e, i);
                    }}
                  >
                    <Icon symbol={IconSymbols.Close} className="ml-2" />
                  </span>
                </Pill>
              );
            }
          })}
      </div>
    )}
  </div>
));

export { MultiSelector };
