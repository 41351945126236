import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableOrganisationApi } from "../exportableApi/ExportableOrganisationApiModel";
import { IFilteredResponse } from "../filteredApi/FilteredApiModel";

import { TrainingTypeField } from "../../../../enums";

export class TrainingTypesApi extends ExportableOrganisationApi<any> {
  controller: string = "training-types";

  constructor(http: AxiosInstance) {
    super(http, "training-types");
  }

  updateField = async (
    organisationId: number,
    projectId: number = null,
    trainingTypeId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: TrainingTypeField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITrainingType>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/${this.controller}/${trainingTypeId}/${field}`,
      data,
      config
    );

    return res.data;
  };

  getAll = async (organisationId: number, config?: any) => {
    let res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(`${this.url}/${organisationId}/${this.controller}/delete-range`, ids, config);
    return res.data;
  };
  getByName = async (
    organisationId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<any>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/name/${name}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}

const instance = new TrainingTypesApi(http);
export default instance;
