import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { TrainingModuleLinkModalModel } from "./TrainingModuleLinkModal_model";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { TrainingModulesGridModal } from "../../../../../../../modalZ/common/trainingModulesModals/TrainingModulesGridModal/TrainingModulesGridModal_view";

interface TrainingModuleLinkModalProps {
  projectId: number;
  trainingCourseId: number;
  trainingCourse?: FP.Entities.ITrainingCourse;
  onAssignSuccess?: any;
}

export const TrainingModuleLinkModal: React.FC<TrainingModuleLinkModalProps> = observer(props => {
  const { projectId, trainingCourse, onAssignSuccess } = props;
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new TrainingModuleLinkModalModel(organisationId, projectId, trainingCourse));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner></PositionedSpinner>;
  return (
    <TrainingModulesGridModal
      onAssignSuccess={items => {
        ModalContext.hide();
        model.assignModulesToTrainingCourses(items, onAssignSuccess);
      }}
      selectedItemIds={model.selectedActionIds}
    />
  );
});
