import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Async } from "react-async";
import { useNavigate, useParams } from "react-router-dom";
import { BenefitDisplay } from ".";
import { Button, ButtonTypes } from "../../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { TabGroup } from "../../../../../../../components/ui/TabGroup";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { Enums } from "../../../../../../../enums";
import { CommentListView } from "../../../../../../../pages/change/comments/commentListView/CommentListView_view";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../../auth_insight";
import I18n from "../../../../../../localization/I18n";
import { Animations } from "../../../../../../util/Animations";
import { BenefitExtendedViewModel } from "./BenefitExtendedView_model";

export interface BenefitExtendedViewProps {
  model?: BenefitExtendedViewModel;
}

const BenefitExtendedView: React.FunctionComponent<BenefitExtendedViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const { projectId, benefitId } = useParams<{ projectId: string; benefitId: string }>();
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const [model] = useState(
    () => m || new BenefitExtendedViewModel({ authUser, projectId: +projectId, benefitId: +benefitId, organisationId })
  );
  const { benefit } = model;

  useEffect(() => {
    model.load();
  }, [model]);

  return (
    <Async promiseFn={model.load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col p-2">
                  <Button size={Enums.UiSizes.MD} type={ButtonTypes.LINK} className="p-0" onClick={() => navigate(-1)}>
                    <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                    {I18n.t("phrases.back")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <div>
                        <h1 className="mb-0" id="BenefitNameLabel">
                          {benefit.name} | {I18n.t("phrases.details")}
                        </h1>
                        <h3 className="mb-0">{benefit.name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <BenefitDisplay item={benefit} />
              </div>
              <Panel.Panel
                background={Panel.PanelBackgrounds.BG_LIGHT}
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-5`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: (
                        <div className="col">
                          <CommentListView model={model.commentViewModel} field={PermissionFields.PROJECTS} />
                        </div>
                      )
                    }
                  ]}
                ></TabGroup>
              </Panel.Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { BenefitExtendedView };
