import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseApiModel, IFlightPathApiResponse } from "../BaseApiModel";
import Http from "../Http";

export class EulaApi extends BaseApiModel<any> {
  controller = "eula";

  constructor(http: AxiosInstance) {
    super(http, "eula");
  }

  getCurrentEula = async (config?: AxiosRequestConfig): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}get-latest`;
    const result = await this.http.get(url, config);

    return result.data;
  };

  acceptEula = async (config?: AxiosRequestConfig) => {
    const url = `${this.url}sign-latest`;
    const result = await this.http.post(url, config);

    return result.data;
  };

  getUserEula = async (config?: AxiosRequestConfig) => {};
}

const instance = new EulaApi(Http);
export default instance;
