import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { BenefitsNotesSidebar } from "./BenefitsNotesSidebar";

export const BENEFIT_NOTES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.IBenefit,
  onAssignSuccess?: any
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.notes")} />,
    content: <BenefitsNotesSidebar projectId={projectId} itemId={item.id} onAssignSuccess={onAssignSuccess} />
  };
};
