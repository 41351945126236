import { ProgressStatus } from "@flightpath/coreui/dist/ui/ProgressStatus";
import { RagStatus } from "@flightpath/coreui/dist/ui/RagStatus";
import { ColDef, ICellEditorParams } from "ag-grid-community";
import moment from "moment";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { ActionField, Enums, defaultDateTimeString } from "../../../../../../enums";
import Pages from "../../../../../../routes/InsightRoutes";
import ActionsApi from "../../../../../../services/api/v2/actions/Actions.api";
import AudiencesApi, { AudiencesApi as IAudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { laterDateComparator } from "../../../../GridHelpers";
import { RAGStatusEditor } from "../../../../editors/editorRAGStatus";
import {
  ActionColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapProgressStatuses
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  OWNER_NAME_COLUMN_CONFIG,
  OWNER_NAME_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/ownerName/OwnerNameColumn_config";
import {
  PROGRESS_STATUS_COLUMN_CONFIG,
  PROGRESS_STATUS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/progressStatus/ProgressStatusColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { RefNumberColumnBuilder } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_builder";
import {
  REF_NUMBER_COLUMN_CONFIG,
  REF_NUMBER_FILTER_CONFIG
} from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { ACTIONS_LINK_TO_AUDIENCES_MODAL_CONFIG } from "./ActionsGrid_modals";

import { ACTION_IMPACTS_SIDEBAR_MODAL_CONFIG } from "./modals/actionImpactsSidebar/ActionImpactsSidebarModal_config";
import { ACTION_STAKEHOLDER_OWNERS_MODAL_CONFIG } from "./modals/actionStakeholderOwnersSidebar/ActionStakeholderOwnersSidebarModal_config";
import { ACTION_TAGS_SIDEBAR_MODAL_CONFIG } from "./modals/actionTagsSidebar/ActionTagsSidebarModal_config";
import { ACTION_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/ActionNotesSidebarModal_config";
import { RagStatusColumnBuilder } from "../../columns/commonColumns/RagStatusColumn/RagStatusColumn_builder";
import { RAG_STATUS_FILTER_CONFIG } from "../../columns/commonColumns/RagStatusColumn/RagStatusColumn_config";

export interface ActionsGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewActions: boolean;
  columns: string[];
  projectTeamMembers: any[];
  actionTypes: any[];
}

export class ActionsGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ActionsGridColumnBuilderProps;
  audiencesProvider: IAudiencesApi = AudiencesApi;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectId: number;
  projectTeamMembers: any[];
  actionTypes: FP.Entities.IActionType[];

  constructor(gridProps: ActionsGridColumnBuilderProps) {
    super(ActionsApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.projectTeamMembers = gridProps.projectTeamMembers;
    this.actionTypes = gridProps.actionTypes;

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [ActionColDefFieldNamesEnum.RagStatus]: () => this.buildRagStatusColumn(),
      [CommonColDefFieldNamesEnum.RefNumber]: () => this.buildRefNumberColumn(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.ProgressStatus]: () => this.buildProgressStatusColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [CommonColDefFieldNamesEnum.Owner]: () => this.buildOwnerColumn(),
      [ActionColDefFieldNamesEnum.AssignedTo]: () => this.buildAssignedToColumn(),
      [ActionColDefFieldNamesEnum.ProjectStakeholderOwners]: () =>
        this.buildPillsColumn(
          ActionColDefFieldNamesEnum.ProjectStakeholderOwners,
          I18n.t("grids.stakeholderOwners"),
          ACTION_STAKEHOLDER_OWNERS_MODAL_CONFIG
        ).generateColumnOptions(),
      [ActionColDefFieldNamesEnum.ActionTypeName]: () => this.buildActionTypeColumn(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [CommonColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          ACTION_TAGS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Impacts]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Impacts,
          I18n.t("grids.impacts"),
          ACTION_IMPACTS_SIDEBAR_MODAL_CONFIG
        )
          .makeEditable(false)
          .makeReadOnly(false)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Processes]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Processes,
          I18n.t("grids.processesFromImpacts")
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.BusinessAreas]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.BusinessAreas,
          I18n.t("grids.businessAreasFromImpacts")
        ).generateColumnOptions(),
      [ActionColDefFieldNamesEnum.Audiences]: () =>
        this.buildJSONPillsColumn(
          ActionColDefFieldNamesEnum.Audiences,
          I18n.t("grids.audiences"),
          ACTIONS_LINK_TO_AUDIENCES_MODAL_CONFIG
        ).generateColumnOptions(),
      [ActionColDefFieldNamesEnum.ActionStakeholders]: () =>
        this.buildPillsColumn(
          ActionColDefFieldNamesEnum.ActionStakeholders,
          I18n.t("grids.stakeholdersFromImpacts")
        ).generateColumnOptions(),
      [ActionColDefFieldNamesEnum.ActionAudiences]: () =>
        this.buildJSONPillsColumn(ActionColDefFieldNamesEnum.ActionAudiences, I18n.t("grids.audiencesFromImpacts"))
          .makeEditable(false)
          .makeReadOnly(true)
          .makeSelectable(false)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ReviewedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ReviewedBy,
          headerName: I18n.t("grids.reviewedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ReviewedOn]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.ReviewedOn,
          headerName: I18n.t("grids.lastReviewedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.ReviewedOn, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.ReviewedOn)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NextReviewDue]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.NextReviewDue,
          headerName: I18n.t("grids.nextReviewDue")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.NextReviewDue, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.NextReviewDue)
          .withEndDateCellRenderer()
          .withCellClass(["ag-cell--not-editable"])
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildRagStatusColumn = () => {
    let model = new RagStatusColumnBuilder()
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setColumnOptions({
        field: ActionColDefFieldNamesEnum.RagStatus,
        headerName: I18n.t("forms.ragStatus"),
        width: 100,
        cellEditorSelector: () => ({ component: RAGStatusEditor, popup: true, popupPosition: "under" }),
        cellRenderer: columnProps => {
          let obj = columnProps.data;
          return obj.progressStatus === Enums.ProgressStatus.COMPLETED ? (
            <ProgressStatus state={obj.progressStatus} />
          ) : (
            <RagStatus state={obj.ragStatus} />
          );
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueA = 4;
          if (nodeB.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueB = 4;

          return valueA - valueB;
        },
        filterValueGetter: params => {
          return params.data.progressStatus === Enums.ProgressStatus.COMPLETED ? 4 : params.data.ragStatus;
        },
        cellDataType: "any"
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        this.updateRagStatus(+params.data.id, params.newValue);
        return true;
      })
      .setFilterOptions(RAG_STATUS_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  buildRefNumberColumn = () => {
    let model = new RefNumberColumnBuilder()
      .setColumnOptions(REF_NUMBER_COLUMN_CONFIG({ headerName: I18n.t("grids.refNo") }))
      .withLinkCell(
        actionId => Pages.projects.actions.extendedView.generateLink(this.organisationId, this.projectId, actionId),
        CommonColDefFieldNamesEnum.RefNumber
      )
      .setFilterOptions(REF_NUMBER_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildProgressStatusColumn = () => {
    const progressStatuses = mapProgressStatuses();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(AUTOCOMPLETE_COLUMN_CONFIG({ field: "progressStatus", headerName: I18n.t("grids.status") }))
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateProgressStatus(params.data.id, params.newValue.key).then(() => {
          if (
            params.newValue === Enums.ProgressStatus.COMPLETED ||
            params.oldValue === Enums.ProgressStatus.COMPLETED
          ) {
            params.api.redrawRows();
          }
        });

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.progressStatus || !progressStatuses) return null;
        const progress = progressStatuses.find(e => e.key === params.data.progressStatus + "");

        if (!progress) return "";
        return <>{progress.label}</>;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(progressStatuses));

    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.StartDate, headerName: I18n.t("grids.startDate") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => true)
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            let startDate = moment(e);
            let endDate =
              params.data[CommonColDefFieldNamesEnum.EndDate] &&
              params.data[CommonColDefFieldNamesEnum.EndDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.EndDate]);

            if (!(startDate === endDate) && (!endDate || endDate > startDate)) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(DATE_COLUMN_CONFIG({ field: "endDate", headerName: I18n.t("grids.endDate") }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor("endDate", "")
      .setValueFormatter("endDate")
      .setValueGetterByFieldFn("endDate")
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => true)
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let endDate = moment(e);
            let startDate =
              params.data[CommonColDefFieldNamesEnum.StartDate] &&
              params.data[CommonColDefFieldNamesEnum.StartDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.StartDate]);

            if (
              !(endDate === startDate) &&
              (!startDate || laterDateComparator(endDate.toDate(), startDate.toDate()) === true)
            ) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildOwnerColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Owner, headerName: I18n.t("grids.owner") }),
        ...OWNER_NAME_COLUMN_CONFIG(CommonColDefFieldNamesEnum.Owner, this.projectTeamMembers)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...OWNER_NAME_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[CommonColDefFieldNamesEnum.Owner]) return;
        return `${e.data[CommonColDefFieldNamesEnum.Owner] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data[CommonColDefFieldNamesEnum.Owner] = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateOwnerName(params.data.id, params.newValue.id);
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildAssignedToColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        field: ActionColDefFieldNamesEnum.AssignedTo,
        headerName: I18n.t("grids.assignedTo")
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellRenderer(e => <div>{e.data.assignedToId > 0 ? e.data.assignedToName : ""}</div>)
      .setValueGetter(e => {
        if (!e.data.assignedToName) return;
        return `${e.data.assignedToName ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.assignedToId = params.newValue.id;
        params.data.assignedToName = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateAssignedTo(+params.data.id, params.newValue.id);
        return true;
      })
      .withCellEditor()
      .setColumnOptions({
        cellEditorParams: {
          field: ActionColDefFieldNamesEnum.AssignedTo,
          getValueLabel: (ee: any) => {
            if (!this.projectTeamMembers) return "";

            const user = this.projectTeamMembers.find(e => e.id === ee.id);
            if (!user) return "";
            return user.firstName + " " + user.lastName;
          },
          options: this.projectTeamMembers ?? [],
          allowFreeText: true,
          isPerson: true,
          filterFn: (items, query) => {
            const lowerQuery = query.toLowerCase();
            return items.filter((item: FP.Entities.IUser) => {
              const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
              const lowerEmail = item.email.toLowerCase();
              return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
            });
          }
        }
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildActionTypeColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setColumnOptions({
        field: ActionColDefFieldNamesEnum.ActionTypeName,
        headerName: I18n.t("grids.actionType"),
        cellEditorParams: {
          field: ActionColDefFieldNamesEnum.ActionTypeName,
          getValueLabel: (ee: any) => {
            return ee.name;
          },
          options: this.actionTypes ?? [],
          valueSelector: "name",
          allowFreeText: true,
          charInputNumber: 1,
          filterFn: (items, query) => {
            const lowerQuery = query.toLowerCase();
            return items.filter((item: FP.Entities.IActionType) => {
              const name = `${item.name}`.toLowerCase();
              return name.indexOf(lowerQuery) > -1;
            });
          }
        }
      })
      .withCellEditor()
      .withCellRenderer(props => {
        if (!props.data.progressStatus || !this.actionTypes) return null;
        const actionType = this.actionTypes.find(e => e.id === props.data.actionTypeId);

        if (!actionType) return "";
        return <>{actionType.name}</>;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.actionTypeId = params.newValue.id;
        params.data.actionTypeName = params.newValue.name;

        this.updateActionType(+params.data.id, params.newValue.id);
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }
    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions(NOTE_COUNT_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      const actionNotesModal = (action: FP.Entities.IAction) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(ACTION_NOTES_SIDEBAR_MODAL_CONFIG(this.organisationId, this.projectId, action))
          .generateModal();

      model.setEditableOnDoubleClick(actionNotesModal);
    }
    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(ActionField.description, entityId, text);
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(ActionField.name, entityId, text);
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(ActionField.startDate, entityId, date);
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(ActionField.endDate, entityId, date);
  };
  updateProgressStatus = async (entityId: number, data: any) => {
    await this.updateIdField(ActionField.progressStatus, entityId, data);
  };
  updateAssignedTo = async (entityId: number, data: any) => {
    await this.updateIdField(ActionField.assignedTo, entityId, data);
  };
  updateActionType = async (entityId: number, data: any) => {
    await this.updateIdField(ActionField.actionType, entityId, data);
  };
  updateRagStatus = async (entityId: number, data: any) => {
    await this.updateIdField(ActionField.ragStatus, entityId, data);
  };
  updateOwnerName = async (entityId: number, data: any) => {
    await this.updateIdField(ActionField.owner, entityId, data);
  };
  //#endregion
}
