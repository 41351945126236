// TODO - Make Milestones settings reusable across projects and programmes

import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { ITableConfig, ITableModel } from "../../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { BaseModel } from "../../../../../core/util/BaseModel";
import MilestonesApi, {
  MilestonesApi as IMilestonesApi
} from "../../../../../services/api/v2/milestones/Milestones.api";
import ProgrammesApi, {
  ProgrammesApi as IProgrammesApi
} from "../../../../../services/api/v2/programmes/Programmes.api";
import { MilestoneModalModel } from "./MilestoneModal_model";
import { MilestoneModal } from "./MilestoneModal_view";

export class MilestoneSettingsModel extends BaseModel {
  tableModel: ITableModel<FP.Entities.IMilestone>;
  modalService: IModalContextModel;
  milestoneProvider: IMilestonesApi;
  programmeProvider: IProgrammesApi;
  programmeId: number;
  @observable.ref programme: FP.Entities.IProgramme;
  @observable.ref milestones: FP.Entities.IMilestone[] = [];
  @observable.ref isLoading: boolean;
  organisationId: number;

  constructor(organisationId: number, programmeId: number) {
    super();
    makeObservable(this);
    this.programmeId = programmeId;
    this.modalService = ModalContext;
    this.milestoneProvider = MilestonesApi;
    this.programmeProvider = ProgrammesApi;
    this.organisationId = organisationId;
    this.isLoading = true;
    this.setTableConfig();
  }

  onMount = async () => {
    await this.loadProgramme();
    await this.loadData();
    this.setLoading(false);
  };

  @action
  loadProgramme = async () => {
    const res = await this.programmeProvider.getById(this.organisationId, this.programmeId);
    this.programme = res.payload;
  };

  setTableConfig = () => {
    this.tableModel = new TableModel();
    this.tableModel.setData(this.milestones);

    const tableConfig: ITableConfig<FP.Entities.IMilestone> = {
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => obj.name
        },
        {
          key: "deadline",
          content: <h4 className="mb-0">{I18n.t("table.milestoneDate")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => moment(obj.deadline).format("L")
        }
      ] as any[],
      actions: [
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          onAction: (ev, row: ITableRowModel) => {
            this.showMilestoneModal(row.rowObject);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            this.showConfirmDeleteModal(row.rowObject);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        }
      ]
    };

    this.tableModel.set(tableConfig);
  };

  @action
  setLoading = (loadingState: boolean) => {
    this.isLoading = loadingState;
  };

  @action
  setMilestones = (milestones: FP.Entities.IMilestone[]) => {
    this.milestones = milestones;
    this.tableModel.setData(milestones);
  };

  @action
  setProgramme = (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
  };

  loadData = async () => {
    const res = await this.milestoneProvider.getProgrammeMilestones(this.organisationId, this.programme.id);
    if (!res || res.isError) return;
    this.setMilestones(res.payload);
  };

  showMilestoneModal = (milestone?: FP.Entities.IMilestone) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon symbol={IconSymbols.Close} className="float-right mr-1" onClick={this.modalService.hide} />
        </div>
      ),
      content: (
        <MilestoneModal
          model={new MilestoneModalModel(this.organisationId, this.programme.id, this.loadData, milestone)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        overflow: "visible",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showConfirmDeleteModal = (milestone: FP.Entities.IMilestone) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: milestone.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const res = await this.milestoneProvider.deleteProgrammeMilestone(
            this.organisationId,
            this.programme.id,
            milestone.id
          );
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.modalService.hide();
          this.loadData();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
