import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ProjectStakeholdersGridModalView } from "../../../ProjectStakeholdersGrid/modals/ProjectStakeholdersGridModal/ProjectStakeholdersGridModal_view";
import { TrainingCourseProjectStakeholderOwnerLinkModalModel } from "./TrainingCourseStakeholdersLinkModal_model";

interface TrainingCourseProjectStakeholderLinkModalProps {
  projectId: number;
  trainingCourseId: number;
  trainingCourse?: FP.Entities.ITrainingCourse;
  onAssignSuccess?: any;
}

export const TrainingCourseProjectStakeholderLinkModal: React.FC<TrainingCourseProjectStakeholderLinkModalProps> =
  observer(props => {
    const { projectId, trainingCourse, onAssignSuccess } = props;
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(
      () => new TrainingCourseProjectStakeholderOwnerLinkModalModel(organisationId, projectId, trainingCourse)
    );
    useEffect(() => {
      model.onMount();
    }, [model]);

    if (model.isLoading) return <PositionedSpinner></PositionedSpinner>;
    return (
      <ProjectStakeholdersGridModalView
        onAssignSuccess={items => {
          model.assignProjectStakeholderOwnersToTrainingCourses(items, onAssignSuccess);
          ModalContext.hide();
        }}
        selectedLinkItemIds={model.selectedProjectStakeholderOwnerIds}
      ></ProjectStakeholdersGridModalView>
    );
  });
