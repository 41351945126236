import { ColDef, GridApi } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, StakeholderField } from "../../../../../../enums";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../../services/api/v2/stakeholders/Stakeholders.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { StakeholdersGridColumnBuilder } from "./StakeholdersGrid_columns";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { GetStakeholdersGridActions } from "./StakeholdersGrid_actions";
import { ORG_STAKEHOLDER_EMAILS_MODAL_CONFIG } from "./modals/stakeholderEmailsModal/StakeholderEmails_config";
import { CommonColDefFieldNamesEnum, StakeholderColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { SHOW_STAKEHOLDER_CREATE_MODAL, SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL } from "./StakeholdersGrid_modals";
import { IUiAction, UiActionRenderers } from "../../../../../uiAction/IUiAction";
import I18n from "../../../../../localization/I18n";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { getStakeholderFormFields } from "../../../../../../pages/change/forms/stakeholder/StakeholderFormSection_data";
import BusinessAreasApi, {
  BusinessAreasApi as IBusinessAreasApi
} from "../../../../../../services/api/v2/businessAreas/BusinessAreas.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../services/api/v2/organisations/Organisations.api";

export class StakeholdersGridModel {
  organisationId: number;
  projectId: number = 0;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  stakeholderProvider: IStakeholdersApi;
  businessAreaProvider: IBusinessAreasApi;
  organisationsProvider: IOrganisationsApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewStakeholders: boolean;
  userCanEditStakeholders: boolean;
  stakeholdersGridColumnBuilder: StakeholdersGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  gridColumns: ColDef<any, any>[];
  columnDefs: FP.Entities.IColumnDef[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;
  refreshDataHandler: any;
  @observable orgUsers?: FP.Entities.IUser[];
  @observable orgBusinessAreas?: FP.Entities.IBusinessArea[];
  @observable orgRoles?: FP.Entities.IRole[];
  @observable stakeholders?: FP.Entities.IStakeholderSummary[];

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    columnDefs: FP.Entities.IColumnDef[],
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    refreshDataHandler: any,
    orgUsers?: FP.Entities.IUser[],
    orgBusinessAreas?: FP.Entities.IBusinessArea[],
    orgRoles?: FP.Entities.IRole[],
    stakeholders?: FP.Entities.IStakeholderSummary[]
  ) {
    makeObservable(this);
    this.stakeholderProvider = StakeholdersApi;
    this.businessAreaProvider = BusinessAreasApi;
    this.organisationsProvider = OrganisationsApi;
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanEditStakeholders = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.columnDefs = columnDefs;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.refreshDataHandler = refreshDataHandler;
    this.orgUsers = orgUsers;
    this.orgBusinessAreas = orgBusinessAreas;
    this.orgRoles = orgRoles;
    this.stakeholders = stakeholders;
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.gridToolbarType !== "link-modal") {
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.PROJECT_STAKEHOLDER);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROJECT_STAKEHOLDER,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROJECT_STAKEHOLDER,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.stakeholdersGridColumnBuilder = new StakeholdersGridColumnBuilder({
      canEdit: this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      userCanViewStakeholders: this.userCanViewStakeholders,
      orgUsers: this.orgUsers,
      orgBusinessAreas: this.orgBusinessAreas,
      orgRoles: this.orgRoles,
      onFieldUpdate: this.refreshDataHandler,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        StakeholderColDefFieldNamesEnum.FirstName,
        StakeholderColDefFieldNamesEnum.LastName,
        StakeholderColDefFieldNamesEnum.Email,
        StakeholderColDefFieldNamesEnum.BusinessArea,
        StakeholderColDefFieldNamesEnum.Role,
        CommonColDefFieldNamesEnum.Owner,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.stakeholdersGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetStakeholdersGridActions(this));
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  deleteFieldData = async (projectStakeholderId: number, stakeholderField: StakeholderField) => {
    const res = await this.stakeholderProvider.deleteField(this.organisationId, projectStakeholderId, stakeholderField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);
    await this.refreshDataHandler();

    return true;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.stakeholderProvider.deleteRange(this.organisationId, itemIds).then(async e => {
      await this.refreshDataHandler();
      return e;
    });

    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-stakeholders--export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  getStakeholderFormModel = (stakeholder: FP.Entities.IStakeholder) => {
    let formFields = getStakeholderFormFields(
      this.organisationId,
      this.organisationsProvider,
      this.businessAreaProvider,
      this.stakeholderProvider,
      stakeholder
    );
    let stakeholderFormModel = new SingleFormModel();

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateStakeholderButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await stakeholderFormModel.submit();
          if (!res) return;
          this.modalService.hide();

          res.audienceCount = -1;

          this.httpProgress.showOverlay();
          if (stakeholder) {
            await this.stakeholderProvider.update(this.organisationId, stakeholder.id, res as FP.Entities.IStakeholder);
          } else {
            await this.stakeholderProvider.create(this.organisationId, res as FP.Entities.IStakeholder);
          }
          this.httpProgress.hideOverlay();

          await this.refreshDataHandler();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    stakeholderFormModel.formFields = formFields;
    stakeholderFormModel.actions = actions;
    return stakeholderFormModel;
  };

  showEmailsModal = () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(ORG_STAKEHOLDER_EMAILS_MODAL_CONFIG(this.selectedItems, this.stakeholders))
      .generateModal();
  };

  showStakeholderConfirmDeleteModal = () => {
    return SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  showStakeholderFormModal = (stakeholder?: FP.Entities.IStakeholder) => {
    const formModel = this.getStakeholderFormModel(stakeholder);
    return SHOW_STAKEHOLDER_CREATE_MODAL(formModel);
  };
}
