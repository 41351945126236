import BenefitApi from "../../../../../../../services/api/v2/benefits/Benefits.api";
import I18n from "../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../utils/GridModalUtils";
import { TagsSidebar } from "../../../modals/TagSidebar/TagSidebar/TagsSidebar_view";
import TagsApi from "../../../../../../../services/api/v2/tags/Tags.api";

export const BENEFITS_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.IBenefit,
  onAssignSuccess: (organisationId: number, projectId: number, item: any, onAssignSuccess?: any) => any
) => {
  const loadBenefits = async (benefitId: number): Promise<FP.Entities.IBenefit> => {
    let res = await BenefitApi.getDetailedById(organisationId, projectId, benefitId);
    if (!res || res.isError) return;
    return res.payload;
  };

  const addBenefitTag = async (tag: FP.Entities.ITag): Promise<FP.Entities.ITag> => {
    let res = await TagsApi.addBenefitTagAssoc(organisationId, projectId, item.id, tag);
    if (!res || res.isError) return;
    onAssignSuccess(organisationId, projectId, item);
    return res.payload;
  };

  const removeBenefitTag = async (tag: any): Promise<boolean> => {
    let res = await TagsApi.removeBenefitTagAssoc(organisationId, projectId, tag.id, item.id);
    if (!res || res.isError) return;
    onAssignSuccess(organisationId, projectId, item);
    return res.payload;
  };

  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.tags")} />,
    content: (
      <TagsSidebar
        organisationId={organisationId}
        projectId={projectId}
        itemId={item.id}
        onAssignSuccess={onAssignSuccess}
        loadItem={loadBenefits}
        addTagAssociation={addBenefitTag}
        removeTagAssociation={removeBenefitTag}
      />
    )
  };
};
