import _ from "lodash";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingIds } from "../../../../../../../../core/grids/GridHelpers";
import I18n from "../../../../../../../../core/localization/I18n";
import { ImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { ProjectsApi } from "../../../../../../../../services/api/v2/projects/Projects.api";

export const getImpactMilestonesFormFields = (
  impactsProvider: ImpactsApi,
  projectsProvider: ProjectsApi,
  organisationId: number,
  projectId: number,
  impactId: number,
  milestones: FP.Entities.IMilestone[]
) => {
  const removeMilestone = async (milestoneId: number) => {
    const res = await impactsProvider.removeMilestone(organisationId, projectId, impactId, milestoneId);
    if (!res) return;

    return res;
  };

  const addMilestone = async (milestoneId: number) => {
    const res = await impactsProvider.addMilestone(organisationId, projectId, impactId, milestoneId);
    if (!res || res.payload) return;

    return res.payload;
  };

  const impactMilestones: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactMilestones",
    label: <label htmlFor={"impactMilestones"}>{I18n.t("forms.associatedMilestones")}</label>,
    placeholder: I18n.t("placeholders.searchMilestones"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpactGroup) => `${e.name}`}
      />
    ),
    onItemSelected: e => addMilestone(e.id),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await projectsProvider.getMilestones(organisationId, projectId);

      if (res.payload) {
        const existingVals = self.extractValue();
        let sortedMilestones = _.orderBy(res.payload, [milestone => milestone.name.toLowerCase()]);
        sortedMilestones = filterOutExistingIds(existingVals, sortedMilestones);
        self.setOptions(sortedMilestones);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    valueLabelFn: e => e?.name,
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.isHidden ? null : this.selectedItems.map(e => e.id);
    },
    value: milestones,
    removeItemFn: (ig, idx) => removeMilestone(ig.id)
  };

  const fields = [];
  fields.push(impactMilestones);

  return generateFormFieldsFromJson(fields);
};
