import { BASE_ROUTE, RouteParams } from "../constants";

export const USER_ROUTES = {
  auth: {
    loginComplete: {
      path: `/login_complete`,
      generateLink: () => `/login_complete`
    }
  },
  index: {
    path: `${BASE_ROUTE}/users/settings`,
    generateLink: () => USER_ROUTES.index.path
  },
  settings: {
    index: {
      path: `${BASE_ROUTE}/users/settings`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/users/settings`
    },
    password: {
      path: `${BASE_ROUTE}/users/password`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/users/password`
    },
    organisations: {
      path: `${BASE_ROUTE}/users/organisations`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/users/organisations`
    }
  },
  userSettings: {
    path: `${BASE_ROUTE}/users/${RouteParams.userId}`,
    generateLink: (organisationId: string | number, identityKey: string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/users/${identityKey}`
  }
};
