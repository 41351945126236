import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const DURATION_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  ...columnOptions,
  headerName: "Duration (min)",
  field: "duration",
  editable: false
});

export const DURATION_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG
};
