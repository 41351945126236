import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import { ProcessImpactGroupsSidebar } from "../../../../../../pages/insight/projects/Processes/ProcessListView/modals/ProcessImpactGroupsSidebar/ProcessImpactGroupsSidebar_view";
import { ProcessAppsSidebar } from "../../../../../../pages/insight/projects/Processes/ProcessesGridView/modals/ProcessAppsSidebar/ProcessAppsSidebar_view";
import { ProcessRolesSidebar } from "../../../../../../pages/insight/projects/Processes/ProcessesGridView/modals/ProcessRolesSidebar/ProcessRolesSidebar_view";
import { ProjectProcessNotesSideBar } from "../../../../../../pages/insight/projects/Processes/_grid/modals/ProjectProcessNotesSidebar";
import { ProjectProcessTagsSidebarModel } from "../../../../../../pages/insight/projects/Processes/_grid/modals/projectProcessTagsSidebar/ProjectProcessTagsSidebar_model";
import { ProjectProcessTagsSidebar } from "../../../../../../pages/insight/projects/Processes/_grid/modals/projectProcessTagsSidebar/ProjectProcessTagsSidebar_view";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../../GridSideModals";
import { GridSideModalTitle } from "../../utils/GridModalUtils";
import { WrapperImpactGridWithDataLoader } from "../ImpactsGrid/WrapperImpactsGridWithDataLoader_view";
import { ProcessesGrid } from "../ProcessesGrid/ProcessesGridView_view";
import { WrapperProcessRolesGridWithDataLoader } from "../ProcessRolesGrid/WrapperProcessRolesGridDataLoader_view";
import { ProjectProcessImpactsSidebar } from "./modals/projectProcessImpactSidebar/ProjectProcessImpactsSidebar_view";
import { ProjectProcessLocationsPopup } from "./modals/projectProcessLocationsPopup/ProjectProcessLocationsPopup_view";
import { StakeholderOnwersSidebar } from "./modals/stakeholderSidebar/StakeholdersSidebar_view";
import { ScopeItemsSidebar } from "../../../../../../pages/insight/projects/Processes/ProcessesGridView/modals/ScopeItemsSidebar/ScopeItemsSidebar_view";

export const SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL_CONFIG = (
  data: any,
  onAssignSuccess: any,
  selectedLinkItems: any
) => {
  return {
    showClose: false,
    title: null,
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: (
      <ProcessesGrid
        data={data}
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        selectedLinkItems={selectedLinkItems}
      ></ProcessesGrid>
    )
  };
};

export const SHOW_PROJECT_PROCESS_DELETE_CONFIRM_MODAL = (
  itemIds: number[],
  removeItems: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeProcessFromOrg")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmProcessRangeRemove", { name: itemIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItems(itemIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_PROCESS_ROLE_SIDEMODAL = (organisationId, projectId, process: FP.Entities.IProjectProcess) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.processRoles")} />,
    content: <ProcessRolesSidebar process={process} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_STAKEHOLDER_OWNERS_SIDEMODAL = (organisationId, projectId, process: FP.Entities.IProjectProcess) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("entities.stakeholderOwners")} />,
    content: <StakeholderOnwersSidebar projectProcess={process} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_PROCESS_APP_SIDEMODAL = (organisationId, projectId, process: FP.Entities.IProjectProcess) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.processApps")} />,
    content: <ProcessAppsSidebar process={process} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_SCOPE_ITEM_SIDEMODAL = (organisationId, projectId, process: FP.Entities.IProjectProcess) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.scopeItems")} />,
    content: <ScopeItemsSidebar process={process} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_IMPACT_GROUP_SIDEMODAL = (organisationId, projectId, process) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.impactGroups")} />,
    content: <ProcessImpactGroupsSidebar projectProcessId={process.id} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_IMPACTS_SIDEMODAL = (organisationId, projectId, process) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.impacts")} />,
    content: <ProjectProcessImpactsSidebar projectProcessId={process.id} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_NOTES_SIDEMODAL = (organisationId, projectId, process) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.level3} field={I18n.t("phrases.notes")} />,
    content: (
      <ProjectProcessNotesSideBar projectId={projectId} organisationId={organisationId} projectProcessId={process.id} />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const SHOW_TAGS_SIDEMODAL = (organisationId, projectId, process) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.tags")} />,
    content: (
      <ProjectProcessTagsSidebar
        model={new ProjectProcessTagsSidebarModel(ModalContext.hide, organisationId, projectId, process.id)}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};

export const LINK_TO_LOCATIONS_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => Promise<any>) => {
  return {
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Assign locations to selected Project Processes.</h3>
      </div>
    ),
    content: <ProjectProcessLocationsPopup onSubmit={onAssignSuccess} />
  };
};
export const LINK_TO_PROCESS_ROLES_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => Promise<any>) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: <WrapperProcessRolesGridWithDataLoader onAssignSuccess={onAssignSuccess} selectedLinkItems={[]} />
  };
};

export const LINK_TO_IMPACTS_MODAL_CONFIG = (onAssignSuccess: (itemIds: number[]) => void) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: <WrapperImpactGridWithDataLoader onAssignSuccess={onAssignSuccess} selectedLinkItems={[]} />
  };
};
