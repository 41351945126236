import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { MilestonesGridView } from "../../../../../core/grids/builder/directorBuilder/grids/MilestonesGrid/MilestonesGridView_view";
import MilestonesApi from "../../../../../services/api/v2/milestones/Milestones.api";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { MilestoneSettingsModel } from "./MilestoneSettings_model";

const MilestoneSettingsView: React.FunctionComponent = observer(() => {
  const { programmeId } = useParams();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new MilestoneSettingsModel(organisationId, +programmeId));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="milestone-settings insight-tab-content__space-filler">
      <MilestonesGridView
        data={model.milestones}
        onMilestoneCreated={model.loadData}
        parentId={+programmeId}
        parentEntity="programme"
        createMilestone={MilestonesApi.createProgrammeMilestone}
      />
    </div>
  );
});

export { MilestoneSettingsView };
