import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { TrainingModulesGridModalModel } from "./TrainingModulesGridModal_model";
import { TrainingModulesGrid } from "../../../../grids/builder/directorBuilder/grids/TrainingModulesGrid/TrainingModulesGridView_view";

export const TrainingModulesGridModal: React.FC<{
  onAssignSuccess: (itemIds: number[]) => any;
  selectedItemIds: number[];
}> = observer(({ selectedItemIds, onAssignSuccess }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new TrainingModulesGridModalModel(organisationId, +projectId, selectedItemIds));

  useEffect(() => {
    model.onMount();

    //eslint-disable-next-line
  }, []);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="training-modules-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <TrainingModulesGrid
        data={model.data}
        gridToolbarType="link-modal"
        isLoading={model.isLoading}
        preSelectedItemIds={selectedItemIds}
        onAssignSuccess={onAssignSuccess}
        onFieldUpdate={model.load}
      />
    </div>
  );
});
