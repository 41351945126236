import { useParams } from "react-router-dom";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Panel } from "../../../../../components/ui/Panel";
import { RadarChart } from "../../../../../components/widgets/radar/radar";
import I18n from "../../../../../core/localization/I18n";
import { UiSizes } from "../../../../../enums";
import Pages from "../../../../../routes/InsightRoutes";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ProjectRadialChartPanelModel } from "./ProjectRadialChartPanel_model";
import { IF } from "../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export const ProjectRadialChartPanel: React.FC = observer(() => {
  const { organisationId, projectId } = useParams();
  const [model] = useState(() => new ProjectRadialChartPanelModel(+organisationId, +projectId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  return (
    <Panel.Panel
      hasBorder
      background={Panel.PanelBackgrounds.BG_LIGHT}
      hasBorderRadius
      className="p-3"
      style={{ height: 512 }}
    >
      <IF condition={model.isLoading}>
        <PositionedSpinner />
      </IF>
      {!model.isLoading && (
        <>
          <h2 className="m-2">{I18n.t("phrases.radarTitle")}</h2>
          {model.readinessSpiderChartNoData ? (
            <div className="panel count-display">
              <p>{I18n.t("phrases.emptyOrMissingReadinessChartData")}</p>
              <LinkButton
                className="m-2"
                size={UiSizes.SM}
                href={Pages.projects.settings.phases.generateLink(organisationId, projectId)}
                type={ButtonTypes.LINK_BIG}
              >
                {I18n.t("phrases.goToPhasesLabel")}
              </LinkButton>
            </div>
          ) : (
            <div>
              <RadarChart
                data={model.readiness}
                keys={["targetValues", "latestValues"]}
                maxValue={Object.keys(model.readiness).length}
                isAnimated
                hasLegend
                translateLegendX={-50}
                translateLegendY={-40}
                styles={{ height: "400px" }}
              />
            </div>
          )}
        </>
      )}
    </Panel.Panel>
  );
});
