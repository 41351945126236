import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../grids/GridSideModals";
import ModalContext from "../../context/ModalContext";
import { TagsSidebar } from "./TagsSidebar_view";

export const SHOW_TAGS_SIDEBAR = (
  onAssignSuccess: (itemIds: number[]) => any,
  selectedItems: number[],
  data: FP.Entities.ITag[]
) => {
  ModalContext.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Select Tags</h3>
      </div>
    ),
    content: (
      <TagsSidebar
        onSubmit={items => {
          ModalContext.hide();
          onAssignSuccess(items);
        }}
        currentIds={selectedItems}
        data={data}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
