import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { Input } from "../../../../../components/ui/_forms/Input";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { GridView } from "../../../../../core/grids/GridView_view";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { EntityTypes, Enums, UiSizes } from "../../../../../enums";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectJobRoleViewModel } from "./ProjectJobRoleView_model";
import { observer } from "mobx-react-lite";
import { ButtonIconDropdown } from "../../../../../components/ui/ButtonIconDropdown";
import { usePrevious } from "react-use";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

export const ProjectJobRoleView: React.FC = observer(() => {
  const navigate = useNavigate();
  const { projectJobRoleStore } = useStores();
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const pId = !!projectId ? +projectId : null;
  const [model, setModel] = useState(() => new ProjectJobRoleViewModel(navigate, organisationId, pId, authUser));
  const wrapTextKey = `projects-${projectId}-${EntityTypes.PROJECT_JOB_ROLES}-wrap-text`;
  const storageService = LocalStorageService;
  const filterRef = useRef(null);
  const [hasFilters, setHasFilters] = useState(false);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const prevOrganisationId = usePrevious(organisationId);
  const prevProjectId = usePrevious(pId);
  const prevModel = usePrevious(model);
  // const isAdmin = useIsOrganisationAdmin(organisationId);

  useEffect(() => {
    if (!!projectJobRoleStore) {
      if (!projectJobRoleStore.isLoading && (prevOrganisationId !== organisationId || prevProjectId !== pId)) {
        setModel(new ProjectJobRoleViewModel(navigate, organisationId, pId, authUser));
      }

      if (prevModel !== model) {
        projectJobRoleStore.invokeDataLoad(organisationId, pId, authUser);
        model.onMount();
        return () => {
          projectJobRoleStore.invokeUserLeft(organisationId, pId, authUser);
          model.onUnmount();
        };
      }
    }
    // eslint-disable-next-line
  }, [model, projectId, organisationId]);

  if (model.isLoading) return <PositionedSpinner></PositionedSpinner>;

  return (
    <div className={`project-job-roles-list-view ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid py-2">
        <div className="row align-items-center">
          <div className="col d-flex">
            <ButtonIcon
              className="float-left mr-2"
              type={ButtonTypes.PRIMARY}
              size={UiSizes.SM}
              onClick={() => model.showJobRoleGridModal()}
              symbol={IconSymbols.CircleDownArrow}
            >
              {I18n.t("phrases.importProcessesFromTemplate")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={model.selectedProjectJobRoles.length === 0}
              onClick={model.selectedProjectJobRoles.length > 0 ? model.showProjectJobRoleConfirmDeleteModal : null}
              className="mr-2 align-self-start"
              symbol={IconSymbols.Trash}
            >
              {I18n.t("phrases.delete")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={model.selectedProjectJobRoles.length === 0}
              onClick={model.selectedProjectJobRoles.length > 0 ? model.exportRows : null}
              className="mr-2 align-self-start"
              symbol={IconSymbols.Download}
            >
              {I18n.t("phrases.export")}
            </ButtonIcon>
            <ButtonIconDropdown
              iconSymbol={IconSymbols.Link}
              buttonProps={{
                size: UiSizes.SM,
                style: { display: "block" },
                type: ButtonTypes.LINK,
                className: "p-0 mr-2",
                isDisabled: model.selectedProjectJobRoles.length === 0
              }}
              tooltipWidth={180}
              actions={model.actions}
            >
              {I18n.t("phrases.link")}
            </ButtonIconDropdown>
            <span className="vertical-line"></span>
            <ButtonIcon
              type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              onClick={() => {
                setIsTextWrapToggled(!isTextWrapToggled);
                filterRef.current.toggleTextWrapper();
              }}
              symbol={IconSymbols.TextWrap}
              className="mr-2 align-self-start"
            >
              {I18n.t("phrases.textWrap")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasFilters}
              onClick={() => filterRef.current.clearFilters()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.ClearFilter}
            >
              {I18n.t("phrases.clearFilters")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasColumnChanges}
              onClick={() => filterRef.current.resetColumns()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.MonitorBack}
            >
              {I18n.t("phrases.resetColumns")}
            </ButtonIcon>
          </div>
          <div className="col-3">
            <ConnectedUserAvatars connectedUsers={projectJobRoleStore.connectedUsers} />
          </div>
          <div className="col-2">
            <div className="text-right">
              <Input
                autoFocus={true}
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
      </div>

      {!model.isLoading && (
        <div className={Animations.FP_ZOOM_IN}>
          <GridView
            context={{}}
            users={projectJobRoleStore.connectedUsers}
            data={projectJobRoleStore.data}
            onCellClicked={projectJobRoleStore.updateUserSelectedCell(organisationId, +projectId)}
            onCellEditModeChange={projectJobRoleStore.updateUserSelectedCell(organisationId, +projectId)}
            onSelectionChanged={model.updateSelectedProjectJobRoless}
            model={model.gridModel}
            isLoading={model.gridModel.isLoading}
            onGridReady={model.onGridReady}
            ref={filterRef}
            filterHasChangedFn={setHasFilters}
            columnOrderHasChangedFn={setHasColumnChanges}
            overlayNoRowsTemplate={null}
          />
        </div>
      )}
    </div>
  );
});
