import { ReadinessUploader } from "../../../../../../pages/insight/projects/AudiencesView/ReadinessTrackingView/ReadinessUploader/ReadinessUploader_view";
import I18n from "../../../../../localization/I18n";

export const READINESS_UPLOADER_MODAL_CONFIG = (pulseId: number, onAssignSuccess) => {
  return {
    showClose: true,
    title: <h1 className="mt-3 px-3">{I18n.t("phrases.uploadReadiness")}</h1>,
    content: (
      <>
        <img
          className="beta-ribbon-img"
          src="/images/assets/beta-ribbon.png"
          alt=""
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        <ReadinessUploader pulseId={pulseId} onAssignSuccess={onAssignSuccess} />
      </>
    )
  };
};
