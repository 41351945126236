import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { TrainingProgressModel } from "./TrainingProgress_model";
import { TrainingProgressGridView } from "../../../../../core/grids/builder/directorBuilder/grids/TrainingProgressGrid/TrainingProgressGridView_view";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export const TrainingProgress: React.FC = observer(() => {
  let organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new TrainingProgressModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <>
      <TrainingProgressGridView
        data={model.trainingProgressData}
        gridToolbarType="base"
        isLoading={model.isLoading}
        onFieldUpdate={model.loadTrainingProgressData}
        refreshDataHandler={model.loadTrainingProgressData}
      />
    </>
  );
});
