import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import { Enums } from "../../../../../../enums";
import { SingleForm } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { Animations } from "../../../../../util/Animations";

export const SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL = (
  itemIds: number[],
  removeItems: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeStakeholderFromOrg")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmStakeholderRangeRemove", { name: itemIds.length })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItems(itemIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_STAKEHOLDER_CREATE_MODAL = (stakeholderFormModel: any) => {
  return new Promise(resolve => {
    ModalContext.show({
      showClose: false,
      title: (
        <h4 className="mt-6 mb-5 d-inline-block c-pointer" onClick={() => ModalContext.hide()}>
          <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} /> {I18n.t("phrases.closeWindow")}
        </h4>
      ),
      content: (
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>{I18n.t("phrases.addPerson")}</h1>
            </div>
          </div>

          <Panel.Panel hasBorderRadius={true} className="p-3 mb-4">
            <div className="row">
              <div className="col">
                <SingleForm model={stakeholderFormModel} />
              </div>
            </div>
          </Panel.Panel>
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right"
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      },
      actions: []
    });
  });
};

// --------------------------
