import { action, makeObservable, observable } from "mobx";
import TrainingTypesApi from "../../../../../services/api/v2/trainingTypes/TrainingTypes.api";

export class TrainingTypeSettingsModel {
  organisationId: number;
  @observable data: FP.Entities.ITrainingType[];
  @observable isLoading: boolean = true;
  trainingTypeProvider = TrainingTypesApi;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
  }

  onMount = () => {
    this.loadTrainingTypeData();
  };

  @action
  loadTrainingTypeData = async () => {
    let res = await this.trainingTypeProvider.getAll(this.organisationId);
    this.setIsLoading(false);

    if (res.isError) {
      return;
    }
    this.setTrainingTypeData(res.payload);
  };

  @action
  setTrainingTypeData = (data: FP.Entities.ITrainingType[]) => {
    this.data = data;
  };
  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
