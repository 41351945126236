import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, Enums, GridTypes, ImpactField } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetImpactsGridActions } from "./ImpactsGrid_actions";
import { ImpactsGridColumnBuilder } from "./ImpactsGrid_columns";
import {
  IMPACT_BULK_EDIT_MODAL_CONFIG,
  IMPACT_UPLOADER_MODAL_CONFIG,
  LINK_TO_ACTIONS_MODAL_CONFIG,
  LINK_TO_BUSINESS_AREAS_MODAL_CONFIG,
  LINK_TO_IMPACT_GROUPS_MODAL_CONFIG,
  LINK_TO_LABELS_MODAL_CONFIG,
  LINK_TO_LOCATIONS_MODAL_CONFIG,
  LINK_TO_PROCESSES_MODAL_CONFIG,
  LINK_TO_STAKEHOLDERS_MODAL_CONFIG,
  SHOW_IMPACT_DELETE_CONFIRM_MODAL,
  SHOW_IMPACT_REVIEW_CONFIRM_MODAL
} from "./ImpactsGrid_modals";
import { IMPACT_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/ImpactNotesSidebarModal_config";
import { SHOW_CONFIRM_CREATION_MODAL } from "../../../../../../pages/insight/projects/Actions/ActionListView/ActionListView_modals";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { STAKEHOLDER_EMAILS_MODAL_CONFIG } from "./modals/stakeholderEmailsModal/StakeholderEmails_config";
import { NavigateFunction } from "react-router-dom";

export class ImpactsGridModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactsProvider: IImpactsApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewImpacts: boolean;
  userCanEditImpacts: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  impactsGridColumnBuilder: ImpactsGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  microImpactForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  @observable projectTeamMembers: any[];
  gridColumns: ColDef<any, any>[];
  columnDefs: FP.Entities.IColumnDef[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;
  @observable preSelectedItemIds: number[];
  gridType: EntityTypes | GridTypes;
  navigate: NavigateFunction;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    columnDefs: FP.Entities.IColumnDef[],
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes,
    navigate: NavigateFunction
  ) {
    makeObservable(this);
    this.urlSearchParams = urlSearchParams;
    this.gridType = gridType;
    this.columnDefs = columnDefs;
    this.setMicroAddForm();
    this.impactsProvider = ImpactsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanEditImpacts = PermissionsContext.canEditField(PermissionFields.IMPACTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.selectedItems = preSelectedItemIds;
    this.preSelectedItemIds = preSelectedItemIds;
    this.navigate = navigate;
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.userCanEditImpacts && this.gridToolbarType !== "link-modal") {
      await this.loadProjectTeamMembers();
      this.generateGridConfig();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, this.gridType ?? EntityTypes.IMPACTS);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.IMPACTS,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.IMPACTS,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.impactsGridColumnBuilder = new ImpactsGridColumnBuilder({
      canEdit: this.userCanEditImpacts && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewImpacts: this.userCanViewImpacts,
      projectTeamMembers: this.projectTeamMembers,
      columns: this.columnDefs
    });
    this.gridColumns = this.impactsGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetImpactsGridActions(this));
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  setSelectedNodes = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.preSelectedItemIds?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  @action
  deleteFieldData = async (impactId: number, impactField: ImpactField) => {
    const res = await this.impactsProvider.deleteGridField(this.organisationId, this.projectId, impactId, impactField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  duplicateItems = async () => {
    this.httpProgress.showOverlay();
    this.isDuplicatingItems = true;
    await this.impactsProvider.duplicateImpacts(this.organisationId, this.projectId, this.selectedItems);
    this.httpProgress.hideOverlay();
    this.isDuplicatingItems = false;
  };

  reviewItems = async (itemIds: number[], comment: string) => {
    let res = await this.impactsProvider.reviewRange(this.organisationId, this.projectId, itemIds, comment);

    if (!res || res.isError) return;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.impactsProvider.deleteRange(this.organisationId, this.projectId, itemIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-impact-group-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setMicroAddForm = () => {
    this.microImpactForm = new SingleFormModel();
    this.microImpactForm.formFields = getEntityNameMicroFormFields(
      this.createMicroImpact,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.impact") })
    );
  };

  @action
  createMicroImpact = async () => {
    let impactGroupFormRes = await this.microImpactForm.submit();
    this.microImpactForm.isSaving = true;
    if (!impactGroupFormRes) return;

    impactGroupFormRes = {
      ...impactGroupFormRes,
      projectId: this.projectId,
      tags: [],
      impactTypes: [],
      changeBenefitText: ""
    };

    this.httpProgress.showOverlay();
    let impactGroupExists = await this.impactsProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${impactGroupFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (impactGroupExists && !impactGroupExists.isError && impactGroupExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateImpact = await this.confirmCreateImpact(impactGroupFormRes.name);
      if (!confirmCreateImpact) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...impactGroupFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.impactsProvider.create(this.organisationId, this.projectId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    this.microImpactForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impact") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  openNoteCountModal = (impact: any) => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(IMPACT_NOTES_SIDEBAR_MODAL_CONFIG(this.projectId, this.organisationId, impact))
      .generateModal();
  };

  assignImpactGroupsToImpacts = async impactIds => {
    await this.impactsProvider.addBulkImpactGroups(this.organisationId, this.projectId, this.selectedItems, impactIds);
    this.modalService.hide();
  };

  showLinkToStakeholders = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_STAKEHOLDERS_MODAL_CONFIG(this.projectId, this.assignStakeholderToImpacts))
      .generateModal();
  };

  showLinkToActions = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_ACTIONS_MODAL_CONFIG(this.assignActionsToImpacts))
      .generateModal();
  };
  showLinkToProcesses = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_PROCESSES_MODAL_CONFIG(this.selectedItems, this.assignProcessesToImpacts))
      .generateModal();
  };
  showLinkToLocations = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_LOCATIONS_MODAL_CONFIG(this.assignLocationsToImpacts))
      .generateModal();
  };

  showLinkToBusinessAreas = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_BUSINESS_AREAS_MODAL_CONFIG(this.assignBusinessAreasToImpacts))
      .generateModal();
  };

  showLinkToImpactGroups = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_IMPACT_GROUPS_MODAL_CONFIG(this.assignImpactGroupsToImpacts))
      .generateModal();
  };

  showLinkToTags = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_LABELS_MODAL_CONFIG(this.projectId, this.assignLabelsToImpacts))
      .generateModal();
  };

  showUploaderModal = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(IMPACT_UPLOADER_MODAL_CONFIG())
      .setComponentProps({ overflow: "visible" })
      .generateModal();
  };

  showBulkEditModal = () => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(IMPACT_BULK_EDIT_MODAL_CONFIG(this.projectId, this.organisationId, this.selectedItems))
      .generateModal();
  };

  showImpactConfirmReviewModal = () => {
    return SHOW_IMPACT_REVIEW_CONFIRM_MODAL(this.selectedItems, this.reviewItems, this.toasterService, () =>
      this.gridApi.deselectAll()
    );
  };

  showEmailsModal = () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(STAKEHOLDER_EMAILS_MODAL_CONFIG(this.selectedItems, this.projectId))
      .generateModal();
  };

  showImpactConfirmDeleteModal = () => {
    return SHOW_IMPACT_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  assignStakeholderToImpacts = async (stakeholderIds: number[]) => {
    await this.projectStakeholderProvider.assignMultipleImpacts(
      this.organisationId,
      this.projectId,
      stakeholderIds,
      this.selectedItems
    );
    this.modalService.hide();
  };

  assignActionsToImpacts = async actionIds => {
    await this.impactsProvider.addBulkActionsToImpacts(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      actionIds
    );

    this.modalService.hide();
  };

  assignProcessesToImpacts = async projectProcessIds => {
    await this.impactsProvider.addBulkProjectProcessesToImpacts(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      projectProcessIds
    );

    this.modalService.hide();
  };

  assignLocationsToImpacts = async locations => {
    await this.impactsProvider.addBulkLocations(this.organisationId, this.projectId, this.selectedItems, locations);
    this.modalService.hide();
  };

  assignLabelsToImpacts = async labels => {
    await this.impactsProvider.addBulkLabels(this.organisationId, this.projectId, this.selectedItems, labels);
    this.modalService.hide();
  };

  assignBusinessAreasToImpacts = async businessAreas => {
    await this.impactsProvider.addBulkBusinessAreas(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      businessAreas
    );
    this.modalService.hide();
  };
}
