import { ICellEditorParams } from "ag-grid-community";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { forwardRef } from "react";
import { AutocompletePerson } from "../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../components/ui/Icon";
import "./_aggrid-autocomplete.scss";
import { AutocompleteOption } from "../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { Autocomplete } from "../../../components/ui/_forms/Autocomplete";
import I18nObj from "../../localization/I18n";

export default forwardRef((props: ICellEditorParams, ref) => {
  const [editing, setEditing] = useState(true);
  const { field, data, options, getValueLabel, allowFreeText, isPerson, filterFn, valueSelector } = props as any;
  const item = props.data[field];
  const [value, setValue] = useState(item);
  const items = typeof options === "function" ? options(data) : options;
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing, props.api]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });
  return (
    <Autocomplete
      className="ag-grid__autocomplete"
      componentProps={{
        icon: IconSymbols.ChevronDown
        // autoFocus: true
      }}
      isLoading={false}
      shouldAlwaysShowOptions={true}
      items={items}
      value={typeof item !== "undefined" && item !== null ? getValueLabel(item) : ""}
      onItemSelected={e => {
        setEditing(false);
        setValue(e);
      }}
      onEnterPress={e => {}}
      searchAttribute="label"
      allowFreeText={allowFreeText}
      filterFn={filterFn}
      noResultsFoundLabel={I18nObj.get("phrases.noResultsFound")}
    >
      {isPerson ? (
        <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
      ) : (
        <AutocompleteOption
          key={"e"}
          className={"autocomplete__chip"}
          label={e => {
            return valueSelector ? e[valueSelector] : e.label;
          }}
        />
      )}
    </Autocomplete>
  );
});
