import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import I18n from "../../../../../../localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { RenderXssSafeString } from "../../../../../../AntiXss/AntiXssHelper";
import { Hr } from "../../../../../../../components/ui/Hr";
import { QUERY_STRING_PARAMS } from "../../../../../../../services/local/queryStringService/QueryStringService";
import { Tag } from "../../../../../../../components/ui/Tag";

export interface BenefitDisplayProps {
  item: FP.Entities.IBenefit;
  isCompact?: boolean;
}
const getPostfix = (type: string) => {
  switch (type) {
    case "%":
      return "%";
    default:
      return "";
  }
};
const getPrefix = (type: string) => {
  switch (type) {
    case "$":
      return "$";
    case "£":
      return "£";
    default:
      return "";
  }
};

const BenefitDisplay: React.FunctionComponent<BenefitDisplayProps> = props => {
  const { item } = props;
  const organisationId = useCurrentOrganisationId();
  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-6">
          <h1 className="mb-0">
            {item.value === null
              ? I18n.t("table.noInput")
              : `${getPrefix(item.type)}${item.value}${getPostfix(item.type)}`}
          </h1>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <h5 className="mb-1">{I18n.t("table.description")}</h5>
          {item.description ? (
            <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
          ) : (
            <p>{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>
      <div className="row mb-4 mt-5">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.owner")}</h5>
          {(item.owner && item.owner?.sub !== "") || typeof item.owner?.sub !== "undefined" ? (
            <Link
              className="text-link-style body-bold"
              to={`/organisations/${organisationId}/users/${item.owner.sub}?${
                QUERY_STRING_PARAMS.RETURN_URL
              }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
            >
              {item.owner.firstName} {item.owner.lastName}
            </Link>
          ) : (
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.measurmentDate")}</h5>
          <p className="mb-0">{item.endDate === null ? I18n.t("table.noInput") : moment(item.endDate).format("L")}</p>
        </div>
      </div>
      <div className="row pb-5"></div>
      <div className="row pb-4 mt-5">
        <div className="col">
          <h5 className="mb-1">{I18n.t("phrases.tags")}</h5>
          {item.tags.length === 0 && <p className="mb-0">{I18n.t("table.noInput")}</p>}
          {item.tags.map(e => (
            <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
          ))}
        </div>
      </div>
    </>
  );
};
export { BenefitDisplay };
