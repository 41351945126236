import { OrgStakeholderEmails } from "./StakeholderEmails_view";

export const ORG_STAKEHOLDER_EMAILS_MODAL_CONFIG = (
  itemIds: number[],
  stakeholders: FP.Entities.IStakeholderSummary[]
) => {
  return {
    showClose: true,
    title: null,
    content: <OrgStakeholderEmails itemIds={itemIds} stakeholders={stakeholders} />
  };
};
