import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { TrainingTypeSettingsModel } from "./TrainingTypeSettings_model";
import { TrainingTypesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/TrainingTypesGrid/TrainingTypesGridView_view";
export const TrainingTypeSettings: React.FC = observer(() => {
  let organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new TrainingTypeSettingsModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div style={{ height: "100%" }}>
      <TrainingTypesGrid
        isLoading={model.isLoading}
        data={model.data}
        gridToolbarType="base"
        onFieldUpdate={model.loadTrainingTypeData}
        refreshDataHandler={model.loadTrainingTypeData}
      />
    </div>
  );
});
