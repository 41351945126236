import moment from "moment";
import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../../components/widgets/TimelineWidget/Timeline_model";
import { Tooltip } from "react-tippy";
import * as _ from "lodash";
import { Enums } from "../../../../../enums";
import { TimelineItem } from "../../../organisations/organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineItem";

export class ProjectTimelinePanelModel {
  timelineModel: TimeLineModel;
  project: FP.Entities.IProject;
  organisationId: number;
  projectId: number;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, project: FP.Entities.IProject) {
    this.project = project;
    this.timelineModel = new TimeLineModel();
    this.projectId = projectId;
    this.organisationId = organisationId;

    this.setTimelineDefaultDates([this.project]);
    this.setTimeLineItems([this.project]);
  }

  setTimelineDefaultDates = (projects: FP.Entities.IProject[]) => {
    const proj = projects.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.actualEndDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (projects: FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    const groups: ITimelineGroup[] = projects.map(project => {
      return {
        id: project.id,
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
            project.name
          )
      };
    });

    const sortedItems = _.sortBy(items, (e: any) => [e.group, e.start_time]);

    const markers: ITimelineMarker[] = sortedItems
      .map((project, index) => {
        return project.data.milestones.map(milestone => {
          const cs = milestone.milestoneType
            ? `timeline-marker__icon--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}`
            : "";
          return {
            id: milestone.id,
            date: moment(milestone.deadline),
            project: index * 40 + 10,
            typeClass: cs,
            title: milestone.name,
            description: milestone.description
          };
        });
      })
      .flat();
    this.timelineModel.timelineMinRows = 1;
    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.sidebarWidth = 0;
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = TimelineItem(this.organisationId, true);
    this.timelineModel.isLoading = false;
  };
}
