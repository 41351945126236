import { ColDef, GridApi, IDetailCellRendererParams, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { ActionField, EntityTypes, GridTypes } from "../../../../../../enums";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../../../services/api/v2/actionTypes/ActionTypes.api";
import AudienceStakeholdersApi, {
  AudienceStakeholdersApi as IAudienceStakeholdersApi
} from "../../../../../../services/api/v2/audenceStakeholders/AudienceStakeholders.api";
import AudiencesApi, { AudiencesApi as IAudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetPulseAudiencesGridActions } from "./PulseAudiencesGrid_actions";
import { PulseAudiencesGridColumnBuilder } from "./PulseAudiencesGrid_columns";
import {
  AuccaColDefFieldNamesEnum,
  AudienceColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { READINESS_UPLOADER_MODAL_CONFIG } from "./PulseAudiencesGrid_modals";

export class PulseAudiencesGridModel {
  organisationId: number;
  projectId: number;
  pulseId: number;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypesProvider: IActionTypesApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewAudiences: boolean;
  userCanEditAudiences: boolean;
  userCanViewStakeholders: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  audiencesGridColumnBuilder: PulseAudiencesGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  microAudienceForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  gridColumns: ColDef<any, any>[];
  detailGridColumns: ColDef<any, any>[];
  detailCellRendererParams: IDetailCellRendererParams<any, any>;
  urlSearchParams: URLSearchParams;
  columnDefs: FP.Entities.IColumnDef[] = [];
  nestedColumnDefs: FP.Entities.IColumnDef[] = [];
  gridToolbarType: AppGridToolbarType;
  audiencesProvider: IAudiencesApi;
  audienceStakeholdersProvider: IAudienceStakeholdersApi;
  gridType: EntityTypes | GridTypes;
  @observable isLoading: boolean = true;
  refreshDataHandler: any;

  constructor(
    organisationId: number,
    projectId: number,
    pulseId: number,
    authUser: FP.Entities.IUser,
    columnDefs: FP.Entities.IColumnDef[],
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes,
    refreshDataHandler: any
  ) {
    makeObservable(this);
    this.audienceStakeholdersProvider = AudienceStakeholdersApi;
    this.audiencesProvider = AudiencesApi;
    this.actionTypesProvider = ActionTypesApi;
    this.columnDefs = columnDefs || [];
    this.userCanViewAudiences = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanEditAudiences = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.pulseId = pulseId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.refreshDataHandler = refreshDataHandler;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.generateAddons();
  }

  @action
  onMount = async () => {
    this.generateFn();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.PULSE_AUDIENCES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PULSE_AUDIENCES,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });

    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PULSE_AUDIENCES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    const canEdit = this.userCanEditAudiences && this.gridToolbarType !== "link-modal";
    this.audiencesGridColumnBuilder = new PulseAudiencesGridColumnBuilder({
      audiencesProvider: this.audiencesProvider,
      canEdit: canEdit,
      organisationId: this.organisationId,
      projectId: this.projectId,
      pulseId: this.pulseId,
      userCanViewAudiences: this.userCanViewAudiences,
      onFieldUpdate: this.refreshDataHandler,
      columns: [
        CommonColDefFieldNamesEnum.Name,
        AudienceColDefFieldNamesEnum.Impact,
        AudienceColDefFieldNamesEnum.Size,
        AudienceColDefFieldNamesEnum.Influence,
        AudienceColDefFieldNamesEnum.Count,
        AuccaColDefFieldNamesEnum.Awareness,
        AuccaColDefFieldNamesEnum.Understanding,
        AuccaColDefFieldNamesEnum.Commitment,
        AuccaColDefFieldNamesEnum.Capability,
        AuccaColDefFieldNamesEnum.Adoption
      ]
    });
    this.gridColumns = this.audiencesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetPulseAudiencesGridActions(this));
  };

  @action
  onFirstDataRendered = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  showUploaderModal = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(READINESS_UPLOADER_MODAL_CONFIG(this.pulseId, this.refreshDataHandler))
      .setComponentProps({ overflow: "visible" })
      .generateModal();
  };

  @action
  deleteFieldData = async (itemId: number, field: ActionField) => {
    // const res = await this.actionsProvider.deleteField(this.organisationId, this.projectId, itemId, field);
    // if (res.isError) return false;
    // this.gridToastService.showToast(res.code, res.message);

    return true;
  };
}
