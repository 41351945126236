import {
  COMMITMENT_PROFILING_OPTIONS,
  IMPACT_LEVEL_OPTIONS,
  MILESTONE_TYPE_OPTIONS,
  PROGRESS_STATUS_OPTIONS,
  RAG_STATUS_OPTIONS,
  SENTIMENT_RECEPTIVENESS_OPTIONS,
  ZERO_TO_TEN_OPTIONS
} from "../../../constants";
import I18n from "../../localization/I18n";

export enum FilterComparators {
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  EQUALS = "equals",
  NOT_EQUAL = "notEqual",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith"
}

const containsComparatorLogic = (value, filterText) => {
  return value.indexOf(filterText) > -1;
};

const equalsComparatorLogic = (value, filterText) => {
  return value === filterText;
};

const startsWithComparatorLogic = (value, filterText) => {
  return value.startsWith(filterText);
};

const endsWithComparatorLogic = (value, filterText) => {
  return value.endsWith(filterText);
};

const getComparatorLogic = comparator => {
  if (comparator === FilterComparators.STARTS_WITH) return startsWithComparatorLogic;
  if (comparator === FilterComparators.ENDS_WITH) return endsWithComparatorLogic;
  if (comparator === FilterComparators.CONTAINS || comparator === FilterComparators.NOT_CONTAINS)
    return containsComparatorLogic;
  if (comparator === FilterComparators.EQUALS || comparator === FilterComparators.NOT_EQUAL)
    return equalsComparatorLogic;
};

const isNegativeTest = comparator => {
  return comparator === FilterComparators.NOT_CONTAINS || comparator === FilterComparators.NOT_EQUAL;
};

const comparatorFunction = (comparator, valueArray, filterText) => {
  const comparatorLogic = getComparatorLogic(comparator);
  const includesFilterValue = valueArray.find(value => comparatorLogic(value, filterText));

  if (isNegativeTest(comparator)) return !includesFilterValue;
  return includesFilterValue;
};

const pipeSeparatedComparatorFunction = (comparator, valueArray, filterText: string[]) => {
  const comparatorLogic = getComparatorLogic(comparator);
  let includesFilterValue = false;
  for (let i = 0; i < filterText.length; i++) {
    if (valueArray.find(value => comparatorLogic(value, filterText[i]))) {
      includesFilterValue = true;
    }
  }

  if (isNegativeTest(comparator)) return !includesFilterValue;
  return includesFilterValue;
};

export const multiValueTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();
  const valueLowerCase = value.toLowerCase();
  const valueArray = valueLowerCase.split("|");

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const progressStatusTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();
  // find the progress status based on the id
  var progressStatus = PROGRESS_STATUS_OPTIONS.find(x => x.key === Number(value));

  var valueArray = [];
  valueArray.push(I18n.t(progressStatus.label).toLowerCase());
  // add the text value to an array for the filter function
  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};
export const milestoneTypeTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();
  // find the mileston type based on the id
  var milestoneType = MILESTONE_TYPE_OPTIONS.find(x => x.key === Number(value));

  var valueArray = [];
  valueArray.push(I18n.t(milestoneType.label).toLowerCase());
  // add the text value to an array for the filter function
  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const zeroToTenTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();
  // find the progress status based on the id
  var item = ZERO_TO_TEN_OPTIONS.find(x => x.key === Number(value));

  // add the text value to an array for the filter function
  var valueArray = [];
  valueArray.push(item.key + " - " + I18n.t(item.label).toLowerCase());

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const impactLevelTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();

  // find the progress status based on the id
  let impactLevel = IMPACT_LEVEL_OPTIONS.find(x => x.key === Number(value));
  if (!impactLevel) return false;
  // add the text value to an array for the filter function
  let valueArray = [];
  valueArray.push(I18n.t(impactLevel.label).toLowerCase());

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const ragStatusTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();

  // find the progress status based on the id
  let ragStatus = RAG_STATUS_OPTIONS.find(x => x.key === Number(value));
  if (!ragStatus) return false;
  // add the text value to an array for the filter function
  let valueArray = [];
  valueArray.push(I18n.t(ragStatus.label).toLowerCase());

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const commitmentTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();

  // find the progress status based on the id
  let item = COMMITMENT_PROFILING_OPTIONS.find(x => x.key === Number(value));
  if (!item) return false;
  // add the text value to an array for the filter function
  let valueArray = [];
  valueArray.push(I18n.t(item.label).toLowerCase());

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const sentimentReceptivenessTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.toLowerCase();

  // find the progress status based on the id
  let item = SENTIMENT_RECEPTIVENESS_OPTIONS.find(x => x.key === Number(value));
  if (!item) return false;
  // add the text value to an array for the filter function
  let valueArray = [];
  valueArray.push(I18n.t(item.label).toLowerCase());

  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};

export const yesNoTextMatcher = ({ filterOption, value, filterText }) => {
  const filterTextLowerCase = filterText.trim().toLowerCase();
  let label;
  if (value === "true") {
    label = "yes";
  } else if (value === "false") {
    label = "no";
  } else {
    return false;
  }
  let valueArray = [];
  valueArray.push(label);
  return _filterValues(filterOption, filterTextLowerCase, valueArray);
};




function _filterValues(filterOption, filterTextLowerCase, valueArray) {
  const filterTextLowerCaseSplit = filterTextLowerCase.split("|");

  switch (filterOption) {
    case "contains":
      return comparatorFunction(FilterComparators.CONTAINS, valueArray, filterTextLowerCase);
    case "notContains":
      return comparatorFunction(FilterComparators.NOT_CONTAINS, valueArray, filterTextLowerCase);
    case "equals":
      return filterTextLowerCaseSplit.length > 1
        ? pipeSeparatedComparatorFunction(FilterComparators.EQUALS, valueArray, filterTextLowerCaseSplit)
        : comparatorFunction(FilterComparators.EQUALS, valueArray, filterTextLowerCase);
    case "notEqual":
      return comparatorFunction(FilterComparators.NOT_EQUAL, valueArray, filterTextLowerCase);
    case "startsWith":
      return comparatorFunction(FilterComparators.STARTS_WITH, valueArray, filterTextLowerCase);
    case "endsWith":
      return comparatorFunction(FilterComparators.ENDS_WITH, valueArray, filterTextLowerCase);
    default:
      return false;
  }
}
