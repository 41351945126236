import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProcessLinkModalModel } from "./ProcessLinkModal_model";
import { ProjectProcessesGridModalView } from "../../../../../../../../pages/insight/projects/Processes/ProcessReportingView/modals/ProjectProcessesGridModal/ProjectProcessesGridModal_view";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

interface ProcessLinkModalProps {
  projectId: number;
  trainingCourseId: number;
  trainingCourse?: FP.Entities.ITrainingCourse;
  onAssignSuccess?: any;
}

export const ProcessLinkModal: React.FC<ProcessLinkModalProps> = observer(props => {
  const { projectId, trainingCourse, onAssignSuccess } = props;
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProcessLinkModalModel(organisationId, projectId, trainingCourse));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner></PositionedSpinner>;
  return (
    <ProjectProcessesGridModalView
      onAssignSuccess={items => {
        ModalContext.hide();
        model.assignActionsToTrainingCourses(items, onAssignSuccess);
      }}
      selectedItemIds={model.selectedProjectProcessIds}
    />
  );
});
