import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import TrainingCoursesApi from "../../../../../../../../services/api/v2/trainingCourses/TrainingCourses.api";
import I18n from "../../../../../../../localization/I18n";
import { TagsSidebar } from "../../../../modals/TagSidebar/TagSidebar/TagsSidebar_view";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";

export const TRAINING_COURSE_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.ITrainingCourseSummary,
  onAssignSuccess: (organisationId: number, projectId: number, item: any, onAssignSuccess?: any) => any
) => {
  const loadTrainingCourse = async (trainingCourseId: number): Promise<FP.Entities.ITrainingCourse> => {
    let res = await TrainingCoursesApi.getDetailedById(organisationId, projectId, trainingCourseId);
    if (!res || res.isError) return;
    return res.payload;
  };

  const addTrainingCourseTag = async (tag: FP.Entities.ITag): Promise<FP.Entities.ITag> => {
    let res = await TagsApi.addTrainingCourseTagAssoc(organisationId, projectId, item.id, tag);
    if (!res || res.isError) return;
    onAssignSuccess(organisationId, projectId, item);
    return res.payload;
  };

  const removeTrainingCourseTag = async (tag: any): Promise<boolean> => {
    let res = await TagsApi.removeTrainingCourseTagAssoc(organisationId, projectId, tag.id, item.id);
    if (!res || res.isError) return;
    onAssignSuccess(organisationId, projectId, item);
    return res.payload;
  };

  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.tags")} />,
    content: (
      <TagsSidebar
        organisationId={organisationId}
        projectId={projectId}
        itemId={item.id}
        onAssignSuccess={onAssignSuccess}
        loadItem={loadTrainingCourse}
        addTagAssociation={addTrainingCourseTag}
        removeTagAssociation={removeTrainingCourseTag}
      />
    )
  };
};
