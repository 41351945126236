import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../localization/I18n";
import { IModalContextModel } from "../../context/IModalContext";
import ModalContext from "../../context/ModalContext";
import { UiActionRenderers } from "../../../uiAction/IUiAction";
import { BaseModel } from "../../../util/BaseModel";
import { SingleFormModel } from "../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { getFilterTagFormFields } from "./TagsSidebar_fields";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";

export class TagsSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  modalService: IModalContextModel;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  @observable initialTags: FP.Entities.ITag[];
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;
  onSubmit: (tagIds: number[]) => void;
  actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        this.submitForm();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  @observable currentTagIds: number[];

  constructor(
    organisationId: number,
    projectId: number,
    onSubmit: (tagIds: number[]) => void,
    currentTagIds: number[],
    data: FP.Entities.ITag[]
  ) {
    super();
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.onSubmit = onSubmit;
    this.currentTagIds = currentTagIds;
    this.initialTags = data;
    this.projectId = projectId;
    this.loadSidebar();
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  submitForm = async () => {
    await this.formModel.submit();
    this.onSubmit(this.currentTagIds);
    this.modalService.hide();
  };

  @action
  loadSidebar = async () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getFilterTagFormFields(
      this.currentTagIds,
      this.initialTags,
      this.addTagId,
      this.removeTagId
    );
    this.formModel.actions = this.actions;
  };

  @action
  addTagId = (tagId: number) => {
    this.currentTagIds = [...this.currentTagIds, tagId];
  };

  @action
  removeTagId = (tagId: number) => {
    let currTags = this.currentTagIds;
    currTags.splice(this.currentTagIds.indexOf(tagId));
    this.currentTagIds = [...currTags];
  };
}
