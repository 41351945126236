import { ColDef, GridApi, IDetailCellRendererParams, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { ActionField, EntityTypes, GridTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../../../services/api/v2/actionTypes/ActionTypes.api";
import AudienceStakeholdersApi, {
  AudienceStakeholdersApi as IAudienceStakeholdersApi
} from "../../../../../../services/api/v2/audenceStakeholders/AudienceStakeholders.api";
import AudiencesApi, { AudiencesApi as IAudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { SHOW_CONFIRM_CREATION_MODAL } from "../ActionsGrid/ActionsGrid_modals";
import { AudienceStakeholdersGridColumnBuilder } from "../AudienceStakeholdersGrid/AudienceStakeholdersGrid_columns";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetAudiencesGridActions } from "./AudiencesGrid_actions";
import { AudiencesGridColumnBuilder } from "./AudiencesGrid_columns";
import { SHOW_AUDIENCE_DELETE_CONFIRM_MODAL, SHOW_AUDIENCES_PEOPLE } from "./AudiencesGrid_modals";

export class AudiencesGridModel {
  organisationId: number;
  projectId: number;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypesProvider: IActionTypesApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewAudiences: boolean;
  userCanEditAudiences: boolean;
  userCanViewStakeholders: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  audiencesGridColumnBuilder: AudiencesGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  microAudienceForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  gridColumns: ColDef<any, any>[];
  detailGridColumns: ColDef<any, any>[];
  detailCellRendererParams: IDetailCellRendererParams<any, any>;
  urlSearchParams: URLSearchParams;
  columnDefs: FP.Entities.IColumnDef[] = [];
  nestedColumnDefs: FP.Entities.IColumnDef[] = [];
  gridToolbarType: AppGridToolbarType;
  audiencesProvider: IAudiencesApi;
  audienceStakeholdersProvider: IAudienceStakeholdersApi;
  gridType: EntityTypes | GridTypes;
  @observable isLoading: boolean = true;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    columnDefs: FP.Entities.IColumnDef[],
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes
  ) {
    makeObservable(this);
    this.audienceStakeholdersProvider = AudienceStakeholdersApi;
    this.audiencesProvider = AudiencesApi;
    this.actionTypesProvider = ActionTypesApi;
    this.columnDefs = columnDefs || [];
    this.userCanViewAudiences = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanEditAudiences = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.setMicroAddForm();
    this.generateAddons();
  }

  loadInitData = async () => {
    await this.loadNestedColumnDefs();
    this.generateFn();
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateDetailGridConfig();
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  loadNestedColumnDefs = async () => {
    let res = await this.audienceStakeholdersProvider.getColumnDefs(this.organisationId, this.projectId);
    this.nestedColumnDefs = res.payload;
  };

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, this.gridType ?? EntityTypes.AUDIENCES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.AUDIENCES,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });

    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.AUDIENCES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    const canEdit = this.userCanEditAudiences && this.gridToolbarType !== "link-modal";
    this.audiencesGridColumnBuilder = new AudiencesGridColumnBuilder({
      audiencesProvider: this.audiencesProvider,
      canEdit: canEdit,
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewAudiences: this.userCanViewAudiences,
      columns: this.columnDefs,
      gridToolbarType: this.gridToolbarType
    });
    this.gridColumns = this.audiencesGridColumnBuilder.generateColumnDefs();
  };

  generateDetailGridConfig = () => {
    let detailGridColumnBuilder = new AudienceStakeholdersGridColumnBuilder({
      organisationId: this.organisationId,
      projectId: this.projectId,
      canEdit: this.userCanEditAudiences && this.gridToolbarType !== "link-modal",
      deletePersonFromAudience: this.deletePersonFromAudience,
      userCanViewStakeholders: this.userCanViewAudiences,
      columns: this.nestedColumnDefs
    });

    this.detailGridColumns = detailGridColumnBuilder.generateColumnDefs();
    this.detailCellRendererParams = {
      refreshStrategy: "rows",
      detailGridOptions: {
        columnDefs: this.detailGridColumns,
        defaultColDef: {
          flex: 1
        },
        keepDetailRows: true
      },
      getDetailRowData: params => {
        this.audiencesProvider.getStakeholders(this.organisationId, this.projectId, params.data.id).then(e => {
          params.successCallback(e.payload);
        });
      }
    } as IDetailCellRendererParams<any, any>;
  };

  generateActions = () => {
    this.setGridActions(GetAudiencesGridActions(this));
  };

  deletePersonFromAudience = async (k, i) => {
    this.httpProgress.showOverlay();
    await this.audienceStakeholdersProvider.remove(this.organisationId, this.projectId, i.data.id);
    this.httpProgress.hideOverlay();
  };

  @action
  onFirstDataRendered = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  @action
  setMicroAddForm = () => {
    this.microAudienceForm = new SingleFormModel();
    this.microAudienceForm.formFields = getEntityNameMicroFormFields(
      this.createMicroAudience,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.audiences") })
    );
  };

  @action
  createMicroAudience = async () => {
    let microAudienceFormRes = await this.microAudienceForm.submit();
    this.microAudienceForm.isSaving = true;
    if (!microAudienceFormRes) return;

    microAudienceFormRes = { ...microAudienceFormRes, projectId: this.projectId, impacts: [], tags: [] };

    const res = {
      ...microAudienceFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.audiencesProvider.create(
      this.organisationId,
      this.projectId,
      res as FP.Entities.IAudience
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const audience = result.payload;
    this.microAudienceForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.audience") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return audience;
  };

  confirmCreateAudience = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  showAudienceConfirmDeleteModal = () => {
    return SHOW_AUDIENCE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  @action
  deleteFieldData = async (itemId: number, field: ActionField) => {
    // const res = await this.actionsProvider.deleteField(this.organisationId, this.projectId, itemId, field);
    // if (res.isError) return false;
    // this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.audiencesProvider.deleteRange(this.organisationId, this.projectId, itemIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  showPeople = async () => {
    return SHOW_AUDIENCES_PEOPLE(this.selectedItems);
  };

  duplicateItems = async () => {
    this.httpProgress.showOverlay();
    this.isDuplicatingItems = true;
    // await this.actionsProvider.duplicateActions(this.organisationId, this.projectId, this.selectedItems);
    this.httpProgress.hideOverlay();
    this.isDuplicatingItems = false;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-audiences-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };
}
