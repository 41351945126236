import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { valueOrNoInput } from "../../utils/helpers";
import { BaseColumnBuilder } from "./BaseColumn_builder";
export abstract class BaseTextColumnBuilder extends BaseColumnBuilder {
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;

  createValueGetter = () => {
    this.setColumnOptions({
      valueGetter: getterProps => valueOrNoInput(getterProps.data[this.columnOptionsBuilder.columnOptions.field])
    });
    return this;
  };
  createValueSetter = (updateField: (entityId, text) => Promise<any>) => {
    this.setColumnOptions({
      valueSetter: params => {
        if (!params.newValue) return false;
        updateField(params.data.id, params.newValue);
        return true;
      }
    });
    return this;
  };

  createEmailValueSetter = (updateField: (entityId, text) => Promise<any>) => {
    this.setColumnOptions({
      valueSetter: params => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(params.newValue) && params.newValue?.length > 0;

        const previousValue = params.data.email;
        params.data.email = params.newValue;
        params.data.emailValid = isValid;

        if (!isValid) {
          setTimeout(() => {
            params.data.email = previousValue;
            params.api.refreshCells({ rowNodes: [params.node], columns: ["email"] });
          }, 1000);
        } else {
          updateField(params.data.id, params.newValue);
        }

        return true;
      }
    });
    return this;
  };

  setEmailValidationClassRules = () => {
    this.setColumnOptions({
      cellClassRules: {
        "invalid-email": params => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return !emailRegex.test(params.value);
        }
      }
    });

    return this;
  };
}
