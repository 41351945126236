import { ColDef } from "ag-grid-community";
import _ from "lodash";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { Enums, Translator } from "../../../../../../enums";
import Pages from "../../../../../../routes/InsightRoutes";
import CustomFieldsApi from "../../../../../../services/api/v2/customFields/CustomFields.api";
import ProjectStakeholdersApi from "../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import {
  commitmentTextMatcher,
  sentimentReceptivenessTextMatcher,
  yesNoTextMatcher,
  zeroToTenTextMatcher
} from "../../../../filters/TextMatcher";
import { CommonColDefFieldNamesEnum, StakeholderColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { CustomColumnFieldTypeEnum } from "../../../enums/AgGridCustomColDefEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { RefNumberColumnBuilder } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_builder";
import { REF_NUMBER_COLUMN_CONFIG } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { StakeholderPauseCell, StakeholderProfilingCell } from "./StakeholderGridView_ui";
import { PROJECT_STAKEHOLDER_OWNED_ACTIONS_ACTIONS_MODAL_CONFIG } from "./modals/actionSidebar/ProjectStakeholderOwnedActionsSidebarModal_config";
import { PROJECT_STAKEHOLDER_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/ProjectStakeholderNotesSidebarModal_config";
import { PROJECT_STAKEHOLDER_OWNER_ACTIONS_MODAL_CONFIG } from "./modals/ownersSidebar/ProjectStakeholderOwnersSidebarModal_config";
import { PROJECT_STAKEHOLDER_IMPACTS_SIDEBAR_MODAL_CONFIG } from "./modals/projectStakeholderImpactsSidebar/ProjectStakeholderImpactsSidebarModal_config";
import { PROJECT_STAKEHOLDER_TAGS_SIDEBAR_MODAL_CONFIG } from "./modals/tagsSidebar/ProjectStakeholderTagsSidebarModal_config";
import { ProjectStakeholderField } from "../../utils/GridFields";

export interface ProjectStakeholdersGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewStakeholders: boolean;
  columns: FP.Entities.IColumnDef[];
  projectTeamMembers: any[];
  isKeyStakeholdersOptions: any;
}

export class ProjectStakeholdersGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ProjectStakeholdersGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectId: number;
  projectTeamMembers: any[];
  isKeyStakeholdersOptions: any;
  levelClassRules: any;
  customProfileFieldOptions: any;

  constructor(gridProps: ProjectStakeholdersGridColumnBuilderProps) {
    super(ProjectStakeholdersApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.projectTeamMembers = gridProps.projectTeamMembers;
    this.isKeyStakeholdersOptions = gridProps.isKeyStakeholdersOptions;
    this.levelClassRules = {
      "stakeholder-grid__cell--unknown": params =>
        params.value <= 0 || typeof params.value === "undefined" || params.value === null,
      "stakeholder-grid__cell--low": params => params.value >= 1 && params.value <= 4,
      "stakeholder-grid__cell--medium": params => params.value >= 5 && params.value <= 7,
      "stakeholder-grid__cell--high": params => params.value > 7
    };
    this.customProfileFieldOptions = [
      {
        key: 1,
        label: `1 - ${I18n.t("phrases.none")}`
      },
      {
        key: 2,
        label: `2 - ${I18n.t("phrases.poor")}`
      },
      {
        key: 3,
        label: `3 - ${I18n.t("phrases.fair")}`
      },
      {
        key: 4,
        label: `4 - ${I18n.t("phrases.good")}`
      },
      {
        key: 5,
        label: `5 - ${I18n.t("phrases.great")}`
      }
    ];

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [StakeholderColDefFieldNamesEnum.Email]: () =>
        new SimpleTextColumnBuilder()
          .makeReadOnly(true)
          .makeSelectable(false)
          .makeEditable(false)
          .setColumnOptions({ field: StakeholderColDefFieldNamesEnum.Email, headerName: I18n.t("grids.email") })
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Role]: () =>
        new SimpleTextColumnBuilder()
          .makeReadOnly(true)
          .makeSelectable(false)
          .makeEditable(false)
          .setColumnOptions({ field: StakeholderColDefFieldNamesEnum.Role, headerName: I18n.t("grids.role") })
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.BusinessArea]: () =>
        new SimpleTextColumnBuilder()
          .makeReadOnly(true)
          .makeSelectable(false)
          .makeEditable(false)
          .setColumnOptions({
            field: StakeholderColDefFieldNamesEnum.BusinessArea,
            headerName: I18n.t("grids.businessArea")
          })
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.OwnerNames]: () =>
        this.buildPillsColumn(
          StakeholderColDefFieldNamesEnum.OwnerNames,
          I18n.t("grids.ownerNames"),
          PROJECT_STAKEHOLDER_OWNER_ACTIONS_MODAL_CONFIG
        ).generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.IsKeyStakeholder]: () => this.buildIsKeyStakeholderColumn(),
      [StakeholderColDefFieldNamesEnum.Influence]: () =>
        this.buildProfilingColumn(
          StakeholderColDefFieldNamesEnum.Influence,
          ProjectStakeholderField.influence,
          `${I18n.t("grids.influence")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.InfluenceLevelMapped(),
          zeroToTenTextMatcher
        )
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            valueFormatter: params => {
              const influence = Translator.InfluenceLevelMapped().find(e => e.key === params.value + "");
              return influence ? influence.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Impact]: () =>
        this.buildProfilingColumn(
          StakeholderColDefFieldNamesEnum.Impact,
          ProjectStakeholderField.impact,
          `${I18n.t("grids.impact")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.ImpactLevelMapped(),
          zeroToTenTextMatcher
        )
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.ImpactLevel(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.ImpactLevelMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Sentiment]: () =>
        this.buildProfilingColumn(
          StakeholderColDefFieldNamesEnum.Sentiment,
          ProjectStakeholderField.sentiment,
          `${I18n.t("grids.sentiment")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.StakeholderProfilingMapped(),
          sentimentReceptivenessTextMatcher
        )
          .withCellRenderer(StakeholderProfilingCell)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Commitment]: () =>
        this.buildProfilingColumn(
          StakeholderColDefFieldNamesEnum.Commitment,
          ProjectStakeholderField.commitment,
          `${I18n.t("grids.commitment")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.StakeholderCommitmentOptions(),
          commitmentTextMatcher,
          { width: 220 }
        )
          .withCellRenderer(StakeholderProfilingCell)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Receptiveness]: () =>
        this.buildProfilingColumn(
          StakeholderColDefFieldNamesEnum.Receptiveness,
          ProjectStakeholderField.receptiveness,
          I18n.t("grids.receptiveness"),
          this.gridProps.canEdit,
          Translator.StakeholderProfilingMapped(),
          sentimentReceptivenessTextMatcher
        )
          .withCellRenderer(StakeholderProfilingCell)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.OwnedActions]: () =>
        this.buildPillsColumn(
          StakeholderColDefFieldNamesEnum.OwnedActions,
          I18n.t("grids.ownedActions"),
          PROJECT_STAKEHOLDER_OWNED_ACTIONS_ACTIONS_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Impacts]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Impacts,
          I18n.t("grids.impacts"),
          PROJECT_STAKEHOLDER_IMPACTS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [StakeholderColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          StakeholderColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          PROJECT_STAKEHOLDER_TAGS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.ReviewedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ReviewedBy,
          headerName: I18n.t("grids.reviewedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.LastReviewedOn]: () =>
        new DateColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.LastReviewedOn,
          headerName: I18n.t("grids.lastReviewedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(StakeholderColDefFieldNamesEnum.LastReviewedOn, "")
          .withComparator()
          .setValueFormatter(StakeholderColDefFieldNamesEnum.LastReviewedOn)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NextReviewDue]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.NextReviewDue,
          headerName: I18n.t("grids.nextReviewDue")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.NextReviewDue, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.NextReviewDue)
          .withEndDateCellRenderer()
          .withCellClass(["ag-cell--not-editable"])
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.CreatedAt,
          headerName: I18n.t("grids.createdOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(StakeholderColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(StakeholderColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(StakeholderColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(StakeholderColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];

    if (this.gridProps.columns?.length === 0) {
      return _.map(this.columnDefs, e => {
        return e();
      });
    }
    res.push(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());
    this.gridProps.columns.forEach(e => {
      if (e.isCore) {
        let col = this.columnDefs[e.colName];
        if (typeof col === "function") {
          res.push(col());
        } else {
          throw new Error(`Typeof columnDef with name "${e.colName}" is not a function`);
        }
      } else {
        let col;
        if (e.customFieldTypeId === CustomColumnFieldTypeEnum.Int) {
          col = this.buildProfilingColumn(
            e.colName,
            e.customFieldId,
            e.header,
            this.gridProps.canEdit,
            this.customProfileFieldOptions,
            null
          )
            .withCellRenderer(StakeholderPauseCell)
            .setValueSetter(params => {
              if (!params.newValue) return false;

              this.updateDynamicFieldValueInt(e.customFieldId)(params.data.id, params.newValue?.key);

              return true;
            });

          if (this.gridProps.canEdit) {
            col.makeEditable().makeDeletable().setFilterOptions(BASE_FILTER_CONFIG);
          }

          col = col.generateColumnOptions();
        } else {
          col = this.buildDynamicColumn(
            e,
            this.gridProps.canEdit,
            this.updateDynamicFieldValueInt,
            this.updateDynamicFieldValueString
          );
        }

        res.push(col);
      }
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new RefNumberColumnBuilder()
      .makeEditable(false)
      .makeSelectable(this.gridProps.canEdit)
      .makeReadOnly(false)
      .setColumnOptions({
        ...REF_NUMBER_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Name, headerName: I18n.t("grids.name") })
      })
      .withLinkCell(
        stakeholderId =>
          Pages.projects.stakeholders.extendedView.generateLink(this.organisationId, this.projectId, stakeholderId),
        CommonColDefFieldNamesEnum.Name,
        StakeholderColDefFieldNamesEnum.StakeholderId
      )
      .setFilterOptions(BASE_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  buildIsKeyStakeholderColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .makeEditable(this.gridProps.canEdit)
      .makeSelectable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setColumnOptions({
        field: StakeholderColDefFieldNamesEnum.IsKeyStakeholder,
        headerName: I18n.t("grids.keyStakeholder")
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;
        params.data.progressStatus = +params.newValue.key;
        this.updateIsKeyStakeholder(params.data.id, params.newValue.key);
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: StakeholderColDefFieldNamesEnum.IsKeyStakeholder,
          getValueLabel: (ee: any) => {
            // if true pick the positive option else pick the negative
            // only two options should be available here at all times
            return ee ? this.isKeyStakeholdersOptions[1].label : this.isKeyStakeholdersOptions[0].label;
          },
          options: this.isKeyStakeholdersOptions ?? []
        }
      })
      .withCellEditor()
      .withCellRenderer(props => {
        let val = props.data[props.colDef.field];
        return val ? I18n.t("phrases.yes") : <span style={{ color: "#aaa" }}>{I18n.t("phrases.no")}</span>;
      })
      .setFilterOptions({
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: yesNoTextMatcher
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable(true);
    }
    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions({ ...NOTE_COUNT_COLUMN_CONFIG(), field: StakeholderColDefFieldNamesEnum.NoteCount })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      const notesModal = (item: FP.Entities.IProjectStakeholderSummary) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(PROJECT_STAKEHOLDER_NOTES_SIDEBAR_MODAL_CONFIG(this.organisationId, this.projectId, item))
          .generateModal();

      model.setEditableOnDoubleClick(notesModal);
    }
    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDynamicFieldValueString = customFieldId => async (entityId: number, valueString: string) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateProjectStakeholderCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      null,
      valueString
    );
  };

  updateDynamicFieldValueInt = customFieldId => async (entityId: number, valueInt: number) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateProjectStakeholderCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      valueInt,
      null
    );
  };

  updateIsKeyStakeholder = async (entityId: number, text: string) => {
    await this.updateTextField(ProjectStakeholderField.isKeyStakeholder, entityId, text);
  };
  updateSentiment = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.sentiment, entityId, id);
  };
  updateCommitment = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.commitment, entityId, id);
  };
  updateReceptiveness = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.receptiveness, entityId, id);
  };
  //#endregion
}
