import moment from "moment";
import { Button, ButtonTypes } from "../Button";
import { Checkbox } from "../Checkbox";
import { Panel } from "../Panel";

export const ScrollableModalTitle = ({ title, version, lastUpdated }) => {
  return (
    <div className="d-flex flex-column justify-content-start align-middle py-4">
      <h1 className="m-0">{title}</h1>
      <span className="font-italic">Version: {version}</span>
      <span className="font-italic">Last updated: {moment(lastUpdated).format("L")}</span>
    </div>
  );
};

export const ScrollableModalContent = ({ onScrollBottom, content }) => {
  return (
    <Panel.Panel type={Panel.PanelTypes.OUTLINES} background={Panel.PanelBackgrounds.BG_WHITE} hasShadow={true}>
      <div>
        <div onScroll={onScrollBottom} className="scrollableModal__content p-4 mb-3">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    </Panel.Panel>
  );
};

export const ScrollableModalFooter = ({ isChecked, isDisabled = true, logout, onChange, onClickProceed }) => {
  return (
    <div className="scrollableModal__footer py-4">
      <div className="scrollableModal__checkbox">
        <Checkbox
          disabled={isDisabled}
          className="mr-2"
          label="Accept"
          key="accept"
          value="accept"
          onChange={onChange}
          checked={isChecked}
        />
        <p className="p-0 m-0">Read the above to accept</p>
      </div>
      <div className="scrollableModal_buttons">
        <Button
          onClick={onClickProceed}
          isDisabled={!isChecked}
          className="mr-2"
          type={isChecked ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_INFO}
        >
          Proceed
        </Button>
        <Button type={ButtonTypes.OUTLINE_INFO} onClick={logout}>
          Log out
        </Button>
      </div>
    </div>
  );
};
