import _ from "lodash";
import { IconSymbols } from "../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../constants";
import { IMultiSelectorModel } from "../../../forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../forms/helpers/FormFieldMappers";
import { filterOutExistingTagVals } from "../../../grids/GridHelpers";
import I18n from "../../../localization/I18n";

export const getFilterTagFormFields = (
  currentTagIds: number[],
  projectActionTags: FP.Entities.ITag[],
  addTagId: (tagId: number) => void,
  removeTagId: (tagId: number) => void
) => {
  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    placeholder: I18n.t("placeholders.searchTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => e.text} />
    ),
    onItemSelected: e => {
      addTagId(e.id);
    },
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const currentVals = projectActionTags?.map(e => {
        if (self.extractValue().includes(e.id)) {
          return e.text;
        }
        return null;
      });

      const sortedTags = _.orderBy(projectActionTags, [tag => tag.text.toLowerCase()]);
      self.setOptions(filterOutExistingTagVals(currentVals, sortedTags));
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: projectActionTags?.filter(e => currentTagIds.includes(e.id)),
    isHidden: false,
    valueLabelFn: e => e?.text,
    removeItemFn: e => {
      return removeTagId(e.id);
    },
    isTagSelector: true,
    allowFreeText: true,
    noResultsFoundLabel: I18n.t("forms.noResultsFound")
  };

  const fields = [];

  fields.push(tags);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
