import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { BenefitGrid } from "../../../../../core/grids/builder/directorBuilder/grids/BenefitsGrid/BenefitsGridView_view";
import { BenefitTrackerSettingsModel } from "../BenefitsTrackerSettings/BenefitTrackerSettings_model";
import { useParams } from "react-router-dom";
import { useFlightPathUser } from "../../../../../core/auth_insight/UserContext";

export const BenefitTrackerSettings: React.FC = observer(() => {
  let { projectId } = useParams<{ projectId: string }>();
  let organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const [model, setModel] = useState(() => new BenefitTrackerSettingsModel(organisationId, +projectId, authUser));

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    if (+projectId !== model.projectId) {
      setModel(new BenefitTrackerSettingsModel(organisationId, +projectId, authUser));
    }
  }, [organisationId, projectId, authUser, model.projectId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <>
      <BenefitGrid
        data={model.BenefitData}
        gridToolbarType="base"
        isLoading={model.isLoading}
        onFieldUpdate={model.loadBenefitData}
        refreshDataHandler={model.loadBenefitData}
      />
    </>
  );
});
