import { useState } from "react";
import { Panel } from "../../../../../components/ui/Panel";
import { TimelineView } from "../../../../../components/widgets/TimelineWidget/Timeline_view";
import I18n from "../../../../../core/localization/I18n";
import { TimelineMilestoneLegend } from "../../../organisations/dashboard/Timeline/components/TimelineMilestoneLegend";
import { ProjectTimelinePanelModel } from "./ProjectTimelinePanel_model";
import { useParams } from "react-router-dom";

export const TimelinePanel: React.FC<{ project: FP.Entities.IProject }> = ({ project }) => {
  const { organisationId, projectId } = useParams();
  const [model] = useState(() => new ProjectTimelinePanelModel(+organisationId, +projectId, project));

  return (
    <>
      {project && (
        <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="pb-4">
          <TimelineView
            model={model.timelineModel}
            hideControls={false}
            timelineProps={{ lineHeight: 40 } as any}
            className={`project-overview__timeline`}
            labels={{
              noItemsText: I18n.t("phrases.noProjectsOrMilestones"),
              today: I18n.t("phrases.today"),
              scrollLeft: I18n.t("phrases.scrollLeft"),
              scrollRight: I18n.t("phrases.scrollRight"),
              zoomOut: I18n.t("phrases.zoomOut"),
              zoomIn: I18n.t("phrases.zoomIn"),
              resetZoom: I18n.t("phrases.resetZoom")
            }}
          />
          <div className="spacer py-5"></div>
        </Panel.Panel>
      )}
      {project.milestones && project.milestones.length > 0 && (
        <div className="col mb-2">
          <TimelineMilestoneLegend milestoneTypes={project.milestones.map(e => e.milestoneType)} />
        </div>
      )}
    </>
  );
};
