import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ProjectStakeholderNotesSidebar } from "./ProjectStakeholderNotesSidebar";

export const PROJECT_STAKEHOLDER_NOTES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.IProjectStakeholderSummary
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.notes")} />,
    content: <ProjectStakeholderNotesSidebar projectId={projectId} itemId={item.id} />
  };
};
