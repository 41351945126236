import { BaseModel } from "../../../../../core/util/BaseModel";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetChangePasswordFields } from "./ChangePassword_fields";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { observable, action, makeObservable } from "mobx";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../services/api/v2/users/Users.api";

export class SecuritySettingsModel extends BaseModel {
  formModel: SingleFormModel;
  usersProvider: IUsersApi;
  httpProgress: IProgressIndicatorModel;
  @observable errorMessage: string = "";
  organisationId: number;
  navigate: any;
  /**
   *
   */
  constructor(organisationId: number, navigate) {
    super();
    makeObservable(this);
    this.navigate = navigate;

    this.formModel = new SingleFormModel();
    this.httpProgress = ProgressIndicatorModel;
    this.usersProvider = UsersApi;
    this.formModel.formFields = GetChangePasswordFields();
    this.organisationId = organisationId;
  }

  @action
  resetErrorMessage = () => {
    this.errorMessage = "";
  };

  @action
  updatePassword = async () => {
    this.resetErrorMessage();
    let res = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.usersProvider.setPassword(this.organisationId, res.currentPassword, res.newPassword);
    this.httpProgress.hideOverlay();
    if (!result.payload as any) {
      this.errorMessage = (result.payload as any).message
        .split(":")
        .filter((e, i) => i !== 0)
        .map((e, i) => (i === 0 ? e : ": " + e))
        .join("");
    } else if (result && result.payload) {
      this.navigate("/");
    }
  };
}
