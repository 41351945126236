import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { ExportableOrganisationApi } from "../exportableApi/ExportableOrganisationApiModel";
import { StakeholderField, StakeholderUploadField } from "../../../../enums";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class StakeholdersApi extends ExportableOrganisationApi<FP.Entities.IStakeholder> {
  controller: string = "stakeholders";

  constructor(http: AxiosInstance) {
    super(http, "stakeholders");
  }

  getGridData = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}/grid-data`, config);
    return res.data;
  };

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    return res.data;
  };

  getDetailedById = async (organisationId: number, stakeholderId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/stakeholders/${stakeholderId}/detailed`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getAudiencesByProgrammeId = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/stakeholders/programme/${programmeId}/audiences`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getStakeholderProjectsWithImpacts = async (
    organisationId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/stakeholders/${stakeholderId}/assigned-projects-with-impacts`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  getStakeholderProjectsWithActions = async (
    organisationId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/stakeholders/${stakeholderId}/assigned-projects-with-actions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  deleteRange = async (organisationId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.put(`${this.url}/${organisationId}/${this.controller}/delete-range`, ids, config);
    return res.data;
  };

  deleteField = async (
    organisationId: number,
    stakeholderId: number,
    stakeholderField: StakeholderField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/${this.controller}/${stakeholderId}/${stakeholderField}`,
      config
    );
    return res.data;
  };

  //#endregion CRUD

  bulkUpload = async (
    organisationId: number,
    stakeholders: any[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.put(this.url + `/${organisationId}/stakeholders/bulk-change-roles`, stakeholders, config);
    return res.data;
  };

  upload = async (
    organisationId: number,
    file: File,
    fileName: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", fileName);
    const url = `${this.url}/${organisationId}/${this.controller}/upload`;
    let res = await this.http.post(url, formData, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return res.data;
  };

  uploadRows = async (
    organisationId: number,
    fileId: number,
    ids: number[],
    showUploadedRows: boolean,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/upload-rows`;
    let res = await this.http.post(
      url,
      {
        organisationId: organisationId,
        fileId: fileId,
        rowIds: ids,
        showUploadedRows: showUploadedRows
      },
      config
    );
    return res.data;
  };

  deleteRows = async (
    organisationId: number,
    fileId: number,
    ids: number[],
    showUploadedRows: boolean,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/delete-rows`;
    let res = await this.http.post(
      url,
      {
        organisationId: organisationId,
        fileId: fileId,
        rowIds: ids,
        showUploadedRows: showUploadedRows
      },
      config
    );
    return res.data;
  };

  async updateUploadField(
    organisationId: number,
    entityId: number,
    textField: string,
    stakeholderField: StakeholderUploadField,
    fileId: number,
    showUploadedRows: boolean,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> {
    let data = {
      StakeholderUploadId: entityId,
      TextField: textField,
      fileId: fileId,
      showUploadedRows: showUploadedRows
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/${this.controller}/update-upload-field/${stakeholderField}`,
      data,
      config
    );
    return res.data;
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    entityId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    stakeholderField: StakeholderField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let data = {
      TextField: textField,
      IdField: idField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/${this.controller}/${entityId}/${stakeholderField}`,
      data,
      config
    );
    return res.data;
  };

  //#region ProjectStakeholder

  getDetailedProjectsById = async (organisationId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${window.appConfig.flightPathCoreApiUrl}/api/v1/organisations/${organisationId}/stakeholders/${id}/assigned-projects`,
      config
    );
    return res.data;
  };
  //#endregion
}

const instance = new StakeholdersApi(http);
export default instance;
