import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import TrainingModulesApi, {
  TrainingModulesApi as ITrainingModulesApi
} from "../../../../../../../../services/api/v2/trainingModules/TrainingModules.api";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";

export class ProcessLinkModalModel {
  @observable selectedProjectProcessIds: number[] = [];
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  trainingModulesProvider: ITrainingModulesApi;
  trainingModule: FP.Entities.ITrainingModule;
  httpProgress: IProgressIndicatorModel;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, trainingModule: FP.Entities.ITrainingModule) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.trainingModulesProvider = TrainingModulesApi;
    this.httpProgress = ProgressIndicatorModel;
    this.trainingModule = trainingModule;
  }

  onMount = async () => {
    await this.getSelectedProjectProcesses();
    this.setIsLoading(false);
  };

  @action
  setSelectedProjectProcesses = (selectedProjectProcessIds: number[]) => {
    this.selectedProjectProcessIds = selectedProjectProcessIds;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  getSelectedProjectProcesses = async () => {
    const res = await this.trainingModulesProvider.getTrainingModuleProcessesByTrainingModuleId(
      this.organisationId,
      this.projectId,
      this.trainingModule.id
    );

    if (!res || res.isError) return;

    this.setSelectedProjectProcesses(res.payload);
  };

  assignActionsToTrainingModules = async (projectProcessIds, onAssignSuccess?: any) => {
    await this.trainingModulesProvider
      .addBulkProjectProcessesToTrainingModules(
        this.organisationId,
        this.projectId,
        [this.trainingModule.id],
        projectProcessIds
      )
      .then(async e => await onAssignSuccess());

    ModalContext.hide();
  };
}
