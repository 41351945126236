import { action, makeObservable, observable } from "mobx";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import {
  IActionInfo,
  IAudienceInfo,
  IImpactInfo,
  IStakeholderInfo
} from "../../../../services/hubs/ProjectDashboardHub/ProjectDashboard_types";
import { Mapper } from "../view/ProjectView_mapper";

export class ProjectViewModel {
  projectsApi: IProjectsApi;
  organisationId: number;
  @observable isLoading: boolean = true;
  actionsProvider: IActionsApi;
  @observable.ref project: FP.Entities.IProject;
  projectId: number;
  projectTeamUserPermissionsApi: IProjectTeamUserPermissionsApi;
  modalService: IModalContextModel;
  @observable.ref projectTeam: FP.Entities.IUserPermission[] = [];
  @observable ragData: any[];
  @observable actionsCompletionData: any[];
  @observable stakeholderData: any[];
  @observable audienceData: any[];
  @observable impactData: any[];
  @observable impactCompletionData: any[];
  @observable impactPercentComplete: number;
  actionPercentComplete: number;
  totalAudiences: number;
  totalStakeholders: number;
  totalActions: number;
  totalImpacts: number;

  constructor(orgId: number, projectId: number) {
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = orgId;
    this.projectsApi = ProjectsApi;
    this.actionsProvider = ActionsApi;
    // this.timelineModel = new TimeLineModel();
    this.projectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
    this.modalService = ModalContext;
  }

  onMount = async () => {
    await this.load();
  };

  load = async () => {
    await Promise.all([this.loadProject(this.projectId), this.loadDashboardData(this.projectId)]);
    this.setIsLoading(false);
  };

  loadDashboardData = async projectId => {
    const res = await this.projectsApi.getDashboardData(this.organisationId, projectId);
    if (res && !res.isError) {
      const { payload } = res;

      this.setImpactData(payload.impactInfo);
      this.setImpactCompletionData(payload.impactInfo);
      this.setActionsCompletionData(payload.actionInfo);
      this.setRagData(payload.actionInfo);
      this.setStakeholderData(payload.stakeholderInfo);
      this.setAudienceData(payload.audienceInfo);

      this.loadImpactPercentageComplete(payload.impactInfo);
      this.loadActionPercentageComplete(payload.actionInfo);
    }
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  loadProject = async (id: number) => {
    const res = await this.projectsApi.getById(this.organisationId, id);
    if (!res || res.isError) return;
    this.setProject(res.payload);
    this.loadProjectTeam();
  };

  loadProjectTeam = async () => {
    const res = await this.projectTeamUserPermissionsApi.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeam(res.payload);
  };

  @action
  loadImpactPercentageComplete = (impacts: IImpactInfo) => {
    const total = impacts?.total | 0;
    const completed = impacts.progress.completed;
    this.impactPercentComplete = completed === 0 && total === 0 ? 0 : Math.round(100 * (completed / total));
  };

  @action
  loadActionPercentageComplete = (actions: IActionInfo) => {
    const total = actions?.total | 0;
    const completed = actions.progress.completed;
    this.actionPercentComplete = completed === 0 && total === 0 ? 0 : Math.round(100 * (completed / total));
  };

  @action
  setProjectTeam = (projectTeam: FP.Entities.IUserPermission[]) => {
    this.projectTeam = projectTeam;
  };

  @action
  setProject = async (project: FP.Entities.IProject) => {
    this.project = project;
  };

  @action
  setImpactData = (impacts: IImpactInfo) => {
    this.totalImpacts = impacts.total;
    this.impactData = Mapper.impactLevels(impacts);
  };

  @action
  setImpactCompletionData = (impacts: IImpactInfo) => {
    this.impactCompletionData = Mapper.impactCompletionData(impacts);
  };

  @action
  setActionsCompletionData = (actions: IActionInfo) => {
    this.actionsCompletionData = Mapper.actionComplletionStatus(actions);
  };

  @action
  setRagData = (actions: IActionInfo) => {
    this.totalActions = actions.total;
    this.ragData = Mapper.ragData(actions);
  };

  @action
  setStakeholderData = (stakeholders: IStakeholderInfo) => {
    this.totalStakeholders = stakeholders.total;
    this.stakeholderData = Mapper.stakeholderData(stakeholders);
  };

  @action
  setAudienceData = (audiences: IAudienceInfo) => {
    this.totalAudiences = audiences.total;
    this.audienceData = Mapper.audienceData(audiences);
  };
}
