import moment from "moment";
import { FilterCellDataTypes } from "../grids/base/addons/FilterAddon/FilterAddonContants";

export const ActiveUsersHandler = {
  removeAllClassNames: () => {
    const items: NodeListOf<Element> = document.querySelectorAll('div[class*="user-active-cell"]');

    items.forEach(el => {
      for (let i = el.classList.length - 1; i >= 0; i--) {
        const className = el.classList[i];
        if (className.startsWith("user-active-cell")) {
          el.classList.remove(className);
        }
      }
    });
  },
  styleSelectedCells: (users: any[]) => {
    users.forEach((e, i) => {
      const el = document.querySelector(`.ag-selectable-${e.cell}`);
      if (!el) return;
      el.classList.add("user-active-cell" + (e.isEditMode ? "--edit" : ""));
      el.classList.add("user-active-cell--" + (i + 1));
    });
  }
};

export const DEFAULT_COLUMN_TYPES = {
  selectableClass: {
    cellClass: e => {
      return `ag-selectable-${e.colDef.field}-${e.data.id}`;
    }
  },
  readOnlyCell: {
    cellClass: e => {
      return `ag-cell--not-editable ag-selectable-${e.colDef.field}-${e.data.id}`;
    }
  },
  deletableCell: {
    suppressKeyboardEvent(params) {
      let valForDeletion = params.data[params.colDef.field];
      if (params.editing || !valForDeletion) return;
      const isDeleteKey = params.event.keyCode === 46;
      const isBackspaceKey = params.event.keyCode === 8;
      if (!params.context) return;
      if (!isDeleteKey && !isBackspaceKey) return;

      const id = params.data.id;
      params.context.deleteFn(id, params.colDef.field);
      return null;
    }
  }
};

export const valueOrNoInput = (value?: string): string => {
  if (!value) return "";
  if (value.length === 0) return "";
  return htmlDecode(value);
};
function htmlDecode(input) {
  var e = document.createElement("textarea");
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export const stringToMomentDateForComparison = (date: string) => {
  return moment(date)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .utcOffset(0, true)
    .format("YYYY-MM-DD HH:mm:ss");
};

export const ExtFilterParamBuild = (column: string, type: FilterCellDataTypes, operator, value?: string) => {
  return `${column}.${type}.${operator}${value || ""}`;
};
