import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { HubEvents } from "./HubEventsEnum";
import { GetAuthToken } from "../../../core/auth_insight/SecureApp";

export interface ISignalRBaseModel {
  isConnectionStarted: boolean;
  stopConnection: any;
  startConnection: any;
  onUserJoined: (callback) => void;
  canInvoke: () => boolean;
}

export abstract class SignalRBaseModel implements ISignalRBaseModel {
  url: string = `${window.appConfig.flightPathCoreApiUrl}/hubs/`;
  hubName: string;
  connection: HubConnection;
  isConnectionStarted: boolean = false;

  constructor(hubName: string) {
    this.url += hubName;
    this.createHub();
  }

  getConnectionGroupName = (organisationId: number, projectId: number, hubName: string) => {
    return `${hubName}-${organisationId}-${projectId}`;
  };

  createHub = (): HubConnection => {
    this.connection = new HubConnectionBuilder()
      .withUrl(this.url, {
        accessTokenFactory: () => {
          return GetAuthToken().then(e => {
            return e;
          });
        }
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build();
    return this.connection;
  };

  loadData = async (connection: HubConnection, event: string, callback: (data) => void) => {
    if (connection) {
      connection
        .start()
        .then(result => {
          if (connection.connectionId) {
            console.info(`SignalR Connected (${event})!`);
          }

          connection.on(event, data => {
            if (callback) {
              callback(data);
            }
          });
        })
        .catch(e => console.error(`SignalR Connection failed ${event}: `, e));
    }
  };

  startConnection = async () => {
    try {
      if (this.connection && !this.isConnectionStarted && this.connection.state === HubConnectionState.Disconnected) {
        await this.connection.start();
        this.isConnectionStarted = true;
        if (this.connection.connectionId) {
          console.info(`%cSignalR Connected \n ConnectionId: ${this.connection.connectionId}!`, "color: green");
        }
      }
    } catch (error) {
      this.isConnectionStarted = false;
      console.error(`SignalR Connection failed: `, error);
    }
  };

  stopConnection = async () => {
    try {
      const prevConnectionId = this.connection.connectionId;
      await this.connection.stop();
      this.isConnectionStarted = false;
      console.info(`%cDisconnected SignalR \n ConnectionId: ${prevConnectionId}!`, "color: yellow");
    } catch (e) {
      console.error(e);
    }
  };

  canInvoke = () => {
    return this.connection.state !== HubConnectionState.Disconnecting;
  };
  onUserJoined = callback => this.registerIncomingEvent(HubEvents.ON_USER_JOIN, callback);

  registerIncomingEvent = (event, callback: (data) => void) => {
    try {
      if (!this.isConnectionStarted) return;
      this.connection.on(event, data => {
        if (!callback) return;

        callback(data);
      });
      return this;
    } catch (error) {
      console.error(`SignalR Connection failed ${event}: `, error);
    }
  };
}
