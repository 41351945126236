import { ErrorBoundary } from "../../components/ui/ErrorBoundary";
import { UiPortalsProvider } from "../../components/ui/UiPortal";
import {
  FeatureToggleProvider,
  configFeatureProvider,
  isFeatureEnabledFactory,
  locationSearchFeatureProvider
} from "../../components/ui/featureToggle";
import { ProgressIndicator } from "../../components/widgets/ProgressIndicator/ProgressIndicator_view";
import { AgGridContextProvider } from "../../contexts/agGrids/AgGridContext";
import { OrganisationSettingsContextProvider } from "../../contexts/organisationSettings/OrganisationSettingsContext";
import PermissionContext, { PermissionContextProvider } from "../../contexts/permissions/PermissionsContext";
import { HTTPProvider, SecureApp } from "../../core/auth_insight/SecureApp";
import { UserProvider } from "../../core/auth_insight/UserContext";
import { HasOrganisationAccess } from "../../core/router/Route";
import { FlightPathThemeProvider } from "../../core/theme/themeProvider";
import { ToasterServiceView } from "../../core/toaster/ToasterService";
import { InsightRoutes } from "../../routes";

const isFeatureEnabled = isFeatureEnabledFactory(
  configFeatureProvider(window.appConfig),
  locationSearchFeatureProvider
);
export const InsightProviders: React.FC<ReactProps> = () => {
  return (
    <FlightPathThemeProvider>
      <ToasterServiceView />
      <ProgressIndicator />
      <FeatureToggleProvider isFeatureEnabled={isFeatureEnabled}>
        <UiPortalsProvider>
          <ErrorBoundary>
            <SecureApp>
              <HTTPProvider>
                <UserProvider>
                  <PermissionContextProvider permissionContext={PermissionContext}>
                    <OrganisationSettingsContextProvider>
                      <HasOrganisationAccess>
                        <AgGridContextProvider>
                          <InsightRoutes />
                        </AgGridContextProvider>
                      </HasOrganisationAccess>
                    </OrganisationSettingsContextProvider>
                  </PermissionContextProvider>
                </UserProvider>
              </HTTPProvider>
            </SecureApp>
          </ErrorBoundary>
        </UiPortalsProvider>
      </FeatureToggleProvider>
    </FlightPathThemeProvider>
  );
};
