import { action, computed, makeObservable, observable } from "mobx";
import I18n from "../../../../../core/localization/I18n";
import ImpactsApi from "../../../../../services/api/v2/impacts/Impacts.api";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import _ from "lodash";

export class ImpactAssessmentModel {
  @observable filterJSON: any = {};
  projectId: number;
  organisationId: number;
  impactsProvider = ImpactsApi;
  @observable impacts: FP.Entities.IImpactSummary[];
  @observable dataConfidence: number = 0;
  @observable ragData: any[] = [];
  @observable impactCount: number = 0;
  @observable isConfidenceReady: boolean = false;
  @observable isLoading:boolean = true;
  confidenceList: any = [];
  localStorage = LocalStorageService;
  @observable projectImpactTags: FP.Entities.ITag[];
  chartRefs: React.RefObject<any>[];

  @computed get hasFilters(): boolean {
    var res = false;
    _.forEach(this.filterJSON, (e: any) => {
      res = res || e.length > 0;
    });

    return res;
  }

  constructor(organisationId: number, projectId: number, chartRefs: React.RefObject<any>[]) {
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.chartRefs = chartRefs;
    let filters = this.localStorage.get(this.getStorageKey(organisationId, projectId));
    if (filters) {
      this.setFilterJSON(JSON.parse(filters));
    }
  }

  onMount = async () => {
    this.loadImpacts();
    await this.loadProjectImpactTags();
  };

  @action
  loadImpacts = async () => {
    const impacts = await this.impactsProvider.getGridDataByImpactIds(
      this.organisationId,
      this.projectId,
      this.filterJSON
    );

    if (!!impacts.payload && !impacts.isError) {
      this.setImpacts(impacts.payload.data);
      this.loadImpactLevelStatus();
      this.isLoading = false;
    }
  };

  @action
  loadProjectImpactTags = async () => {
    const res = await this.impactsProvider.getTagsByProjectId(this.organisationId, this.projectId);

    if (!!res.payload && !res.isError) {
      this.setProjectImpactTags(res.payload);
    }
  };

  setConfidenceList = (key: string, confidence: number) => {
    this.confidenceList.push(confidence);
    this.setDataConfidence();
  };

  @action
  loadImpactLevelStatus = () => {
    this.ragData = [
      {
        label: I18n.t("visualisations.level_high"),
        value: this.impacts.filter(e => e.impactLevel > 7).length,
        id: "high"
      },
      {
        label: I18n.t("visualisations.level_medium"),
        value: this.impacts.filter(e => e.impactLevel > 4 && e.impactLevel < 8).length,
        id: "medium"
      },
      {
        label: I18n.t("visualisations.level_low"),
        value: this.impacts.filter(e => e.impactLevel >= 1 && e.impactLevel < 5).length,
        id: "low"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: this.impacts.filter(e => e.impactLevel === -1 || e.impactLevel === 0).length,
        id: "unknown"
      }
    ];
  };

  @action
  setDataConfidence = () => {
    let allDataConfidence: number[] = this.confidenceList;
    this.dataConfidence = Math.round(
      allDataConfidence.reduce((partialSum, a) => partialSum + a, 0) / allDataConfidence.length
    );
  };

  @action
  setProjectImpactTags = (projectImpactTags: FP.Entities.ITag[]) => {
    this.projectImpactTags = projectImpactTags;
  };

  @action
  setFilterJSON = (filterJSON: any) => {
    this.filterJSON = filterJSON;
    this.localStorage.set(this.getStorageKey(this.organisationId, this.projectId), JSON.stringify(filterJSON));
  };

  @action
  setFilterJSONFromList = (filterJSON: any[]) => {
    let s = {};

    filterJSON.forEach(e => {
      s[e.key] = e.ids;
    });
    this.setFilterJSON(s);
  };

  @action
  onFilterChange = async k => {
    this.setFilterJSONFromList(k);
    this.confidenceList = [];

    return await Promise.all([
      this.chartRefs.forEach(e => e?.current?.loadData()),
      this.loadImpacts(),
      this.loadImpactLevelStatus()
    ]).then(async e => {
      return null;
    });
  };

  @action
  setImpacts = (impacts: FP.Entities.IImpactSummary[]) => {
    this.impacts = impacts;
    this.impactCount = impacts.length;
  };

  resetLocalStorage = () => {
    this.localStorage.remove(this.getStorageKey(this.organisationId, this.projectId));
  };

  getStorageKey = (organisationId, projectId) => {
    return `impact-assessment-${organisationId}-${projectId}`;
  };
}
