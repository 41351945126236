import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { ImpactMilestonesSidebarModel } from "./ImpactMilestonesSidebar_model";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
interface ImpactMilestonesSidebarProps {
  impactId: number;
}

export const ImpactMilestonesSidebar: React.FC<ImpactMilestonesSidebarProps> = observer(({ impactId }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  let [model] = useState(
    () => new ImpactMilestonesSidebarModel(ModalContext.hide, organisationId, +projectId, impactId)
  );

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="container-fluid pt-3">
      <SingleForm model={model.formModel} />
    </div>
  );
});
