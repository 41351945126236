import { action, makeObservable, observable } from "mobx";
import BenefitApi, { BenefitApi as IBenefitApi } from "../../../../../../../services/api/v2/benefits/Benefits.api";
import { DisposableModel } from "../../../../../../util/DisposableModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../../../services/api/v2/projects/Projects.api";
import ToasterService, { IToasterService } from "../../../../../../toaster/ToasterService";
import { RevisionHistoryModel } from "../../../../../../../pages/change/revisionHistory/RevisionHistory_model";
import { IQueryStringService } from "../../../../../../../services/local/queryStringService/IQueryStringService";
import ModalContext from "../../../../../../modalZ/context/ModalContext";
import { CommentListViewModel } from "../../../../../../../pages/change/comments/commentListView/CommentListView_model";
import I18n from "../../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../../modalZ/context/IModalContext";
import QueryStringService from "../../../../../../../services/local/queryStringService/QueryStringService";
import progressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../../../../services/api/v2/comments/Comments.api";

interface IBenefitExtendedViewModelOptions {
  projectId: number;
  benefitId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}

export class BenefitExtendedViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  benefitProvider: IBenefitApi;
  projectId: number;
  commentsProvider: ICommentsApi;
  toasterService: IToasterService;
  benefitId: number;
  confirmationService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable isLoading: boolean = true;
  @observable.ref benefit: FP.Entities.IBenefit;
  organisationId: number;
  queryStringService: IQueryStringService;

  constructor({ projectId, benefitId, authUser, organisationId }: IBenefitExtendedViewModelOptions) {
    super();
    makeObservable(this);
    this.toasterService = ToasterService;
    this.projectProvider = ProjectsApi;
    this.benefitProvider = BenefitApi;
    this.httpProgress = progressIndicatorModel;
    this.commentsProvider = CommentsApi;
    this.confirmationService = ModalContext;
    this.projectId = projectId;
    this.benefitId = benefitId;
    this.organisationId = organisationId;
    this.commentViewModel = new CommentListViewModel(projectId, authUser, {
      placeholderText: I18n.t("placeholders.benefitNotePlaceholder"),
      searchAttribute: "benefitId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel({
      entityId: this.benefitId,
      projectId: this.projectId,
      historyType: "benefits",
      organisationId
    });

    this.queryStringService = QueryStringService;
  }

  @action.bound
  async load() {
    await this.loadBenefit();
    this.commentViewModel.setConfig({
      id: this.benefitId,
      description: <h4 className="mb-0">{I18n.t("phrases.addANote")}</h4>
    });
  }

  @action
  loadBenefit = async () => {
    this.isLoading = true;
    const res = await this.benefitProvider.getDetailedById(this.organisationId, this.projectId, this.benefitId);
    if (!res || res.isError) {
      console.error("Failed to fetch benefit data", res);
      this.isLoading = false;
      return;
    }
    this.benefit = res.payload;
    this.isLoading = false;
  };
}
