export const UrlOperators = {
  equals: { symbol: "=", gridOperator: "equals" },
  contains: { symbol: "@", gridOperator: "contains" },
  notContains: { symbol: "^", gridOperator: "notContains" },
  notEqual: { symbol: "!", gridOperator: "notEqual" },
  beginsWith: { symbol: "]", gridOperator: "startsWith" },
  endsWith: { symbol: "[", gridOperator: "endsWith" },
  blank: { symbol: "-", gridOperator: "blank" },
  notBlank: { symbol: "~", gridOperator: "notBlank" },
  between: { symbol: "<>", gridOperator: "inRange" },
  lessThan: { symbol: "<", gridOperator: "lessThan" },
  moreThan: { symbol: ">", gridOperator: "greaterThan" }
};

export const JoinOperators = {
  and: { symbol: "AND", gridOperator: "AND" },
  or: { symbol: "OR", gridOperator: "OR" }
};

export const ConditionPartsJoinOperators = {
  between: { symbol: "_" },
  or: { symbol: "|" },
  and: { symbol: "+" }
};

export enum FilterCellDataTypes {
  text = "text",
  date = "date"
}

export const externalFilterParamKey = {
  extFilter: "extFilter"
};
