import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import ProjectsApi, {
  ProjectsApi as IProjectsApi
} from "../../../../../../../../services/api/v2/projects/Projects.api";
import { getImpactMilestonesFormFields } from "./ImpactMilestonesSidebar_fields";
import I18n from "../../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";

export class ImpactMilestonesSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  projectsProvider: IProjectsApi;
  @observable isLoading: boolean = true;
  @observable milestones: FP.Entities.IMilestone[];
  impactId: number;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.isLoading = true;
    this.projectsProvider = ProjectsApi;
    this.impactId = impactId;
    this.closeModalFn = closeModalFn;
    this.setupFormModel();
  }

  onMount = async () => {
    await this.loadImpact(this.impactId);
  };

  setupFormModel = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactMilestonesFormFields(
      this.impactsProvider,
      this.projectsProvider,
      this.organisationId,
      this.projectId,
      this.impactId,
      this.milestones
    );
    this.formModel.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => ModalContext.hide(),
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
  };

  @action
  setMilestones = (milestones: FP.Entities.IMilestone[]) => {
    this.milestones = milestones;
    this.setupFormModel();
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getMilestones(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setMilestones(res.payload);
  };
}
