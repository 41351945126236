import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionLinkModalModel } from "./ActionLinkModal_model";
import { ActionsGridModal } from "../../../../../../../modalZ/common/actionModals/ActionsGridModal/ActionsGridModal_view";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

interface ActionLinkModalProps {
  projectId: number;
  trainingModuleId: number;
  trainingModule?: FP.Entities.ITrainingModule;
  onAssignSuccess?: any;
}

export const ActionLinkModal: React.FC<ActionLinkModalProps> = observer(props => {
  const { projectId, trainingModule, onAssignSuccess } = props;
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ActionLinkModalModel(organisationId, projectId, trainingModule));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner></PositionedSpinner>;
  return (
    <ActionsGridModal
      onAssignSuccess={items => {
        ModalContext.hide();
        model.assignActionsToTrainingModules(items, onAssignSuccess);
      }}
      selectedItemIds={model.selectedActionIds}
    />
  );
});
