import React, { useEffect, useRef, useState } from "react";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { observer } from "mobx-react";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGrid } from "../base/AppGrid_view";
import { TrainingProgressGridModel } from "./TrainingProgressGridView_model";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { EntityTypes, GridTypes } from "../../../../../../enums";

interface TrainingProgressGridProps {
  data: FP.Entities.ITrainingProgress[];
  isLoading: boolean;
  gridToolbarType?: AppGridToolbarType;
  selectedLinkItems?: number[];
  refreshDataHandler?: any;
  onFieldUpdate?: () => void;
  preSelectedItemIds?: number[];
  connectedUsers?: FP.Entities.IUser[];
  gridType?: EntityTypes | GridTypes;
  onCellStateChange?: (
    organisationId: number,
    projectId: number
  ) => (cell: string, isEditMode?: boolean) => Promise<void>;
}

export const TrainingProgressGridView: React.FunctionComponent<TrainingProgressGridProps> = observer(
  ({
    data,
    gridToolbarType = "base",
    refreshDataHandler,
    onFieldUpdate,
    isLoading,
    preSelectedItemIds,
    connectedUsers,
    gridType
  }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const gridRef = useRef<AppGrid>();

    const [gridModel, setGridModel] = useState(
      () =>
        new TrainingProgressGridModel(
          organisationId,
          authUser,
          gridToolbarType,
          refreshDataHandler,
          onFieldUpdate,
          preSelectedItemIds,
          gridType
        )
    );

    useEffect(() => {
      if (gridModel.organisationId !== organisationId) {
        setGridModel(
          new TrainingProgressGridModel(
            organisationId,
            authUser,
            gridToolbarType,
            refreshDataHandler,
            onFieldUpdate,
            preSelectedItemIds,
            gridType
          )
        );
      }
      // eslint-disable-next-line
    }, [organisationId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!isLoading}>
          <IF condition={gridModel.canEditCoreData && (gridToolbarType === "all" || gridToolbarType === "base")}>
            <AppGridToolbar
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems?.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            onFirstData={gridModel.setSelectedNodes}
            shouldTrackSelectedCell={true}
            rowSelection="multiple"
          />
        </IF>
      </>
    );
  }
);
