import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  ScrollableModalContent,
  ScrollableModalFooter,
  ScrollableModalTitle
} from "../../../../components/ui/ScrollableModal";
import I18n from "../../../../core/localization/I18n";
import { EulaModel } from "./Eula_model";
import { useAuth0 } from "@auth0/auth0-react";
import AuthApi from "../../../../services/api/v2/auth/Auth.api";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";

export const Eula: React.FC = observer(() => {
  const [model] = useState(() => new EulaModel());
  const { logout } = useAuth0();

  const signOut = async () => {
    await AuthApi.logout();
    logout();
  };

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <>
      <ScrollableModalTitle
        title={I18n.t("phrases.eulaModalTitle")}
        version={model.eula["version"]}
        lastUpdated={model.eula["createdAt"]}
      />
      <ScrollableModalContent onScrollBottom={model.onScrollBottom} content={model.eula["content"]} />
      <ScrollableModalFooter
        onClickProceed={model.onClickProceed}
        isDisabled={model.isDisabled}
        logout={signOut}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          model.setIsChecked(e.currentTarget.checked);
        }}
        isChecked={model.isChecked}
      />
    </>
  );
});
