import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ActionSidebar } from "./ActionSidebar_view";

export const PROJECT_STAKEHOLDER_OWNED_ACTIONS_ACTIONS_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  projectStakeholder: any
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("entities.actionOwned")} />,
    content: <ActionSidebar projectId={projectId} projectStakeholderId={projectStakeholder.id} />
  };
};
