import { useParams } from "react-router-dom";
import { MilestonesGridView } from "../../../../../core/grids/builder/directorBuilder/grids/MilestonesGrid/MilestonesGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { MilestoneSettingsModel } from "./MilestoneSettings_model";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import MilestonesApi from "../../../../../services/api/v2/milestones/Milestones.api";

export const MilestoneSettings: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();

  const [model] = useState(() => new MilestoneSettingsModel(organisationId, +projectId));

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="milestone-settings insight-tab-content__space-filler">
      <MilestonesGridView
        data={model.milestones}
        parentEntity="project"
        onMilestoneCreated={model.loadMilestones}
        createMilestone={MilestonesApi.createProjectMilestone}
        parentId={+projectId}
      />
    </div>
  );
});
