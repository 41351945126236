import { observer } from "mobx-react";
import React, { useState } from "react";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { SingleForm } from "../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { TagsSidebarModel } from "./TagsSidebar_model";

export interface TagsSidebarProps {
  onSubmit: (tagIds: number[]) => void;
  currentIds: number[];
  data: FP.Entities.ITag[];
}

export const TagsSidebar: React.FC<TagsSidebarProps> = observer(({ onSubmit, currentIds, data }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new TagsSidebarModel(organisationId, +projectId, onSubmit, currentIds, data));

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
