import { action, makeObservable, observable } from "mobx";
import TrainingProgressApi from "../../../../../services/api/v2/trainingProgress/TrainingProgress.api";

export class TrainingProgressModel {
  organisationId: number;
  @observable trainingProgressData: any[] = [];
  @observable isLoading: boolean = true;
  trainingProgressProvider = TrainingProgressApi;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
  }

  onMount = async () => {
    await this.loadTrainingProgressData();
    this.setIsLoading(false);
  };

  @action
  setTrainingProgressData = (trainingProgressData: any[]) => {
    this.trainingProgressData = trainingProgressData;
  };

  loadTrainingProgressData = async () => {
    let response = await this.trainingProgressProvider.getGridData(this.organisationId);
    if (response.isError) {
      return;
    }

    const mappedResponse = response.payload.map(item => ({
      ...item,
      createdBy: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
      modifiedBy: `${item.modifiedBy.firstName} ${item.modifiedBy.lastName}`
    }));

    this.setTrainingProgressData(mappedResponse);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
