import { action, makeObservable, observable } from "mobx";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../services/api/v2/tags/Tags.api";
import { BaseModel } from "../../../../../../util/BaseModel";

export class TagsSidebarModel extends BaseModel {
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref tags: FP.Entities.ITag[];
  organisationId: number;
  projectId: number;
  mainEntityId: number;
  onAssignSuccess?: any;
  loadItem: (id: number) => Promise<any>;

  constructor(
    organisationId: number,
    projectId: number,
    mainEntityId: number,
    onAssignSuccess,
    loadItem: (id: number) => Promise<any>
  ) {
    super();
    makeObservable(this);
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mainEntityId = mainEntityId;
    this.onAssignSuccess = onAssignSuccess;
    this.loadItem = loadItem;
  }

  onMount = async () => {
    let res = await this.loadItem(this.mainEntityId);
    this.setTags(res?.tags ?? []);
  };

  @action
  setTags = (tags: FP.Entities.ITag[]) => {
    this.tags = tags;
    this.isLoading = false;
  };
}
