import { action, makeObservable, observable } from "mobx";
import CsvHelper, { PARSE_CSV_FILE } from "../../../../../../services/local/csvHelper/CsvHelper";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import PulseAudienceStakeholdersApi, {
  PulseAudienceStakeholdersApi as IPulseAudienceStakeholdersApi
} from "../../../../../../services/api/v2/pulseAudienceStakeholders/PulseAudienceStakeholders.api";
import ToasterService, { ToasterService as IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import I18n from "../../../../../../core/localization/I18n";

const UPLOAD_KEYS = ["email", "awareness", "understand", "commit", "acquire", "apply"];

export enum ReadinessUploaderViews {
  Initial,
  IsUploading,
  IsNotOneFileError,
  IsUploadSuccessfull,
  IsNotValidFileError,
  InvalidDataError,
  InvalidColumnError
}

export class ReadinessUploaderModel {
  organisationId: number;
  projectId: number;
  pulseId: number;
  @observable viewState: ReadinessUploaderViews = ReadinessUploaderViews.Initial;
  @observable isUploading: boolean = false;
  @observable isOneFile: boolean = true;
  pulseAudienceStakeholdersProvider: IPulseAudienceStakeholdersApi;
  modalService: IModalContextModel;
  onAssignSuccess: any;
  toastService: IToasterService;

  constructor(organisationId: number, projectId: number, pulseId: number, onAssignSuccess: any) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.pulseId = pulseId;
    this.pulseAudienceStakeholdersProvider = PulseAudienceStakeholdersApi;
    this.modalService = ModalContext;
    this.projectId = projectId;
    this.onAssignSuccess = onAssignSuccess;
    this.toastService = ToasterService;
  }

  @action
  processFiles = (files: FileList, event: React.DragEvent<HTMLDivElement>) => {
    if (files.length > 1) {
      this.viewState = ReadinessUploaderViews.IsNotOneFileError;
      this.isOneFile = false;
      return;
    }

    if (files.length === 1 && files[0].type !== "text/csv") {
      this.viewState = ReadinessUploaderViews.IsNotValidFileError;
      return;
    }
    this.viewState = ReadinessUploaderViews.IsUploading;

    PARSE_CSV_FILE<FP.Entities.IPulseAudienceStakeholder>(files[0], this.uploadReadiness);
  };

  uploadReadiness = async (pulseAudienceStakeholders: FP.Entities.IPulseAudienceStakeholder[]) => {
    if (!this.hasMatchingKeys(pulseAudienceStakeholders)) {
      this.viewState = ReadinessUploaderViews.InvalidColumnError;
      return;
    }

    const hasInvalidEntries = pulseAudienceStakeholders.some((obj, i) => {
      return Object.entries(obj).some(([key, val], ii) => {
        if (key === UPLOAD_KEYS[0]) {
          return typeof val !== "string" || !val.includes("@");
        } else {
          return val !== null && val !== "" && (val < 1 || val > 5);
        }
      });
    });

    if (hasInvalidEntries) {
      this.viewState = ReadinessUploaderViews.InvalidDataError;
      return;
    }
    const res = await this.pulseAudienceStakeholdersProvider.updateMultiple(
      this.organisationId,
      this.projectId,
      this.pulseId,
      pulseAudienceStakeholders
    );
    await this.onAssignSuccess();
    if (!!res.payload) {
      this.toastService
        .showSuccessToast(TOASTER_TOAST_TIME.FAST)
        .setContent(<p>{`${I18n.t("phrases.stakeholderReadinessUploadedSuccessfully")}`}</p>);
    } else {
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.FAST)
        .setContent(<p>{`${I18n.t("phrases.stakeholderReadinessUploadFailed")}`}</p>);
    }
    this.modalService.hide();
  };

  @action
  setViewState = (viewState: ReadinessUploaderViews) => (this.viewState = viewState);

  downloadTemplate = () => {
    CsvHelper.exportToCsv("pulse-stakeholder-readiness-upload-template", [
      UPLOAD_KEYS,
      ["example@domain.com", "4", "3", "4", "2", "1"]
    ]);
  };

  hasMatchingKeys = obj => {
    if (Object.keys(obj[0]).length !== UPLOAD_KEYS.length) {
      return false;
    }
    return UPLOAD_KEYS.every(key => {
      return Object.keys(obj[0]).includes(key);
    });
  };
}
