import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginComplete = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/"); // Redirect to dashboard or home
      } else {
        navigate("/login"); // Handle unexpected cases
      }
    }
  }, [isAuthenticated, isLoading, navigate, user]);

  return <div>Logging you in...</div>;
};

export default LoginComplete;
