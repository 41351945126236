import { useClaimValue } from "../../../core/auth/authorise";
import { Eula } from "../../../pages/insight/dashboard/eula/Eula_view";

export const SignEulaService: React.FC<ReactProps> = ({ children }) => {
  const isEulaSigned = useClaimValue("isEulaSigned");

  if (isEulaSigned === "0") {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <Eula />
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
