import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { BaseProjectApiModel } from "../BaseProjectApiModel";
import { IFlightPathApiResponse } from "../BaseApiModel";
import {
  IReadinessLineChartData,
  IReadinessSpiderChartData
} from "../../../../pages/insight/projects/AudiencesView/ReadinessChartsView/ReadinessChartsView_model";

export class AudienceReportingApi extends BaseProjectApiModel<FP.Entities.IAudience> {
  controller: string = "audience-reporting";

  constructor(http: AxiosInstance) {
    super(http, "audience-reporting");
  }

  getAudiencesLineChartDataByProjectIdAudienceIds = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IReadinessLineChartData>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/line-chart-data`;
    let res = await this.http.post(url, data || {}, config);
    return res.data;
  };

  getAudiencesSpiderChartDataByProjectIdAudienceIds = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IReadinessSpiderChartData>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/spider-chart-data`;
    let res = await this.http.post(url, data || {}, config);
    return res.data;
  };
}

const instance = new AudienceReportingApi(http);
export default instance;
