import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { getImpactGroupFormFields } from "./impactGroup_formFields";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";

export class ImpactGroupsSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref impact: FP.Entities.IImpact;
  @observable searchValue: string = "";
  organisationId: number;
  projectId: number;
  impactGroup: FP.Entities.IImpactGroup[];
  impactGroupsProvider: IImpactGroupsApi;
  closeModalFn: () => void;
  formModel: SingleFormModel;
  impactId: number;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);

    this.impactGroupsProvider = ImpactGroupsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.isLoading = true;
    this.impactId = impactId;
    this.loadImpact(impactId);
    this.closeModalFn = closeModalFn;
    this.initForm();
  }

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.hideSearchMode();
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
  };

  @action
  setImpactGroup = (impactGroup: FP.Entities.IImpactGroup[]) => {
    this.impactGroup = impactGroup;
  };

  @action
  loadImpact = async (impactId: number) => {
    this.isLoading = true;
    let res = await this.impactsProvider.getImpactGroups(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpactGroup(res.payload);
    this.setFormModel();
    this.isLoading = false;
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getImpactGroupFormFields(
      this.impactGroupsProvider,
      this.organisationId,
      this.projectId,
      this.impactId,
      this.impactGroup
    );
  };
}
