import { useEffect, useMemo, useState } from "react";
import { AudiencesPeopleModel } from "./AudiencesPeople_model";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../../localization/I18n";
import { Button } from "../../../../../../../../components/ui/Button";
import { IF } from "../../../../../../../../components/hoc/If";
import "./_audiences-people.scss";

export const AudiencesPeople: React.FC<{ audienceIds: number[] }> = observer(({ audienceIds }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new AudiencesPeopleModel(organisationId, +projectId, audienceIds));
  const emailList = useMemo(() => model.people.map(e => e.email).join(","), [model.people]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  return (
    <div className="audiences-people container-fluid py-5">
      <IF condition={model.isLoading}>
        <PositionedSpinner />
      </IF>
      <IF condition={!model.isLoading}>
        <div className="row">
          <div className="col-10">
            <h1 className="mb-1">Email Addresses</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <div className="audiences-people-list border p-2 " >
              <ul className="mb-0 " >
                {model.people?.length > 0
                  ? model.people.map((person, i) => (
                    <li className="stakeholder-email__list" key={i}>
                      <p className="mb-0">{person.email}</p>
                    </li>
                  ))
                  : I18n.t("phrases.actionStakeholders_noEmailAddresses")}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-2">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(emailList);
              }}
              className="mr-2"
            >
              {I18n.t("phrases.copyToClipboard")}
            </Button>
          </div>
        </div>
      </IF>
    </div>
  );
});