import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionsGrid } from "../../../../grids/builder/directorBuilder/grids/ActionsGrid/ActionsGridView_view";
import { ActionsGridModalModel } from "./ActionsGridModal_model";

export const ActionsGridModal: React.FC<{
  onAssignSuccess: (itemIds: number[]) => any;
  selectedItemIds: number[];
}> = observer(({ selectedItemIds, onAssignSuccess }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ActionsGridModalModel(organisationId, +projectId, selectedItemIds));

  useEffect(() => {
    model.onMount();

    //eslint-disable-next-line
  }, []);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <ActionsGrid
        data={model.data}
        gridToolbarType="link-modal"
        isLoading={model.isLoading}
        preSelectedItemIds={selectedItemIds}
        onAssignSuccess={onAssignSuccess}
      />
    </div>
  );
});
