import I18n from "../../../../core/localization/I18n";

export const Mapper = {
  readinessSpiderChartData: readiness => {
    return [
      {
        label: I18n.t("phrases.awareness"),
        latestValues: readiness?.latestValues?.awareness ?? 0,
        targetValues: readiness?.targetValues?.awareness ?? 0
      },
      {
        label: I18n.t("phrases.understanding"),
        latestValues: readiness?.latestValues?.understanding ?? 0,
        targetValues: readiness?.targetValues?.understanding ?? 0
      },
      {
        label: I18n.t("phrases.commitment"),
        latestValues: readiness?.latestValues?.commitment ?? 0,
        targetValues: readiness?.targetValues?.commitment ?? 0
      },
      {
        label: I18n.t("phrases.acquire"),
        latestValues: readiness?.latestValues?.capability ?? 0,
        targetValues: readiness?.targetValues?.capability ?? 0
      },
      {
        label: I18n.t("phrases.apply"),
        latestValues: readiness?.latestValues?.adoption ?? 0,
        targetValues: readiness?.targetValues?.adoption ?? 0
      }
    ];
  },
  audienceData: audiences => {
    return [
      {
        label: I18n.t("visualisations.keepSatisfied"),
        value: audiences.impactInfluence.keepSatisfied,
        id: "keepSatisfied"
      },
      {
        label: I18n.t("visualisations.manageClosely"),
        value: audiences.impactInfluence.manageClosely,
        id: "manageClosely"
      },
      {
        label: I18n.t("visualisations.monitor"),
        value: audiences.impactInfluence.monitor,
        id: "monitor"
      },
      {
        label: I18n.t("visualisations.keepInformed"),
        value: audiences.impactInfluence.keepInformed,
        id: "keepInformed"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: audiences.impactInfluence.unknown,
        id: "unknown"
      }
    ];
  },
  stakeholderData: stakeholders => {
    return [
      {
        label: I18n.t("visualisations.keepSatisfied"),
        value: stakeholders.impactInfluence.keepSatisfied,
        id: "keepSatisfied"
      },
      {
        label: I18n.t("visualisations.manageClosely"),
        value: stakeholders.impactInfluence.manageClosely,
        id: "manageClosely"
      },
      {
        label: I18n.t("visualisations.monitor"),
        value: stakeholders.impactInfluence.monitor,
        id: "monitor"
      },
      {
        label: I18n.t("visualisations.keepInformed"),
        value: stakeholders.impactInfluence.keepInformed,
        id: "keepInformed"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: stakeholders.impactInfluence.unknown,
        id: "unknown"
      }
    ];
  },
  ragData: actions => {
    return [
      {
        label: I18n.t("visualisations.Red"),
        value: actions.rag.red,
        id: "red"
      },
      {
        label: I18n.t("visualisations.Orange"),
        value: actions.rag.amber,
        id: "orange"
      },
      {
        label: I18n.t("visualisations.Green"),
        value: actions.rag.green,
        id: "green"
      },
      {
        label: I18n.t("visualisations.Completed"),
        value: actions.progress.completed,
        id: "completed"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: actions.rag.unknown,
        id: "unknown"
      }
    ];
  },
  actionComplletionStatus: actions => {
    return [
      {
        label: I18n.t("phrases.notStarted"),
        value: actions.progress.notStarted,
        id: "notStarted"
      },
      {
        label: I18n.t("phrases.inProgress"),
        value: actions.progress.inProgress,
        id: "inProgress"
      },
      {
        label: I18n.t("phrases.completed"),
        value: actions.progress.completed,
        id: "completed"
      }
    ];
  },
  impactCompletionData: impacts => {
    return [
      {
        label: I18n.t("phrases.notStarted"),
        value: impacts.progress.notStarted,
        id: "notStarted"
      },
      {
        label: I18n.t("phrases.inProgress"),
        value: impacts.progress.inProgress,
        id: "inProgress"
      },
      {
        label: I18n.t("phrases.completed"),
        value: impacts.progress.completed,
        id: "completed"
      }
    ];
  },
  impactLevels: impacts => {
    return [
      {
        label: I18n.t("phrases.high"),
        value: impacts.level.high,
        id: "high"
      },
      {
        label: I18n.t("phrases.medium"),
        value: impacts.level.medium,
        id: "medium"
      },
      {
        label: I18n.t("phrases.low"),
        value: impacts.level.low,
        id: "low"
      },
      {
        label: I18n.t("phrases.unknown"),
        value: impacts.level.unknown,
        id: "unknown"
      }
    ];
  }
};
