import React, { useState } from "react";
import { Button } from "../../../../../../../components/ui/Button";
import { SingleFormModel } from "../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import TagsApi from "../../../../../../../services/api/v2/tags/Tags.api";
import I18n from "../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../modalZ/context/ModalContext";
import { getTagsFormFields } from "./TagsSidebar_formFields";

interface ManageTagProps {
  organisationId: number;
  projectId: number;
  items: any[];
  addTag: any;
  removeTag: any;
  onAssignSuccess?: any;
}

class ManageTagFormModel {
  formModel: SingleFormModel;

  constructor(
    organisationId: number,
    projectId: number,
    items: any[],
    addTag: any,
    removeTag: any,
    onAssignSuccess?: any
  ) {
    this.formModel = new SingleFormModel();

    const tagsProvider = TagsApi;
    let formFields = getTagsFormFields(
      tagsProvider,
      organisationId,
      projectId,
      items,
      addTag,
      removeTag,
      onAssignSuccess
    );
    this.formModel.formFields = formFields;
  }
}

export const ManageTagForm: React.FC<ManageTagProps> = props => {
  const { items, projectId, organisationId, addTag, removeTag, onAssignSuccess } = props;
  const modalService = useModalContext();

  let [model] = useState(
    () => new ManageTagFormModel(organisationId, projectId, items, addTag, removeTag, onAssignSuccess)
  );

  return (
    <div>
      <SingleForm model={model.formModel} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
