import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { TrainingModulesListViewModel } from "./TrainingModulesListView_model";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";
import { TrainingModulesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/TrainingModulesGrid/TrainingModulesGridView_view";

export const TrainingModulesListView: React.FC = observer(() => {
  let organisationId = useCurrentOrganisationId();
  let { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(() => new TrainingModulesListViewModel(organisationId, +projectId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    setModel(new TrainingModulesListViewModel(organisationId, +projectId));
  }, [organisationId, projectId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className=" insight-tab-content__space-filler" style={{ height: "100%" }}>
      <TrainingModulesGrid
        data={model.trainingModuleData}
        gridToolbarType="base"
        isLoading={model.isLoading}
        onFieldUpdate={model.loadTrainingModuleData}
        refreshDataHandler={model.loadTrainingModuleData}
      />
    </div>
  );
});
