import * as React from "react";
import * as _ from "lodash";
import { Input, InputProps } from "../Input";
import { FormatAsDataTestId, FormatAsDataTestValue } from "../../../../core/util/TestHelpers";
import { IconSymbols } from "../../Icon";
import { Enums, UiPositions } from "../../../../enums";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

export interface AutocompleteProps extends ReactProps {
  /**
   * Class name of the component
   */
  className?: string;
  id?: string;

  value: string;

  /**
   * Placeholder for the input
   */
  placeholder?: string;

  /**
   * Items to be shown in the dropdowns
   */
  items: any[];

  /**
   * Items to be shown in the dropdowns
   */
  defaultItems?: any[];

  /**
   * This is a value of the attribute the data will be filtered by
   */
  searchAttribute: string;

  /**
   * A function called when a change on input is triggered
   */
  onInputChange?: (value: string) => void;

  /**
   * A function which is trigered when enter button is clicked
   */
  onEnterPress?: (value: any) => void;

  /**
   * True if the component should hide dropdown when clicked somewhere else
   */
  hasBlur?: boolean;

  /**
   * True if the component should be disabled
   */
  disabled?: boolean;

  /**
   * OnClick execute the on enter function
   */
  shouldExecuteOnEnter?: boolean;

  /**
   * number of characters to input in order to show the dropdown
   */
  charInputNumber?: number;

  /**
   * if input should clear on execution
   */
  shouldClearOnExecution?: boolean;

  /**
   * Function to be called when an item is selected either
   * with mouse click or keyboad enter
   */
  onItemSelected?: (e: any) => void;

  /**
   * true if the options should always be visible
   */
  shouldAlwaysShowOptions?: boolean;

  componentProps?: InputProps;

  isLoading?: boolean;

  filterFn: (items: any[], searchQuery: string) => any[];

  testId?: string;

  noResultsFoundLabel: string;

  allowFreeText?: boolean;

  searchResultHint?: React.ReactNode;

  hideOptions?: boolean;

  selectedItems?: any[];
}

export interface AutocompleteState {
  currentItemIdx: number;
  items: any[] | Dictionary<any>;
  visible: boolean;
}

export class Autocomplete extends React.Component<AutocompleteProps, AutocompleteState> {
  inputEl: HTMLInputElement | null;
  _isMounted: boolean = false;
  constructor(p: AutocompleteProps) {
    super(p);
    this.inputEl = null;
    this.state = {
      items: [],
      currentItemIdx: 0,
      visible: false
    };
  }

  public static defaultProps: Partial<AutocompleteProps> = {
    hasBlur: true,
    onInputChange: () => {},
    onEnterPress: () => {},
    onItemSelected: () => {},
    shouldExecuteOnEnter: false,
    charInputNumber: null as any,
    shouldClearOnExecution: false,
    shouldAlwaysShowOptions: false,
    value: "",
    isLoading: true,
    filterFn: null as any,
    allowFreeText: false
  };

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.children) {
      console.error("Component: 'Autocomplete' expects child component to be provided");
    }
    this.setState({
      items: this.props.defaultItems || this.props.items,
      visible: this.props.shouldAlwaysShowOptions as any
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: AutocompleteProps, prevState: AutocompleteState) {
    if (
      !_.isEqual(prevProps.items, this.props.items) ||
      !_.isEqual(prevProps.selectedItems, this.props.selectedItems)
    ) {
      let s = this.getDropdownItems();
      this.setState({
        items: s || this.props.items
      });
    }
    if (this.props.defaultItems) {
      if (!_.isEqual(prevProps.items, this.props.items)) {
        this.setState({
          items: this.props.defaultItems || this.props.defaultItems
        });
      }
    }
  }

  getDropdownItems = () => {
    if (!this.inputEl) {
      return;
    }

    if (this.props.defaultItems && this.inputEl.value.length < (this.props.charInputNumber as number)) {
      return this.props.defaultItems;
    }

    if (this.props.filterFn && this.inputEl) {
      return this.props.filterFn(this.props.items, this.inputEl.value);
    }

    return _.filter(this.props.items, (e: any) => {
      let kk = e[this.props.searchAttribute].toLowerCase();
      return this.inputEl && kk.includes(this.inputEl.value.toLowerCase());
    });
  };

  triggerInputUpdate = () => {
    if (this.props.onInputChange) {
      this.props.onInputChange(this.inputEl!.value);
    }

    let s = this.getDropdownItems();
    this.setState({
      currentItemIdx: 0,
      items: s as any,
      visible: !!this.inputEl && this.inputEl.value.length >= (this.props.charInputNumber as number)
    });
  };

  keyPressed = (e: any) => {
    e = e || window.event;
    if (`${e.keyCode}` === "38") {
      this.setToUpItem();
    } else if (`${e.keyCode}` === "40") {
      this.setToDownItem();
    } else if (`${e.keyCode}` === "13") {
      if (this.props.allowFreeText) {
        this.executeOnEnter(this.props.value);
      } else {
        if (this.state.items[this.state.currentItemIdx]) {
          this.executeSelection(this.state.items[this.state.currentItemIdx]);
        }
      }
    }
  };

  setToUpItem = () => {
    let cIdx = this.state.currentItemIdx - 1;
    let len = this.state.items.length;
    cIdx = cIdx < 0 ? len - 1 : cIdx;
    this.setState({
      currentItemIdx: cIdx
    });
  };

  setToDownItem = () => {
    let cIdx = this.state.currentItemIdx + 1;
    let len = this.state.items.length;
    cIdx = cIdx < len ? cIdx : 0;

    this.setState({
      currentItemIdx: cIdx
    });
  };

  hideItems = () => {
    this.setState({
      visible: false
    });
  };

  showItems = () => {
    if (this.inputEl) {
      if (this.inputEl.value.length >= (this.props.charInputNumber as number)) {
        this.setState({
          visible: true
        });
      }
    }
  };

  getSelection = () => {
    return this.state.items[this.state.currentItemIdx as any] as any;
  };

  blurItems = () => {
    if (this._isMounted && this.props.hasBlur) {
      setTimeout(() => {
        this.hideItems();
      }, 400);
    }
  };

  executeSelection = (item: any) => {
    this.blurItems();
    if (this.inputEl) {
      if (this.props.shouldClearOnExecution) {
        this.inputEl.value = "";
        this.setState({
          items: this.props.items
        });
      } else {
        this.inputEl.value = item[this.props.searchAttribute];
      }
      this.props.onItemSelected(item);
    }
  };

  executeOnEnter = (value: string) => {
    if (!this.state.visible) return;
    this.blurItems();
    if (this.inputEl) {
      this.inputEl.value = "";
    }
    this.props.onItemSelected({ id: 0, [this.props.searchAttribute]: value });
  };

  /**
   * Return a cloned element for that will appear on the Autocomplete dropdown
   * @param child Child component passed from the parent element
   * @param item The object from where information is taken
   * @param idx Index of the object in the array
   * @param state Autocomplete state
   * @param component Autocomplete component
   */
  CLONE_ELEMENTS = (
    child: React.ReactElement<any>,
    item: any,
    idx: number,
    currentItemIdx: number,
    component: any,
    id?: string,
    testId?: string
  ) => {
    let res = React.cloneElement(child, {
      ...child.props,
      key: Math.random(),
      dataTestid: `${id}AutocompleteIdx-${idx}`,
      dataTestValue: FormatAsDataTestValue(testId, child.props.label(item)),
      className: currentItemIdx === idx ? child.props.className + " grey lighten-3" : child.props.className,
      label: child.props.label && child.props.label(item),
      imgSrc: child.props.imgSrc && child.props.imgSrc(item),
      mouseEnter: () => {
        if (this.state.currentItemIdx !== idx) {
          component.setState({ currentItemIdx: idx });
        }
      },
      onClick: (e: React.SyntheticEvent) => {
        e.preventDefault();
        component.executeSelection(item);
        if (this.props.hasBlur) {
          this.hideItems();
        }
      }
    });
    return res;
  };

  /**
   * This function will loop through an array of objects to show in the dropdown
   * @param state Autocomplete component state
   * @param props Autocomplete component props
   * @param component Autocomplete component
   */
  GET_ARRAY_ITEMS(items: any[], props: any, component: any): React.ReactNode {
    return (items as any[]).map((e, ii) => {
      return React.Children.map(props.children, (child: React.ReactElement<any>) => {
        return this.CLONE_ELEMENTS(child, e, ii, component.state.currentItemIdx, component, props.id, props.testId);
      })[0];
    });
  }

  render() {
    let props = this.props,
      state = this.state,
      cls = this.props.className || "";
    let len = this.state.items.length;

    return (
      <div
        className={"autocomplete " + cls + `${state.visible ? " autocomplete--visible" : ""}`}
        data-visible={state.visible}
        onBlur={this.blurItems}
      >
        <Input
          {...props.componentProps}
          id={this.props.id}
          className={`autocomplete__input ${props.componentProps?.className || ""}`}
          type="text"
          disabled={this.props.disabled}
          icon={props.componentProps?.icon || IconSymbols.ChevronDown}
          placeholder={props.placeholder || ""}
          onChange={this.triggerInputUpdate}
          ref={(e: any) => {
            this.inputEl = e;
          }}
          onKeyDown={this.keyPressed}
          value={this.props.allowFreeText ? this.inputEl?.value : props.value}
          onFocus={(e: any) => {
            this.showItems();
            props.componentProps && props.componentProps.onFocus && props.componentProps.onFocus(e);
          }}
          data-testid={FormatAsDataTestId(this.props.testId)}
        />

        {!props.hideOptions && props.children && len > 0 && (
          <div className="autocomplete__menu">
            {props.searchResultHint && (
              <div className="">
                {props.searchResultHint}
                <hr />
              </div>
            )}
            {Array.isArray(state.items) && this.GET_ARRAY_ITEMS(state.items, props, this)}
          </div>
        )}
        {!props.hideOptions && props.children && len === 0 && !props.isLoading && (
          <div className="autocomplete__menu p-3">
            <p className="mb-0">{props.noResultsFoundLabel}</p>
          </div>
        )}

        {!props.hideOptions && props.isLoading && (
          <div className="autocomplete__menu p-3" data-visible={state.visible}>
            <PositionedSpinner position={UiPositions.MIDDLE} spinnerProps={{ size: Enums.UiSizes.XS }} />
          </div>
        )}
      </div>
    );
  }
}
