import I18n from "../../../../../../../localization/I18n";
import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../../../../GridSideModals";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactMilestonesSidebar } from "./ImpactMilestonesSidebar_view";

export const IMPACT_MILESTONES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.milestones")} />,
    content: <ImpactMilestonesSidebar impactId={impact.id} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  };
};
