import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import TrainingCoursesApi, {
  TrainingCoursesApi as ITrainingCoursesApi
} from "../../../../../../../../services/api/v2/trainingCourses/TrainingCourses.api";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";

export class ActionLinkModalModel {
  @observable selectedActionIds: number[] = [];
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  trainingCoursesProvider: ITrainingCoursesApi;
  trainingCourse: FP.Entities.ITrainingCourse;
  httpProgress: IProgressIndicatorModel;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, trainingCourse: FP.Entities.ITrainingCourse) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.trainingCoursesProvider = TrainingCoursesApi;
    this.httpProgress = ProgressIndicatorModel;
    this.trainingCourse = trainingCourse;
  }

  onMount = async () => {
    await this.getSelectedActions();
    this.setIsLoading(false);
  };

  @action
  setSelectedActions = (selectedActionIds: number[]) => {
    this.selectedActionIds = selectedActionIds;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  getSelectedActions = async () => {
    const res = await this.trainingCoursesProvider.getTrainingCourseActionsByTrainingCourseId(
      this.organisationId,
      this.projectId,
      this.trainingCourse.id
    );

    if (!res || res.isError) return;

    this.setSelectedActions(res.payload);
  };

  assignActionsToTrainingCourses = async (actionIds, onAssignSuccess?: any) => {
    await this.trainingCoursesProvider
      .addBulkActionsToTrainingCourses(this.organisationId, this.projectId, [this.trainingCourse.id], actionIds)
      .then(async e => await onAssignSuccess());

    ModalContext.hide();
  };
}
