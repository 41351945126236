import { StakeholderColDefFieldNamesEnum } from "../../../../../enums/AgGridColDefFieldNameEnum";
import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const BUSINESS_AREA_COLUMN_CONFIG: (
  businessAreas: FP.Entities.IBusinessArea[]
) => ISdColDef<any, any> = businessAreas => {
  return {
    cellEditorParams: {
      field: StakeholderColDefFieldNamesEnum.BusinessArea,
      getValueLabel: (ee: any) => {
        if (!businessAreas) return "";

        const businessArea = businessAreas.find(e => e.id === ee.id);
        if (!businessArea) return ee;

        return businessArea.name;
      },
      options: !!businessAreas ? businessAreas : [],
      allowFreeText: true,
      isPerson: false,
      valueSelector: "name",
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.IBusinessArea) => {
          const lowerName = `${item.name}`.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const BUSINESS_AREA_FILTER_CONFIG: ISdFilterDef = {};
