import { ICellEditorParams } from "ag-grid-community";
import { forwardRef, useState, useRef, useEffect, useImperativeHandle } from "react";
import "./_duration-editor.scss";

const DurationCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const dayRef = useRef(null);
  const hourRef = useRef(null);
  const minuteRef = useRef(null);

  const initialTotal = props.value != null && !isNaN(props.value) ? Number(props.value) : 0;
  const initialDays = Math.floor(initialTotal / 1440);
  const remainderAfterDays = initialTotal % 1440;
  const initialHours = Math.floor(remainderAfterDays / 60);
  const initialMinutes = remainderAfterDays % 60;

  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);

  useImperativeHandle(
    ref,
    () => ({
      getValue() {
        return days * 1440 + hours * 60 + minutes;
      },
      afterGuiAttached() {
        if (dayRef.current) {
          dayRef.current.focus();
          dayRef.current.setSelectionRange(0, dayRef.current.value.length);
        }
      }
    }),
    [days, hours, minutes]
  );

  useEffect(() => {
    if (dayRef.current) {
      dayRef.current.focus();
      dayRef.current.setSelectionRange(0, dayRef.current.value.length);
    }
  }, []);

  const handleChange = (e, field) => {
    const val = e.target.value;
    if (!/^\d*$/.test(val)) return;
    const num = val === "" ? 0 : parseInt(val, 10);
    if (field === "day") {
      setDays(num);
    } else if (field === "hour") {
      setHours(num);
    } else if (field === "minute") {
      setMinutes(num);
    }
  };

  const handleKeyDown = (e, field) => {
    if (e.key === "ArrowLeft") {
      if (e.target.selectionStart === 0) {
        if (field === "hour" && dayRef.current) {
          dayRef.current.focus();
          dayRef.current.setSelectionRange(dayRef.current.value.length, dayRef.current.value.length);
        } else if (field === "minute" && hourRef.current) {
          hourRef.current.focus();
          hourRef.current.setSelectionRange(hourRef.current.value.length, hourRef.current.value.length);
        }
      }
    } else if (e.key === "ArrowRight") {
      if (e.target.selectionStart === e.target.value.length) {
        if (field === "day" && hourRef.current) {
          hourRef.current.focus();
          hourRef.current.setSelectionRange(0, 0);
        } else if (field === "hour" && minuteRef.current) {
          minuteRef.current.focus();
          minuteRef.current.setSelectionRange(0, 0);
        }
      }
    }
  };

  return (
    <div className="duration-editor-tooltip">
      <label className="duration-editor__label">Enter Duration (D:H:M)</label>
      <div className="duration-editor__input-container">
        <input
          ref={dayRef}
          type="text"
          value={days}
          onChange={(e) => handleChange(e, "day")}
          onKeyDown={(e) => handleKeyDown(e, "day")}
          className="duration-editor__input"
        />
        <span className="duration-editor__colon">:</span>
        <input
          ref={hourRef}
          type="text"
          value={hours}
          onChange={(e) => handleChange(e, "hour")}
          onKeyDown={(e) => handleKeyDown(e, "hour")}
          className="duration-editor__input"
        />
        <span className="duration-editor__colon">:</span>
        <input
          ref={minuteRef}
          type="text"
          value={minutes}
          onChange={(e) => handleChange(e, "minute")}
          onKeyDown={(e) => handleKeyDown(e, "minute")}
          className="duration-editor__input"
        />
      </div>
    </div>
  );
});

export default DurationCellEditor;
