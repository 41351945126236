import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ClaimsContextProvider } from "../../core/auth/authorise";
import { ModalContextProvider } from "../../core/modalZ/context/ModalContext";
import { Navigation } from "../../pages/insight/navigation/Navigation";
import {
  OrganisationContextModel,
  OrganisationContextProvider
} from "../../services/local/organisationContext/OrganisationContextModel";
import { SignEulaService } from "../../services/local/signEulaService/SignEulaService";

export const InsightApp: React.FC = () => {
  const [organisationContext] = useState(() => new OrganisationContextModel());

  return (
    <ClaimsContextProvider>
      <SignEulaService>
        <OrganisationContextProvider model={organisationContext}>
          <ModalContextProvider>
            <Navigation>
              <Outlet />
            </Navigation>
          </ModalContextProvider>
        </OrganisationContextProvider>
      </SignEulaService>
    </ClaimsContextProvider>
  );
};
