import { action, makeObservable, observable } from "mobx";
import ActionsApi from "../../../../../services/api/v2/actions/Actions.api";

export class ActionsGridModalModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable data: FP.Entities.IActionSummary[] = [];
  actionsProvider = ActionsApi;
  @observable selectedItemIds: number[] = [];
  /**
   *
   */
  constructor(organisationId: number, projectId: number, selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItemIds = selectedItemIds;
  }

  onMount = async () => {
    await this.load();
  };

  load = async () => {
    let res = await this.actionsProvider.getActionsSummary(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setData(res.payload);
    this.setIsLoading(false);
  };

  @action
  setData = (data: FP.Entities.IActionSummary[]) => {
    this.data = data.map(e => {
      return {
        ...e,
        actionAudiences: JSON.parse(e.actionAudiences),
        audiences: JSON.parse(e.audiences)
      };
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
