import { action, makeObservable, observable } from "mobx";
import ProjectsApi from "../../../../../services/api/v2/projects/Projects.api";
import { Mapper } from "../../view/ProjectView_mapper";

export class ProjectRadialChartPanelModel {
  @observable isLoading = true;
  projectsApi = ProjectsApi;
  organisationId: number;
  projectId: number;
  readiness: any;
  readinessSpiderChartNoData: boolean;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = async () => {
    await this.loadData();
    this.setIsLoading(false);
  };

  loadData = async () => {
    const readinessRes = await this.projectsApi.getReadinessSpiderChartData(this.organisationId, this.projectId);
    if (readinessRes && !readinessRes.isError) {
      this.setReadiness(readinessRes.payload);
    } else {
      this.setReadiness({ categories: [], values: [] });
      this.readinessSpiderChartNoData =
        Array.isArray(this.readiness) &&
        this.readiness.every(item => item.latestValues === 0 && item.targetValues === 0);
    }
  };

  @action
  setReadiness = (readiness: any) => {
    this.readiness = Mapper.readinessSpiderChartData(readiness);
  };
  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
