import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import {
  CommonColDefFieldNamesEnum,
  TrainingProgressColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import I18n from "../../../../../localization/I18n";
import TrainingProgressApi from "../../../../../../services/api/v2/trainingProgress/TrainingProgress.api";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { TrainingProgressField } from "../../../../../../enums";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";

export interface TrainingProgressGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  columns: string[];
  onFieldUpdate: () => void;
}

export class TrainingProgressGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  gridProps: TrainingProgressGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  onFieldUpdate: () => void;

  constructor(gridProps: TrainingProgressGridColumnBuilderProps) {
    super(TrainingProgressApi.updateField, gridProps.organisationId, null, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [TrainingProgressColDefFieldNamesEnum.TrainingProgressStatus]: () => this.buildTrainingProgressStatusColumn(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e && typeof this.columnDefs[e] === "function") {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildTrainingProgressStatusColumn = () => {
    let model = new SimpleTextColumnBuilder({
      field: TrainingProgressColDefFieldNamesEnum.TrainingProgressStatus,
      headerName: I18n.t("grids.trainingProgressStatus")
    })
      .makeEditable(this.canEdit)
      .makeReadOnly(!this.canEdit)
      .setCellDataType("number")
      .setColumnOptions({
        valueFormatter: (params: ValueFormatterParams<any>) => {
          if (!params.value || params.value === 0) {
            return "";
          }
          return params.value + " %";
        },
        cellEditorParams: {
          min: 0,
          max: 100
        }
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      model
        .setColumnOptions({
          valueSetter: params => {
            if (!!params.newValue && params.newValue >= 0 && params.newValue <= 100) {
              this.updateTrainingProgressStatus(params.data.id, params.newValue);
              return true;
            }
          }
        })
        .makeDeletable();
    }
    return model.generateColumnOptions();
  };

  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(TrainingProgressField.name, entityId, text);
    await this.onFieldUpdate();
  };
  updateTrainingProgressStatus = async (entityId: number, numeric: number) => {
    await this.updateNumericField(TrainingProgressField.trainingProgressStatus, entityId, numeric);
    await this.onFieldUpdate();
  };
}
