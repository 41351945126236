import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { IFilteredResponse } from "../filteredApi/FilteredApiModel";
import { ExportableOrganisationApi } from "../exportableApi/ExportableOrganisationApiModel";
import { TrainingProgressField } from "../../../../enums";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class TrainingProgressApi extends ExportableOrganisationApi<FP.Entities.ITrainingProgress> {
  controller: string = "training-progress";

  constructor(http: AxiosInstance) {
    super(http, "training-progress");
  }

  getGridData = async (organisationId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    return res.data;
  };

  getByName = async (
    organisationId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<any>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/name/${name}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getAll = async (organisationId: number, config?: any) => {
    let res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    return res.data;
  };

  deleteRange = async (organisationId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(`${this.url}/${organisationId}/${this.controller}/delete-range`, ids, config);
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number = null,
    trainingProgressIds: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: TrainingProgressField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/${this.controller}/${trainingProgressIds}/${field}`,
      data,
      config
    );
    return res.data;
  };
}

const instance = new TrainingProgressApi(http);
export default instance;
