import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { IFlightPathApiResponse } from "../../../../../../services/api/v2/BaseApiModel";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGrid } from "../base/AppGrid_view";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { MilestonesGridViewModel } from "./MilestonesGridView_model";

export interface MilestonesGridViewProps {
  data: FP.Entities.IMilestone[];
  onMilestoneCreated: () => Promise<any>;
  parentEntity: "project" | "programme";
  parentId: number;
  createMilestone: (
    organisationId: number,
    projectId: number,
    milestone: FP.Entities.IMilestone
  ) => Promise<IFlightPathApiResponse<FP.Entities.IMilestone>>;
}

export const MilestonesGridView: React.FC<MilestonesGridViewProps> = observer(
  ({ data, createMilestone, onMilestoneCreated, parentId, parentEntity }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const gridRef = useRef<AppGrid>();

    const [gridModel, setGridModel] = useState(
      () =>
        new MilestonesGridViewModel(
          organisationId,
          +parentId,
          authUser,
          onMilestoneCreated,
          parentEntity,
          createMilestone
        )
    );

    useEffect(() => {
      if (
        (typeof parentId !== "undefined" && gridModel.parentId !== +parentId) ||
        gridModel.organisationId !== organisationId
      ) {
        setGridModel(
          new MilestonesGridViewModel(
            organisationId,
            +parentId,
            authUser,
            onMilestoneCreated,
            parentEntity,
            createMilestone
          )
        );
      }
      // eslint-disable-next-line
    }, [organisationId, parentId]);

    useEffect(() => {
      gridModel.onMount();
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={!data}>
          <PositionedSpinner />
        </IF>
        <IF condition={!!data}>
          <AppGridToolbar
            onQuichSearchInputChange={e => {
              gridRef.current.setSearchText(e.currentTarget.value);
            }}
            selectedItemCount={gridModel.selectedItems?.length}
            actions={gridModel.gridActions}
          />

          <AppGrid
            ref={gridRef}
            gridOptions={{ context: { deleteFn: gridModel.deleteFieldData } }}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            shouldTrackSelectedCell={true}
            rowSelection="multiple" // Options - allows click selection of rows
          />
        </IF>
      </>
    );
  }
);
