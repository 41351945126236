import { action, makeObservable, observable } from "mobx";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { TrainingProgressGridColumnBuilder } from "./TrainingProgressGridView_columns";
import {
  CommonColDefFieldNamesEnum,
  TrainingProgressColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { EntityTypes, GridTypes } from "../../../../../../enums";
import { AppGridState } from "../base/AppGrid_view";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import I18n from "../../../../../localization/I18n";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import {
  SHOW_CONFIRM_CREATION_MODAL,
  SHOW_TRAINING_PROGRESS_DELETE_CONFIRM_MODAL
} from "./TrainingProgressGrid_modals";
import { GetTrainingProgressGridActions } from "./TrainingProgressGridVies_actions";
import TrainingProgressApi, {
  TrainingProgressApi as ITrainingProgressApi
} from "../../../../../../services/api/v2/trainingProgress/TrainingProgress.api";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";

export class TrainingProgressGridModel {
  organisationId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  canEditCoreData: boolean;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  @observable projectTeamMembers: any[];
  @observable microTrainingProgressForm: SingleFormModel;
  httpProgress: IProgressIndicatorModel;
  trainingProgressGridColumnBuilder: TrainingProgressGridColumnBuilder;
  gridType: EntityTypes | GridTypes;
  type: GridTypes = GridTypes.TRAINING_PROGRESS_GRID;
  gridApi: GridApi;
  trainingProgressProvider: ITrainingProgressApi;
  toasterService: IToasterService;
  refreshDataHandler: any;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;
  gridToolbarType: AppGridToolbarType;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;

  constructor(
    organisationId: number,
    authUser: FP.Entities.IUser,
    gridToolbarType: AppGridToolbarType,
    refreshDataHandler: any,
    onFieldUpdate: () => void,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
    this.canEditCoreData = PermissionsContext.canContributeOrg(organisationId);
    this.gridToolbarType = gridToolbarType;
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.httpProgress = ProgressIndicatorModel;
    this.setMicroAddForm();
    this.generateAddons();
    this.toasterService = ToasterService;
    this.trainingProgressProvider = TrainingProgressApi;
  }

  loadInitData = async () => {
    if (this.canEditCoreData && this.gridToolbarType !== "link-modal") {
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, null);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  generateGridConfig = () => {
    this.trainingProgressGridColumnBuilder = new TrainingProgressGridColumnBuilder({
      canEdit: this.canEditCoreData && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      onFieldUpdate: this.onFieldUpdate,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        CommonColDefFieldNamesEnum.Name,
        TrainingProgressColDefFieldNamesEnum.TrainingProgressStatus,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.trainingProgressGridColumnBuilder.generateColumnDefs();
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setMicroAddForm = () => {
    this.microTrainingProgressForm = new SingleFormModel();
    this.microTrainingProgressForm.formFields = getEntityNameMicroFormFields(
      this.createMicroTrainingProgress,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.trainingProgress") })
    );
  };

  confirmCreateTrainingProgress = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.trainingProgressProvider
      .deleteRange(this.organisationId, itemIds)
      .then(async e => await this.refreshDataHandler());

    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  showTrainingProgressConfirmDeleteModal = () => {
    return SHOW_TRAINING_PROGRESS_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  @action
  createMicroTrainingProgress = async () => {
    if (!this.microTrainingProgressForm.isSaving) {
      let microTrainingProgressFormRes = await this.microTrainingProgressForm.submit();
      this.microTrainingProgressForm.isSaving = true;
      if (!microTrainingProgressFormRes) {
        this.microTrainingProgressForm.isSaving = false;
        return;
      }

      microTrainingProgressFormRes = {
        ...microTrainingProgressFormRes,
        organisation: this.organisationId
      };

      this.httpProgress.showOverlay();
      let trainingProgressNameExists = await this.trainingProgressProvider.getByName(
        this.organisationId,
        microTrainingProgressFormRes.name
      );

      if (
        trainingProgressNameExists &&
        !trainingProgressNameExists.isError &&
        trainingProgressNameExists.payload.length
      ) {
        this.httpProgress.hideOverlay();
        let confirmCreateTrainingProgress = await this.confirmCreateTrainingProgress(microTrainingProgressFormRes.name);
        if (!confirmCreateTrainingProgress) {
          this.microTrainingProgressForm.isSaving = false;
          return;
        }
        this.httpProgress.showOverlay();
      }

      const res = {
        ...microTrainingProgressFormRes
      };
      this.httpProgress.showOverlay();
      const result = await this.trainingProgressProvider.create(
        this.organisationId,
        res as FP.Entities.ITrainingProgress
      );

      this.httpProgress.hideOverlay();

      if (!result || result.isError) {
        this.microTrainingProgressForm.isSaving = false;
        return;
      }
      this.microTrainingProgressForm.isSaving = false;

      const trainingProgress = result.payload;
      await this.onFieldUpdate();
      this.microTrainingProgressForm.resetFields();
      this.toasterService
        .showSuccessToast()
        .setContent(
          <span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.trainingProgress") })}</span>
        )
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return trainingProgress;
    }
  };

  generateActions = () => {
    this.setGridActions(GetTrainingProgressGridActions(this));
  };

  @action
  setSelectedNodes = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(null, EntityTypes.TRAINING_PROGRESS);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: null,
      gridType: EntityTypes.TRAINING_PROGRESS,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: null,
      gridType: EntityTypes.TRAINING_PROGRESS,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };
}
