import { action, makeObservable, observable } from "mobx";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";

export class ProjectBenefitsPanelModel {
  @observable benefitsData: any[] = [];
  @observable isLoading: boolean = true;
  projectId: number;
  projectsApi: IProjectsApi;
  organisationId: number;

  /**
   *
   */
  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.projectsApi = ProjectsApi;
    this.projectId = projectId;
    this.organisationId = organisationId;
  }

  onMount = async () => {
    await this.loadBenefitData(this.projectId);
    this.setIsLoading(false);
  };

  @action
  loadBenefitData = async projectId => {
    const benefitsRes = await this.projectsApi.getBenefitsGridData(this.organisationId, projectId);
    if (benefitsRes && !benefitsRes.isError) {
      this.setBenefitData(benefitsRes.payload);
    } else {
      this.setBenefitData([]);
    }
  };

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };

  @action
  setBenefitData = benefitsData => {
    this.benefitsData = benefitsData;
  };
}
