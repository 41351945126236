import { action, makeObservable, observable } from "mobx";
import TrainingCoursesApi from "../../../../../services/api/v2/trainingCourses/TrainingCourses.api";

export class TrainingCoursesListViewModel {
  organisationId: number;
  projectId: number;
  @observable trainingCourseData: any[] = [];
  @observable isLoading: boolean = true;
  trainingCourseProvider = TrainingCoursesApi;

  /**
   *
   */
  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = () => {
    this.loadTrainingCourseData();
  };

  loadTrainingCourseData = async () => {
    let res = await this.trainingCourseProvider.getGridData(this.organisationId, this.projectId);

    if (res.isError) {
      return;
    }

    this.setTrainingCourseData(res.payload);
  };

  @action
  setTrainingCourseData = (trainingCoursedata: any[]) => {
    this.trainingCourseData = trainingCoursedata;
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
