import { IF } from "../../../../../components/hoc/If";
import { Panel } from "../../../../../components/ui/Panel";
import { CountDisplay } from "../../view/components/CountDisplay";
import { ReportingPie } from "../../view/components/ReportingPie";

const chartColours = {
  impactLevelColoursArray: ["#093D4C", "#095971", "#308096", "#BEBEBE"],
  impactAndActionCompletionDataColoursArray: [
    "#D3D3D3",
    "#FFD700",
    "#1AC541",
    "#FFA07A",
    "#B0E0E6",
    "#C0C0C0",
    "#ADD8E6"
  ],
  ragColourArray: ["#F91E1E", "#F9AB1E", "#1AC541", "#58C8D1", "#BEBEBE"],
  staColourArray: ["#3d00c1", "#2AE1EE", "#f7e6c3", "#ed9aed", "#BEBEBE"] //stakeholders & audience colour array
};

export const ProjectPieChartPanel: React.FC<{
  totalCount: number;
  onPieItemClick: (item: any) => void;
  data: any[];
  colorKey:
    | "impactLevelColoursArray"
    | "impactAndActionCompletionDataColoursArray"
    | "ragColourArray"
    | "staColourArray";
  shouldShowIf: boolean;
  label: string;
  permissionLabel: string;
  noDataLabel: string;
  countUrl: string;
  subtitle?: string;
  postfix?: string;
}> = ({
  totalCount,
  countUrl,
  onPieItemClick,
  data,
  shouldShowIf,
  subtitle,
  colorKey,
  label,
  permissionLabel,
  noDataLabel,
  postfix
}) => {
  return (
    <Panel.Panel
      hasBorder={true}
      background={Panel.PanelBackgrounds.BG_LIGHT}
      hasBorderRadius
      className=" p-3"
      style={{ height: "240px" }}
    >
      <div className="row">
        <div className="col-5 p-0">
          <div className="col p-0">
            <CountDisplay
              count={totalCount + (postfix || "")}
              url={countUrl}
              label={label}
              canView={shouldShowIf}
              permissionLabel={permissionLabel}
              subtitle={subtitle}
            />
          </div>
        </div>
        <IF condition={totalCount > 0 || postfix === "%"}>
          <div className="col-7 p-0">
            <ReportingPie cls={"p-0"} data={data} colourArray={chartColours[colorKey]} onClick={onPieItemClick} />
          </div>
        </IF>
        <IF condition={totalCount === 0 && postfix !== "%"}>
          <div className="col-7 p-0">
            <div className="panel count-display p-2">
              <p className="text-center">{noDataLabel}</p>
            </div>
          </div>
        </IF>
      </div>
    </Panel.Panel>
  );
};
