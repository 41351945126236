import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import http from "../Http";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";

export class ImpactTypesApi extends FilteredOrganisationApiModel<FP.Entities.IImpactType> {
  controller: string = "impact-types";

  constructor(http: AxiosInstance) {
    super(http, "impact-types");
  }

  addTypeToImpact = async (
    organisationId: number,
    projectId: number,
    impactImpactType: object,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}`,
      impactImpactType,
      config
    );
    return res.data;
  };

  removeTypeFromImpact = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    impactTypeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/impacts/${impactId}/${this.controller}/${impactTypeId}`,
      config
    );
    return res.data;
  };

  getImpactTypes = async (
    orgId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactType[]>> => {
    let url = this.url + "/" + orgId + "/" + this.controller + ((filterOptions && this.getRequestQuery(filterOptions)) || "")
    let res = await this.http.get(url, config);
    return res.data;
  };

  getImpactTypesByName = async (
    orgId: number,
    impactTypeName: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactType[]>> => {
    let res = await this.http.get(
      this.url + "/" + orgId + "/" + this.controller + "/by-name/" + impactTypeName,
      config
    );
    return res.data;
  };

  getUnselectedByOrganisation = async (
    organisationId: number,
    selectedOrganisationImpactTypeIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactType[]>> => {
    let res = await this.http.post(
      this.url + "/" + organisationId + "/impact-types/unselected-by-organisation",
      selectedOrganisationImpactTypeIds,
      config
    );
    return res.data;
  };
}

const instance = new ImpactTypesApi(http);
export default instance;
