import { action, makeObservable, observable } from "mobx";
import TrainingModulesApi from "../../../../../services/api/v2/trainingModules/TrainingModules.api";

export class TrainingModulesGridModalModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable data: FP.Entities.ITrainingModuleSummary[] = [];
  trainingModulesProvider = TrainingModulesApi;
  @observable selectedItemIds: number[] = [];
  /**
   *
   */
  constructor(organisationId: number, projectId: number, selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItemIds = selectedItemIds;
  }

  onMount = async () => {
    await this.load();
  };

  load = async () => {
    let res = await this.trainingModulesProvider.getGridData(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setData(res.payload);
    this.setIsLoading(false);
  };

  @action
  setData = (data: FP.Entities.ITrainingModuleSummary[]) => {
    this.data = data;
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
