import { AxiosInstance, AxiosRequestConfig } from "axios";
import { TrainingModuleField } from "../../../../enums";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import http from "../Http";
import { IFilteredResponse } from "../filteredApi/FilteredApiModel";

export class TrainingModulesApi extends ExportableProjectApi<FP.Entities.ITrainingModule> {
  controller: string = "training-modules";

  constructor(http: AxiosInstance) {
    super(http, "training-modules");
  }

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  deleteField = async (
    organisationId: number,
    projectId: number,
    trainingModuleIds: number,
    field: TrainingModuleField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${trainingModuleIds}/${field}`,
      config
    );
    return res.data;
  };

  getGridData = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/grid-data`,
      config
    );
    return res.data;
  };

  getDetailedByIdAsync = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`,
      config
    );
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    trainingModuleIds: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: TrainingModuleField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITrainingModule>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${trainingModuleIds}/${field}`,
      data,
      config
    );
    return res.data;
  };

  getByProjectIdAndName = async (
    organisationId: number,
    projectId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<any>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/name/${name}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  addBulkActionsToTrainingModules = async (
    organisationId: number,
    projectId: number,
    trainingModuleIds: number[],
    actionIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-actions-to-training-modules`;
    let res = await this.http.post(url, { actionIds, trainingModuleIds }, config);
    return res.data;
  };

  addBulkProjectProcessesToTrainingModules = async (
    organisationId: number,
    projectId: number,
    trainingModuleIds: number[],
    projectProcessIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-processes-to-training-modules`;
    let res = await this.http.post(url, { projectProcessIds, trainingModuleIds }, config);
    return res.data;
  };

  getTrainingModuleActionsByTrainingModuleId = async (
    organisationId: number,
    projectId: number,
    trainingModuleId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${trainingModuleId}/actionIds`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getTrainingModuleProcessesByTrainingModuleId = async (
    organisationId: number,
    projectId: number,
    trainingModuleId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${trainingModuleId}/projectProcessIds`;
    let res = await this.http.get(url, config);
    return res.data;
  };
}

const instance = new TrainingModulesApi(http);
export default instance;
