import { action, makeObservable, observable } from "mobx";
import BenefitApi from "../../../../../services/api/v2/benefits/Benefits.api";
import { useFlightPathUser } from "../../../../../core/auth_insight";

export class BenefitTrackerSettingsModel {
  organisationId: number;
  projectId: number;
  @observable BenefitData: any[] = [];
  @observable isLoading: boolean = true;
  benefitProvider = BenefitApi;
  @observable connectedUsers: FP.Entities.IUser[] = [];

  /**
   *
   */
  constructor(organisationId: number, projectId: number, authUser = useFlightPathUser) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = () => {
    this.loadBenefitData();
  };

  @action
  loadBenefitData = async () => {
    let res = await this.benefitProvider.getGridData(this.organisationId, this.projectId);
    this.setIsLoading(false);

    if (res.isError) {
      return;
    }
    this.setBenefitData(res.payload);
  };

  @action
  setBenefitData = (BenefitData: any[]) => {
    this.BenefitData = BenefitData;
    this.setIsLoading(false);
  };
  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };
}
