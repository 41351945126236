import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";

export interface UserProviderProps {
  children?: React.ReactNode;
}
const UserContext = React.createContext<any>({});

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { user } = useAuth0();
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function useUserIdentity() {
  return useContext(UserContext);
}

function flightPathUserFromUserIdentity(userIdentity: any) {
  return {
    firstName: userIdentity.given_name,
    lastName: userIdentity.family_name,
    email: userIdentity.email,
    sub: userIdentity.sub,
    id: userIdentity.flightPathUserId,
    profileImageUrl: userIdentity.picture
  } as any;
}

export function useFlightPathUser() {
  const userIdentity = useUserIdentity();
  const [flightPathUser, setFlightPathUser] = useState(() => flightPathUserFromUserIdentity(userIdentity));

  useEffect(() => {
    setFlightPathUser(flightPathUserFromUserIdentity(userIdentity));
  }, [userIdentity]);

  return flightPathUser;
}
