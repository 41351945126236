import { Panel } from "@flightpath/coreui";
import React, { ReactNode } from "react";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import I18n from "../../../../../../core/localization/I18n";

interface BenefitDisplayProps {
  title: string;
  owner?: string;
  value: any;
  status: string[] | string;
  img: any;
  date: ReactNode;
  rag: any;
  navToExtendedView: any;
  type: string;
}

export function BenefitDisplay({ children, className }) {
  return (
    <div>
      <h1>{I18n.t("phrases.benefitTracker")}</h1>
      <div className={className}>{children}</div>
    </div>
  );
}

export const BenefitDisplayCard: React.FC<BenefitDisplayProps> = ({
  title,
  value,
  status,
  img,
  type,
  date,
  rag,
  navToExtendedView
}) => {
  return (
    <Panel.Panel
      hasBorder={true}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasBorderRadius
      className="p-3 mb-3"
      style={{ height: "230px" }}
    >
      <div className="">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center ">
            {rag}
            <h2 className="m-0 ml-2 text-truncate text-uppercase">{title}</h2>
          </div>
          <h6 className="mr-3 mb-0">{I18n.t("phrases.owner")}</h6>
        </div>
        <div className="d-flex-end">
          <span>{img}</span>
        </div>
        <div className="d-flex align-items-center mb-3">
          <h1
            className={`benefit-tracker__value text-truncate display-l ${
              type === "-" ? "benefit-tracker__value--small" : ""
            }`}
          >
            {value}
          </h1>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mr-3 mb-0">{I18n.t("phrases.status")}</h4>
            <Panel.Panel hasBorder={false} hasBorderRadius className="benefit-tracker__panel--secondary p-2">
              {status}
            </Panel.Panel>
          </div>
          <div>
            <h4 className="m-0">{I18n.t("phrases.measurmentDate")}</h4>
            <Panel.Panel hasBorder={false} hasBorderRadius className="benefit-tracker__panel--secondary p-2">
              {date}
            </Panel.Panel>
          </div>
          <div className="d-flex align-items-center justify-content-center align-self-end">
            <Panel.Panel hasBorder={false} hasBorderRadius className="px-2 py-1 benefit-tracker__panel--secondary">
              <Icon symbol={IconSymbols.ChevronRight} className="p-0 m-0 cursor-pointer" onClick={navToExtendedView} />
            </Panel.Panel>
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
};
