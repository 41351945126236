import { ColDef, EditableCallbackParams } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { CommonColDefFieldNamesEnum, StakeholderColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { StakeholderField } from "../../../../../../enums";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  OWNER_NAME_COLUMN_CONFIG,
  OWNER_NAME_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/ownerName/OwnerNameColumn_config";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import StakeholdersApi from "../../../../../../services/api/v2/stakeholders/Stakeholders.api";
import {
  BUSINESS_AREA_COLUMN_CONFIG,
  BUSINESS_AREA_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/businessArea/BusinessAreaColumn_config";
import {
  ROLE_COLUMN_CONFIG,
  ROLE_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/role/RoleColumn_config";
import { makeObservable, observable } from "mobx";

export interface StakeholdersGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  userCanViewStakeholders: boolean;
  columns: string[];
  orgUsers: FP.Entities.IUser[];
  orgBusinessAreas: FP.Entities.IBusinessArea[];
  orgRoles: FP.Entities.IRole[];
  onFieldUpdate?: () => void;
}

export class StakeholdersGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: StakeholdersGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  @observable orgUsers: FP.Entities.IUser[];
  @observable orgBusinessAreas: FP.Entities.IBusinessArea[];
  @observable orgRoles: FP.Entities.IRole[];
  onFieldUpdate?: () => void;

  constructor(gridProps: StakeholdersGridColumnBuilderProps) {
    super(StakeholdersApi.updateField, gridProps.organisationId, 0, gridProps.canEdit);
    makeObservable(this);
    this.gridProps = gridProps;
    this.canEdit = gridProps.canEdit;
    this.organisationId = gridProps.organisationId;
    this.orgUsers = gridProps.orgUsers;
    this.orgBusinessAreas = gridProps.orgBusinessAreas;
    this.orgRoles = gridProps.orgRoles;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.FirstName]: () => this.buildFirstNameColumn(),
      [StakeholderColDefFieldNamesEnum.LastName]: () => this.buildLastNameColumn(),
      [StakeholderColDefFieldNamesEnum.Email]: () =>
        new SimpleTextColumnBuilder()
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .makeEditable(this.canEdit)
          .setColumnOptions({ field: StakeholderColDefFieldNamesEnum.Email, headerName: I18n.t("grids.email") })
          .setFilterOptions(BASE_FILTER_CONFIG)
          .createEmailValueSetter(this.updateEmail)
          .setEmailValidationClassRules()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Owner]: () => this.buildOwnerColumn(),
      [StakeholderColDefFieldNamesEnum.Role]: () => this.buildRoleColumn(),
      [StakeholderColDefFieldNamesEnum.BusinessArea]: () => this.buildBusinessAreaColumn(),
      [StakeholderColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.CreatedAt,
          headerName: I18n.t("grids.createdOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(StakeholderColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(StakeholderColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(StakeholderColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(StakeholderColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildFirstNameColumn = () => {
    let model = new NameColumnBuilder()
      .makeEditable(this.canEdit)
      .makeSelectable(this.canEdit)
      .makeReadOnly(false)
      .setColumnOptions({
        ...NAME_COLUMN_CONFIG({
          field: StakeholderColDefFieldNamesEnum.FirstName,
          headerName: I18n.t("grids.firstName")
        })
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      model.createValueSetter(this.updateFirstName);
    }

    return model.generateColumnOptions();
  };

  buildLastNameColumn = () => {
    let model = new NameColumnBuilder()
      .makeEditable(this.canEdit)
      .makeSelectable(this.canEdit)
      .makeReadOnly(false)
      .setColumnOptions({
        ...NAME_COLUMN_CONFIG({
          field: StakeholderColDefFieldNamesEnum.LastName,
          headerName: I18n.t("grids.lastName")
        })
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      model.createValueSetter(this.updateLastName);
    }

    return model.generateColumnOptions();
  };

  buildOwnerColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Owner, headerName: I18n.t("grids.owner") }),
        ...OWNER_NAME_COLUMN_CONFIG(CommonColDefFieldNamesEnum.Owner, this.orgUsers)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...OWNER_NAME_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[CommonColDefFieldNamesEnum.Owner]) return;
        return `${e.data[CommonColDefFieldNamesEnum.Owner] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data[CommonColDefFieldNamesEnum.Owner] = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateOwnerName(params.data.id, params.newValue.id);
        return true;
      });
    return model.generateColumnOptions();
  };

  buildBusinessAreaColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({
          field: StakeholderColDefFieldNamesEnum.BusinessArea,
          headerName: I18n.t("grids.businessArea")
        }),
        ...BUSINESS_AREA_COLUMN_CONFIG(this.orgBusinessAreas)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...BUSINESS_AREA_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[StakeholderColDefFieldNamesEnum.BusinessArea]) return;
        return `${e.data[StakeholderColDefFieldNamesEnum.BusinessArea] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data[StakeholderColDefFieldNamesEnum.BusinessArea] = `${params.newValue.name}`;

        this.updateBusinessArea(params.data.id, params.newValue.id).then(val => {
          params.api.redrawRows({ rowNodes: [params.node] });
        });
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildRoleColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({
          field: StakeholderColDefFieldNamesEnum.Role,
          headerName: I18n.t("grids.role")
        }),
        ...ROLE_COLUMN_CONFIG(this.orgRoles)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...ROLE_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable((params: EditableCallbackParams) => {
        return this.gridProps.canEdit && params.data[StakeholderColDefFieldNamesEnum.BusinessArea]?.length > 0;
      })
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[StakeholderColDefFieldNamesEnum.Role]) return;
        return `${e.data[StakeholderColDefFieldNamesEnum.Role] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data[StakeholderColDefFieldNamesEnum.Role] = `${params.newValue.name}`;

        this.updateRole(params.data.id, params.newValue.id);
        return true;
      })
      .withCellRenderer(params => {
        if (params.data[StakeholderColDefFieldNamesEnum.BusinessArea]?.length > 0) {
          return params.value;
        }

        return (
          <span style={{ color: "red", opacity: 0.6, fontStyle: "italic" }}>
            {I18n.t("phrases.businessAreaMissing")}
          </span>
        );
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  updateFirstName = async (entityId: number, text: string) => {
    await this.updateTextField(StakeholderField.firstName, entityId, text);
    await this.onFieldUpdate();
  };
  updateLastName = async (entityId: number, text: string) => {
    await this.updateTextField(StakeholderField.lastName, entityId, text);
    await this.onFieldUpdate();
  };
  updateEmail = async (entityId: number, text: string) => {
    await this.updateTextField(StakeholderField.emailAddress, entityId, text);
    await this.onFieldUpdate();
  };
  updateOwnerName = async (entityId: number, data: any) => {
    await this.updateIdField(StakeholderField.owner, entityId, data);
    await this.onFieldUpdate();
  };
  updateBusinessArea = async (entityId: number, data: any) => {
    await this.updateIdField(StakeholderField.businessArea, entityId, data);
    await this.onFieldUpdate();
  };
  updateRole = async (entityId: number, data: any) => {
    await this.updateIdField(StakeholderField.role, entityId, data);
    await this.onFieldUpdate();
  };
}
