import * as React from "react";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../../core/localization/I18n";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { FORM_COL } from "../../../../../constants";

export const GetChangePasswordFields = () => {
  const newPassword: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "newPassword",
    label: <label htmlFor={"newPassword"}> {I18n.t("forms.newPassword")} * </label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    inputType: "password",
    placeholder: I18n.t("placeholders.newPassword"),
    validate: function () {
      const self: ITextFieldModel = this;

      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.emptyPassword")}</ErrorMessage>;
        return false;
      }

      if (!Validations.IS_VALID_PASSWORD(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.invalidPassword")}</ErrorMessage>;
        return false;
      }

      return true;
    }
  };

  const confirmPassword: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "confirmPassword",
    subscribeTo: ["newPassword"],
    fieldClassName: FORM_COL.FULL_WIDTH,
    inputType: "password",
    label: <label htmlFor={"confirmPassword"}> {I18n.t("forms.confirmPassword")} *</label>,
    placeholder: I18n.t("placeholders.confirmPassword"),
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;

      if (Validations.PASSWORDS_DO_NOT_MATCH(self.channels.newPassword.value, self.value)) {
        this.errorMessage = <ErrorMessage>{I18n.t("errors.passwordsDoNotMatch")}</ErrorMessage>;
        res = false;
      }

      return res;
    }
  };

  const fields = [];
  fields.push(newPassword);
  fields.push(confirmPassword);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
