import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Authentication: React.FC<any> = ({ children }) => {
  const navigate = useNavigate();

  const domain = appConfig.auth0.domain;
  const clientId = appConfig.auth0.clientId;
  const redirectUri = appConfig.auth0.redirectUri;
  const audience = appConfig.auth0.audience;

  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || "login_complete");
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuthToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  return { getAuthToken };
};
