import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../core/grids/GridSideModals";
import I18n from "../../../../core/localization/I18n";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { ImpactsPerProjectByLevelSidebar } from "./modals/ImpactsPerProjectByLevelSidebar/ImpactsPerProjectByLevelSidebar_view";
import { ImpactsPerProjectByProgressStatusSidebar } from "./modals/ImpactsPerProjectByProgressStatusSidebar/ImpactsPerProjectByProgressStatusSidebar_view";

export const showImpactsPerProjectByProgressStatusPieChartSidebar = (data, pieData) => {
  ModalContext.show({
    title: (
      <GridSideModalTitle name={`${data.firstName} ${data.lastName}`} field={I18n.get("phrases.yourImpacts")} />
    ),
    showClose: true,
    content: (
      <ImpactsPerProjectByProgressStatusSidebar
        userId={data.id}
        progressStatus={pieData.data.key}
      ></ImpactsPerProjectByProgressStatusSidebar>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapWidth: "medium" },
    animationOptions: gridSideModalAnimationOpts
  });
};

export const showImpactsPerProjectByLevelPieChartSidebar = (data, pieData) => {
  ModalContext.show({
    title: (
      <GridSideModalTitle name={`${data.firstName} ${data.lastName}`} field={I18n.get("phrases.yourImpacts")} />
    ),
    showClose: true,
    content: (
      <ImpactsPerProjectByLevelSidebar
        userId={data.id}
        impactLevel={pieData.data.key}
      ></ImpactsPerProjectByLevelSidebar>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapWidth: "medium" },
    animationOptions: gridSideModalAnimationOpts
  });
};
