import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
// import { ProjectViewModel } from "../view/ProjectView_model";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import _ from "lodash";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { useCanViewField } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useUserIdentity } from "../../../../core/auth_insight";
import { UrlOperators } from "../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterAddon/FilterAddonContants";
import {
  ActionColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import I18n from "../../../../core/localization/I18n";
import { useModalContext } from "../../../../core/modalZ/context/ModalContext";
import Pages from "../../../../routes/InsightRoutes";
import { ProjectBenefitsPanel } from "./benefitsPanel/ProjectBenefitsPanel_view";
import { ProjectPieChartPanel } from "./pieChartPanel/ProjectPieChartPanel_view";
import { INFO_MODAL_CONFIG } from "./ProjectView_modals";
import { ProjectViewModel } from "./ProjectView_model";
import { QuickLinksPanel } from "./quickLinksPanel/QuickLinksPanel_view";
import { ProjectRadialChartPanel } from "./radialChartPanel/ProjectRadialChartPanel_view";
import { TimelinePanel } from "./timelinePanel/ProjectTimelinePanel_view";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../components/ui/Button";

const stakeholderMapUrl = (organisationId, projectId, tabId?: number, mapId?: number) => {
  return Pages.projects.stakeholders.visualisations.parameters.generateLink(
    organisationId,
    projectId,
    tabId || 1,
    mapId || 0
  );
};

export const ProjectView: React.FunctionComponent<any> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const [model, setModel] = useState(() => new ProjectViewModel(organisationId, +projectId));
  const modal = useModalContext();
  const { given_name, family_name } = useUserIdentity();
  const navigate = useNavigate();
  const canViewStakeholders = useCanViewField(PermissionFields.STAKEHOLDERS, organisationId, +projectId);
  const canViewActions = useCanViewField(PermissionFields.ACTIONS, organisationId, +projectId);

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    if (model.organisationId !== organisationId || model.projectId !== +projectId) {
      setModel(new ProjectViewModel(organisationId, +projectId));
    }
  }, [organisationId, projectId, model.organisationId, model.projectId]);

  const audienceMapOnClickFunction = useCallback(
    () => navigate(Pages.projects.audiences.visualisations.index.generateLink(organisationId, projectId)),
    [projectId, organisationId, navigate]
  );

  const stakeholderMapOnClickFunction = useCallback(
    () => navigate(stakeholderMapUrl(organisationId, projectId)),
    [navigate, projectId, organisationId]
  );

  const impactProgressOnClickFunction = useCallback(
    (dataObject: any) => {
      let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
        CommonColDefFieldNamesEnum.ProgressStatus
      }=${dataObject.label}`;
      navigate(url);
    },
    [organisationId, projectId, navigate]
  );

  const actionProgressOnClickFunction = useCallback(
    (dataObject: any) => {
      let url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
        CommonColDefFieldNamesEnum.ProgressStatus
      }=${dataObject.label}`;
      navigate(url);
    },
    [organisationId, projectId, navigate]
  );

  const impactTypeOnClickFunction = useCallback(
    (dataObject: any) => {
      let level = dataObject.id.charAt(0).toUpperCase() + dataObject.id.slice(1);
      let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
        ImpactColDefFieldNamesEnum.ImpactLevel
      }=${UrlOperators.contains.symbol}${level}`;
      navigate(url);
    },
    [projectId, organisationId, navigate]
  );

  const actionRagOnClickFunction = useCallback(
    (dataObject: any) => {
      let url = "";
      if (dataObject.label === "Completed") {
        url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
          CommonColDefFieldNamesEnum.ProgressStatus
        }=${dataObject.label}`;
      } else {
        url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
          ActionColDefFieldNamesEnum.RagStatus
        }=${dataObject.label}`;
      }
      navigate(url);
    },
    [organisationId, projectId, navigate]
  );

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className="container-fluid project-main-panel py-5">
      <div className="row mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <h1 className="mb-0">
              {I18n.t("phrases.project")} | {model.project?.name}
            </h1>
            <CanEdit field={PermissionFields.PROJECTS} projectId={+projectId}>
              <LinkButton
                id="manageProjectButton"
                className="ml-auto mr-2"
                type={ButtonTypes.PRIMARY}
                href={Pages.projects.settings.index.generateLink(organisationId, projectId)}
              >
                {I18n.t("phrases.manageProject")}
              </LinkButton>
            </CanEdit>
            <ButtonIcon
              symbol={IconSymbols.MenuArrowRight}
              onClick={() => modal.show(INFO_MODAL_CONFIG(model))}
              type={ButtonTypes.LINK}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ProjectBenefitsPanel />
        </div>
      </div>
      <div className="row">
        <div className="col mb-5">
          <TimelinePanel project={model.project} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          <ProjectRadialChartPanel />
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          <div className="mb-5">
            {/* Impact progress */}
            <ProjectPieChartPanel
              totalCount={model.impactPercentComplete}
              onPieItemClick={impactProgressOnClickFunction}
              data={model.impactCompletionData}
              colorKey={"impactAndActionCompletionDataColoursArray"}
              shouldShowIf={canViewStakeholders}
              countUrl={Pages.projects.impacts.listView.generateLink(organisationId, projectId)}
              label={I18n.t("phrases.impactsProgress")}
              permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
              noDataLabel={I18n.t("phrases.projectDashboardNoStakeholders")}
              subtitle={I18n.t("phrases.impactsComplete")}
              postfix="%"
            />
          </div>
          {/* Action Progress */}
          <ProjectPieChartPanel
            totalCount={model.actionPercentComplete}
            onPieItemClick={actionProgressOnClickFunction}
            data={model.actionsCompletionData}
            colorKey={"impactAndActionCompletionDataColoursArray"}
            shouldShowIf={canViewStakeholders}
            countUrl={Pages.projects.actions.listView.generateLink(organisationId, projectId)}
            label={I18n.t("phrases.actionsProgress")}
            permissionLabel={I18n.t("warnings.noActionAccessPanel")}
            noDataLabel={I18n.t("phrases.projectDashboardNoActions")}
            subtitle={I18n.t("phrases.actionsComplete")}
            postfix="%"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          {/* Total Impacts */}
          <ProjectPieChartPanel
            totalCount={model.totalImpacts}
            onPieItemClick={impactTypeOnClickFunction}
            data={model.impactData}
            colorKey={"impactLevelColoursArray"}
            shouldShowIf={canViewStakeholders}
            label={I18n.t("phrases.totalImpacts")}
            permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
            noDataLabel={I18n.t("phrases.projectDashboardNoImpacts")}
            countUrl={Pages.projects.impacts.listView.generateLink(organisationId, projectId)}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          {/* Total Actions */}
          <ProjectPieChartPanel
            totalCount={model.totalActions}
            onPieItemClick={actionRagOnClickFunction}
            data={model.ragData}
            colorKey={"ragColourArray"}
            shouldShowIf={canViewActions}
            label={I18n.t("phrases.totalActions")}
            permissionLabel={I18n.t("warnings.noActionAccessPanel")}
            noDataLabel={I18n.t("phrases.projectDashboardNoActions")}
            countUrl={Pages.projects.actions.listView.generateLink(organisationId, projectId)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          {/* Total Stakeholders */}
          <ProjectPieChartPanel
            totalCount={model.totalStakeholders}
            onPieItemClick={stakeholderMapOnClickFunction}
            data={model.stakeholderData}
            colorKey={"staColourArray"}
            shouldShowIf={canViewStakeholders}
            label={I18n.t("phrases.totalStakeholders")}
            permissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
            noDataLabel={I18n.t("phrases.projectDashboardNoStakeholders")}
            countUrl={Pages.projects.stakeholders.listView.generateLink(organisationId, projectId)}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mb-5 px-3 py-0">
          {/* Total Audience */}
          <ProjectPieChartPanel
            totalCount={model.totalAudiences}
            onPieItemClick={audienceMapOnClickFunction}
            data={model.audienceData}
            colorKey={"staColourArray"}
            countUrl={Pages.projects.audiences.listView.generateLink(organisationId, projectId)}
            shouldShowIf={canViewStakeholders}
            label={I18n.t("phrases.totalAudiences")}
            permissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
            noDataLabel={I18n.t("phrases.projectDashboardNoAudiences")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <QuickLinksPanel
            impactPercentComplete={model.impactPercentComplete}
            actionPercentComplete={model.actionPercentComplete}
            stakeholdersTotal={_.sumBy(model.stakeholderData, e => e.value)}
            audiencesTotal={_.sumBy(model.audienceData, e => e.value)}
            firstName={given_name}
            lastName={family_name}
          />
        </div>
      </div>
    </div>
  );
});
