import { action, makeObservable, observable } from "mobx";
import TrainingModulesApi from "../../../../../services/api/v2/trainingModules/TrainingModules.api";

export class TrainingModulesListViewModel {
  organisationId: number;
  projectId: number;
  @observable trainingModuleData: any[] = [];
  @observable isLoading: boolean = true;
  trainingModuleProvider = TrainingModulesApi;

  /**
   *
   */
  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = () => {
    this.loadTrainingModuleData();
  };

  loadTrainingModuleData = async () => {
    let res = await this.trainingModuleProvider.getGridData(this.organisationId, this.projectId);

    if (res.isError) {
      return;
    }

    this.setTrainingModuleData(res.payload);
  };

  @action
  setTrainingModuleData = (trainingModuledata: any[]) => {
    this.trainingModuleData = trainingModuledata;
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
