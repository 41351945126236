import { PermissionScope } from "../../enums";

export enum PermissionFields {
  PROJECTS = "project",
  ACTIONS = "action",
  IMPACTS = "impact",
  STAKEHOLDERS = "stakeholder",
  PROCESSES = "process",
  AUDIENCES = "audience",
}

export interface IEditView {
  canEdit: boolean;
  canView: boolean;
}

export interface IProjectPermission {
  id: number;
  name: string;
  canManagePermissions: boolean;
  project: IEditView;
  impact: IEditView;
  action: IEditView;
  stakeholder: IEditView;
  audience: IEditView;
  parentId: number;
}

export interface IProgrammePermission {
  kk: IProjectPermission[];
  id: number;
  name: string;
  projectIds: number[];
  projects: IProjectPermission[];
}

export interface IOrganisationPermission extends Partial<Omit<FP.Entities.IOrganisation, "projects" | "programmes">> {
  permissionScope: PermissionScope;
  projects: Dictionary<IProjectPermission>;
  programmes: Dictionary<IProgrammePermission>;
}
