import _ from "lodash";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import { ProjectProcessesApi } from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { filterOutExistingNames } from "../../../../../../../core/grids/GridHelpers";

export const getProjectProcessScopeItemsSidebarFields = (
  organisationId: number,
  projectId: number,
  projectProcessProvider: ProjectProcessesApi,
  process: FP.Entities.IProjectProcess,
  currentScopeItems: FP.Entities.IScopeItem[],
  onUpdate: (processId: number) => void
) => {
  const removeScopeItem = async (scopeItem: FP.Entities.IScopeItem) => {
    let res = await projectProcessProvider.removeScopeItem(organisationId, projectId, process.id, scopeItem.id);
    if (!res) return;
  };

  const addScopeItem = async (scopeItem: FP.Entities.IScopeItem) => {
    let res = await projectProcessProvider.addScopeItem(
      organisationId,
      projectId,
      process.id,
      scopeItem.id,
      scopeItem.name
    );
    if (!res) return;

    await onUpdate(process.id);
    return res;
  };

  const addNewScopeItem = async (scopeItemName: string) => {
    const scopeItem: FP.Entities.IScopeItem = {
      id: 0,
      name: scopeItemName,
      slug: _.kebabCase(scopeItemName),
      organisationId: organisationId
    };

    return await addScopeItem(scopeItem);
  };

  const scopeItems: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "scopeItems",
    placeholder: I18n.t("placeholders.searchOrCreateScopeItems"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: any) => e.text} />,
    onItemSelected: e => addScopeItem(e),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const currentScopeItems = await projectProcessProvider.getScopeItems(organisationId, projectId, process.id);
      const orgScopeItems = await projectProcessProvider.getScopeItemsByOrgId(organisationId, projectId);

      if (currentScopeItems?.payload && orgScopeItems?.payload) {
        const currentVals = self.extractValue().map(e => e.name);
        const sortedScopeItems = _.orderBy(orgScopeItems.payload, [scopeItem => scopeItem.name.toLowerCase()]);

        const filteredValues = filterOutExistingNames(currentVals, sortedScopeItems)
          .filter(e => e.name?.length > 0)
          .map(e => ({ ...e, text: e.name }));

        self.setOptions(filteredValues);
      }
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: currentScopeItems.map(e => {
      return { ...e, text: e.name };
    }),
    isHidden: false,
    valueLabelFn: e => e?.text || e.name,
    saveOnEnterFn: e => addNewScopeItem(e.target.value),
    removeItemFn: (e, i) => removeScopeItem(e),
    isTagSelector: false,
    allowFreeText: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.scopeItemsResultsNotFound"),
    searchResultHint: I18n.t("forms.scopeItemsSearchResultHint")
  };

  const fields = [];

  fields.push(scopeItems);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
