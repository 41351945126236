import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { UiSizes } from "../../../../../../enums";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { GridActionEnum, GridLinkActionEnum } from "../../../enums/GridActionEnum";
import { TrainingCoursesGridModel } from "./TrainingCoursesGridView_model";

export const GetTrainingCoursesGridActions = (model: TrainingCoursesGridModel) => {
  return [
    {
      id: "name",
      label: I18n.t("phrases.add"),
      componentProps: {
        symbol: IconSymbols.Plus
      },
      type: "microForm",
      contextProps: {
        formModel: model.microTrainingCourseForm,
        onSubmit: model.createMicroTrainingCourse
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.delete,
      label: I18n.t("phrases.delete"),
      type: "uiAction",
      onAction: ev => model.showTrainingCourseConfirmDeleteModal(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Trash
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.link,
      label: (
        <>
          <Icon symbol={IconSymbols.Link} size={UiSizes.MD} />
          <Icon symbol={IconSymbols.ChevronDown} size={UiSizes.SM} />
        </>
      ),
      type: "uiAction",
      componentProps: {
        type: ButtonTypes.LINK,
        className: "p-1"
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON,
      children: [
        {
          id: GridLinkActionEnum.linkTrainingModules,
          label: I18n.t("phrases.trainingModules"),
          type: "uiAction",
          onAction: ev => model.showLinkToTrainingModules(),
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: GridLinkActionEnum.linkActions,
          label: I18n.t("phrases.actions"),
          type: "uiAction",
          onAction: ev => model.showLinkToActions(),
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: GridLinkActionEnum.linkProcesses,
          label: I18n.t("phrases.processes"),
          type: "uiAction",
          onAction: ev => model.showLinkToProcesses(),
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        }
      ]
    },
    {
      id: GridActionEnum.export,
      label: I18n.t("phrases.export"),
      type: "uiAction",
      onAction: ev => model.exportRows(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Download
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: "devider",
      label: "",
      type: "devider",
      onAction: ev => {},
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.textWrap,
      label: I18n.t("phrases.textWrap"),
      type: "uiAction",
      onAction: ev => {
        model.textWrapAddon.toggleTextWrap();
        model.generateActions();
      },
      componentProps: {
        type: model.textWrapAddon.isWrappedText ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY,
        symbol: IconSymbols.TextWrap
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.clearFilters,
      label: I18n.t("phrases.clearFilters"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.filterStoreAddon.clearFilters();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.ClearFilter,
        isDisabled: !model.isFilterChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.resetColumns,
      label: I18n.t("phrases.resetColumns"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.columnStateAddon.resetColumns();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.MonitorBack,
        isDisabled: !model.isColumnStateChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    }
  ];
};
