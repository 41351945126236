import { action, makeObservable, observable } from "mobx";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class ProjectStakeholdersGridModalModel {
  organisationId: number;
  projectId: number;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  @observable isLoading: boolean = true;
  @observable projectStakeholders: any[];
  @observable projectStakeholderColDefs: any[];
  @observable selectedItemIds: number[];

  constructor(organisationId: number, projectId: number, selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.selectedItemIds = selectedItemIds;
  }

  onMount = async () => {
    await this.loadProjectStakeholders();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  loadProjectStakeholders = async () => {
    const res = await this.projectStakeholdersProvider.getGridData(this.organisationId, this.projectId);

    if (!res.payload || res.isError) return;

    this.setProjectStakeholders(res.payload.data);
    this.setProjectStakeholderColDefs(res.payload.columnDefs);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
  @action
  setProjectStakeholders = (projectStakeholders: any[]) => {
    this.projectStakeholders = projectStakeholders;
  };
  @action
  setProjectStakeholderColDefs = (projectStakeholderColDefs: any[]) => {
    this.projectStakeholderColDefs = projectStakeholderColDefs;
  };
}
