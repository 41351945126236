import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { IF } from "../../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../../localization/I18n";
import { ManageTagForm } from "./ManageTagForm";
import { TagsSidebarModel } from "./TagsSidebar_model";
import { Panel } from "../../../../../../../components/ui/Panel";

export interface TagsSidebarProps {
  organisationId: number;
  projectId: number;
  itemId: number;
  loadItem: (id: number) => Promise<any>;
  onAssignSuccess?: any;
  addTagAssociation: (item: any) => any;
  removeTagAssociation: (item: any) => any;
}

export const TagsSidebar: React.FC<TagsSidebarProps> = observer(props => {
  const { projectId, itemId, onAssignSuccess, loadItem, addTagAssociation, removeTagAssociation } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(
    () => new TagsSidebarModel(organisationId, projectId, itemId, onAssignSuccess, loadItem)
  );

  useEffect(() => {
    if (model.projectId !== projectId) {
      setModel(new TagsSidebarModel(organisationId, projectId, itemId, onAssignSuccess, loadItem));
    }
  }, [organisationId, projectId, itemId, model.projectId, loadItem, onAssignSuccess]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  const canEdit = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);

  return (
    <div className="container-fluid">
      <IF condition={canEdit}>
        <div className="row mb-4">
          <div className="col">
            <ManageTagForm
              organisationId={organisationId}
              projectId={projectId}
              items={model.tags}
              addTag={addTagAssociation}
              removeTag={removeTagAssociation}
              onAssignSuccess={onAssignSuccess}
            />
          </div>
        </div>
      </IF>

      <IF condition={!canEdit}>
        <div className="row">
          <div className="col">
            {model.tags.length > 0 ? (
              model.tags.map(t => (
                <Tag
                  tagText={t.text}
                  isEditable={false}
                  testId={t.id + ""}
                  key={t.id}
                  isNew={false}
                  onClick={e => {
                    removeTagAssociation(t.id);
                  }}
                />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noTags")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      </IF>
    </div>
  );
});
