import { ColDef, ICellEditorParams } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { minimumDate } from "../../../../../../enums";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import {
  AuccaColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  PhaseColDefFieldNamesEnum,
  PulseColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { PulseField } from "../../utils/GridFields";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import moment from "moment";
import { stringToMomentDateForComparison } from "../../utils/helpers";
import { AuccaGridColumnBuilder } from "../base/AuccaGridColumnBuilder";
import PulsesApi from "../../../../../../services/api/v2/pulses/Pulses.api";
import { MasterDetailRowColumnBuilder } from "../../columns/commonColumns/MasterDetailRowColumn/MasterDetailRowColumn_builder";
import { SyntheticEvent } from "react";
import { DEFAULT_DATE } from "../../../../../../constants";
import ToasterService from "../../../../../toaster/ToasterService";

export interface PulsesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewPhases: boolean;
  columns: string[];
  onFieldUpdate: () => void;
}

export class PulsesGridColumnBuilder extends AuccaGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PulsesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  onFieldUpdate: () => void;

  constructor(gridProps: PulsesGridColumnBuilderProps) {
    super(PulsesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [AuccaColDefFieldNamesEnum.Awareness]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Awareness, header || "Awareness"),
      [AuccaColDefFieldNamesEnum.Understanding]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Understanding, header || "Understand"),
      [AuccaColDefFieldNamesEnum.Commitment]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Commitment, header || "Commit"),
      [AuccaColDefFieldNamesEnum.Capability]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Capability, header || "Acquire"),
      [AuccaColDefFieldNamesEnum.Adoption]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Adoption, header || "Apply"),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    if (this.gridProps.canEdit) {
      res.unshift(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());
    }
    return res;
  };

  buildNameColumn = () => {
    let model = new MasterDetailRowColumnBuilder({
      field: CommonColDefFieldNamesEnum.Name,
      headerName: I18n.t("phrases.name"),
      pinned: "left",
      resizable: true,
      width: 100
    })
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model
        .withCustomActionsCellRenderer(CommonColDefFieldNamesEnum.Name, this.generateViewChildGridAction)
        .useExpandedMasterHeaderHighlighting([CommonColDefFieldNamesEnum.Name])
        .createValueSetter(this.updateName);
    }
    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);
    if (this.gridProps.canEdit) {
      model.makeDeletable().setEditableOnDoubleClick(this.updateDescription);
    }
    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.StartDate,
          headerName: I18n.t("grids.startDate")
        })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable((params: ICellEditorParams) => {
        if (
          params.data[PulseColDefFieldNamesEnum.Phase].startDate &&
          moment(params.data[PulseColDefFieldNamesEnum.Phase].startDate) > moment(minimumDate)
        ) {
          return this.gridProps.canEdit;
        }
        return false;
      })
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: (params: ICellEditorParams) => {
            let phaseStartDate = moment(
              params.data[PulseColDefFieldNamesEnum.Phase][CommonColDefFieldNamesEnum.StartDate]
            );
            if (moment(params.data[CommonColDefFieldNamesEnum.StartDate]) > moment(minimumDate)) {
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            return phaseStartDate;
          },
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = minimumDate;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }
            const phasePulses = params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Pulses];
            const startDate = moment(e);
            const endDate =
              params.data[CommonColDefFieldNamesEnum.EndDate] &&
              moment(params.data[CommonColDefFieldNamesEnum.EndDate]);
            const isStartDateBeforeEndDate =
              !endDate || endDate.format("L") === moment(DEFAULT_DATE).format("L")
                ? true
                : startDate.isBefore(endDate, "day");
            const phase = params.data[PulseColDefFieldNamesEnum.Phase];
            const isStartDateOfAnotherPulse = phasePulses.some(
              pulse =>
                stringToMomentDateForComparison(pulse.startDate) ===
                stringToMomentDateForComparison(startDate.toString())
            );
            const phaseStartDate = moment(phase[CommonColDefFieldNamesEnum.StartDate]);
            const rawProjectEndDate =
              params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Project][
                CommonColDefFieldNamesEnum.ActualEndDate
              ];
            const projectEndDate = rawProjectEndDate ? moment(rawProjectEndDate) : null;
            const isStartDateBeforeProjectEndDate = !projectEndDate
              ? true
              : startDate.isSameOrBefore(projectEndDate, "day");
            const isStartDateSameOrAfterPhaseStartDate = startDate.isSameOrAfter(phaseStartDate);
            const isStartDateValid =
              isStartDateSameOrAfterPhaseStartDate &&
              !isStartDateOfAnotherPulse &&
              isStartDateBeforeEndDate &&
              isStartDateBeforeProjectEndDate;
            if (isStartDateValid) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            } else {
              const res = GetStartDateErrorMessage(
                isStartDateBeforeEndDate,
                isStartDateOfAnotherPulse,
                isStartDateSameOrAfterPhaseStartDate,
                isStartDateBeforeProjectEndDate
              );
              ToasterService.showErrorToast(6000, "right").setContent(<p>{res}</p>);
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });
    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }
    return model.generateColumnOptions();
  };

  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.EndDate, headerName: I18n.t("grids.endDate") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable((params: ICellEditorParams) => {
        if (
          params.data[CommonColDefFieldNamesEnum.StartDate] &&
          moment(params.data[CommonColDefFieldNamesEnum.StartDate]) > moment(minimumDate)
        ) {
          return this.gridProps.canEdit;
        }
        return false;
      })
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.EndDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.EndDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.EndDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: params => {
            if (
              params.data[CommonColDefFieldNamesEnum.StartDate] > minimumDate &&
              params.data[CommonColDefFieldNamesEnum.EndDate] > minimumDate &&
              !!params.data[CommonColDefFieldNamesEnum.EndDate]
            ) {
              return moment(params.data[CommonColDefFieldNamesEnum.StartDate]).add(1, "day");
            }

            if (params.data[CommonColDefFieldNamesEnum.EndDate] > minimumDate) {
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            return params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Project][
              CommonColDefFieldNamesEnum.ActualEndDate
            ];
          },
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = minimumDate;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let projectEndDate = moment(
              params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Project][
                CommonColDefFieldNamesEnum.ActualEndDate
              ]
            );
            let endDate = moment(e);
            const hasStartDate = params.data[CommonColDefFieldNamesEnum.StartDate];
            const isStartDateAfterMinimumCheck =
              stringToMomentDateForComparison(params.data[CommonColDefFieldNamesEnum.StartDate]) >
              stringToMomentDateForComparison(minimumDate);

            const isStartDateAfterMinimum = hasStartDate && isStartDateAfterMinimumCheck;

            const startDate = isStartDateAfterMinimum
              ? moment(params.data[CommonColDefFieldNamesEnum.StartDate])
              : false;
            const phasePulsesEndDates = params.data[PulseColDefFieldNamesEnum.Phase][
              PhaseColDefFieldNamesEnum.Pulses
            ].map(e => moment(e.endDate));
            const existingEndDates = phasePulsesEndDates.find(
              item => stringToMomentDateForComparison(item.toString()) === stringToMomentDateForComparison(e)
            );
            const isEndDateSameAsStartDate = startDate && endDate.isSame(startDate, "day");
            const isEndDateBeyondProject = endDate.isAfter(projectEndDate, "day");
            const endDateAlreadyExists = !!existingEndDates;
            const endDateAfterStartDateComparison =
              stringToMomentDateForComparison(e) > stringToMomentDateForComparison(startDate.toString());

            const endDateIsAfterStartDate = !startDate || endDateAfterStartDateComparison;
            const canUpdateEndDate =
              !isEndDateSameAsStartDate && !isEndDateBeyondProject && !endDateAlreadyExists && endDateIsAfterStartDate;

            if (canUpdateEndDate) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            } else {
              const res = GetEndDateErrorMessage(
                !isEndDateSameAsStartDate,
                !isEndDateBeyondProject,
                !endDateAlreadyExists,
                endDateIsAfterStartDate
              );
              ToasterService.showErrorToast(6000, "right").setContent(<p>{res}</p>);
            }

            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }
    return model.generateColumnOptions();
  };

  generateViewChildGridAction = (
    value: string,
    isExpanded: boolean,
    classname: string,
    onclick: (ev: SyntheticEvent) => void
  ) => {
    return (
      <>
        {isExpanded ? (
          <span className={`ag-group-expanded pointer-class ${classname}-view`} onClick={onclick}>
            <span className="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>
          </span>
        ) : (
          <span className={`ag-group-contracted pointer-class ${classname}-view`} onClick={onclick}>
            <span className="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>
          </span>
        )}
        <span>{value}</span>
      </>
    );
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(PulseField.DESCRIPTION, entityId, text);
    this.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(PulseField.NAME, entityId, text);
    this.onFieldUpdate();
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PulseField.START_DATE, entityId, date);
    this.onFieldUpdate();
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PulseField.END_DATE, entityId, date);
    this.onFieldUpdate();
  };
}

const GetStartDateErrorMessage = (
  isStartDateBeforeEndDate: boolean,
  isStartDateOfAnotherPulse: boolean,
  isStartDateSameOrAfterPhaseStartDate: boolean,
  isStartDateBeforeProjectEndDate: boolean
) => {
  let res = "";

  if (isStartDateBeforeEndDate) {
    res += "Start date should be before end date. ";
  }

  if (!isStartDateOfAnotherPulse) {
    res += "Start date should not be same as other pulses ";
  }

  if (isStartDateSameOrAfterPhaseStartDate) {
    res += "Start date should be after phase start date. ";
  }

  if (isStartDateBeforeProjectEndDate) {
    res += "Start date should be before project end date. ";
  }

  return res;
};

const GetEndDateErrorMessage = (
  isEndDateSameAsStartDate: boolean,
  isEndDateBeyondProject: boolean,
  endDateAlreadyExists: boolean,
  endDateIsAfterStartDate: boolean
) => {
  let res = "";

  if (isEndDateSameAsStartDate) {
    res += "End date should not be same as start date. ";
  }

  if (isEndDateBeyondProject) {
    res += "End date should be before project end date. ";
  }

  if (endDateAlreadyExists) {
    res += "End date already exists. ";
  }

  if (!endDateIsAfterStartDate) {
    res += "End date should be after start date. ";
  }

  return res;
};
