import _ from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { MilestoneType } from "../../../../../enums";
import AudienceReportingApi, {
  AudienceReportingApi as IAudienceReportingApi
} from "../../../../../services/api/v2/audienceReporting/AudienceReporting.api";
import ProjectsApi from "../../../../../services/api/v2/projects/Projects.api";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import moment from "moment";

export interface IReadinessLineChartPoints {
  name: string;
  id: number;
  data: { x: string | number; y: string | number }[];
}

export interface IReadinessSpiderChartLatestValues {
  awareness: number;
  understanding: number;
  capability: number;
  commitment: number;
  adoption: number;
}

export interface IAudienceReadinessLineChartDateRange {
  startDate: string;
  endDate: string;
}

export interface IReadinessLineChartGroup {
  id: number;
  startDate: string;
  name: string;
  awareness: number;
  understanding: number;
  capability: number;
  commitment: number;
  adoption: number;
}

export interface IReadinessLineChartGroupMember {
  id: number;
  name: string;
  startDate: string;
}

export interface IReadinessLineChartData {
  auccaScores: IReadinessLineChartPoints[];
  phases: IReadinessLineChartGroup[];
  pulses: IReadinessLineChartGroupMember[];
  project: IAudienceReadinessLineChartDateRange;
}

export interface IReadinessSpiderChartData {
  latestValues: IReadinessSpiderChartLatestValues[];
  targetValues: IReadinessSpiderChartLatestValues[];
}

export class ReadinessChartsViewModel {
  organisationId: number;
  projectId: number;
  @observable auccaScores: IReadinessLineChartPoints[];
  @observable phases: IReadinessLineChartGroup[];
  @observable project: IAudienceReadinessLineChartDateRange;
  @observable pulses: IReadinessLineChartGroupMember[];
  @observable isLoading: boolean = true;
  @observable goLiveMilestones: FP.Entities.IMilestone[] = [];
  @observable filterJSON: any = {};
  projectsProvider = ProjectsApi;
  localStorage = LocalStorageService;
  audienceReportingProvider: IAudienceReportingApi;
  httpProgress: IProgressIndicatorModel;

  @computed get hasFilters(): boolean {
    var res = false;
    _.forEach(this.filterJSON, (e: any) => {
      res = res || e.length > 0;
    });

    return res;
  }

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audienceReportingProvider = AudienceReportingApi;
    let filters = this.localStorage.get(this.getStorageKey(organisationId, projectId));
    this.httpProgress = ProgressIndicatorModel;

    if (filters) {
      this.setFilterJSON(JSON.parse(filters));
    }
  }

  onMount = async () => {
    await this.loadData();
  };

  @action
  loadData = async () => {
    this.httpProgress.showOverlay();
    await this.loadAudienceLineChartData();
    await this.loadGoLiveMilestones();
    this.httpProgress.hideOverlay();
    this.setIsLoading(false);
  };

  @action
  loadAudienceLineChartData = async () => {
    const res = await this.audienceReportingProvider.getAudiencesLineChartDataByProjectIdAudienceIds(
      this.organisationId,
      this.projectId,
      this.filterJSON
    );
    if (!res || res.isError) {
      return;
    }
    const { auccaScores, phases, pulses, project } = res.payload;

    this.auccaScores = auccaScores;
    this.phases = phases;
    this.pulses = pulses;
    this.project = project;
  };

  loadGoLiveMilestones = async () => {
    const res = await this.projectsProvider.getById(this.organisationId, this.projectId);
    if (res.isError) return;

    let project = res.payload;
    let milestones: FP.Entities.IMilestone[] = project.milestones;

    const filtered = milestones.filter(
      e =>
        e.milestoneType === MilestoneType.GO_LIVE &&
        moment(e.deadline).isSameOrAfter(project.startDate) &&
        moment(e.deadline).isSameOrBefore(project.actualEndDate)
    );
    this.setGoLiveMilestones(filtered);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGoLiveMilestones = (goLiveMilestones: any[]) => {
    this.goLiveMilestones = goLiveMilestones;
  };

  @action
  setFilterJSON = (filterJSON: any) => {
    this.filterJSON = filterJSON;
    this.localStorage.set(this.getStorageKey(this.organisationId, this.projectId), JSON.stringify(filterJSON));
  };

  @action
  setFilterJSONFromList = (filterJSON: any[]) => {
    let s = {};

    filterJSON.forEach(e => {
      s[e.key] = e.ids;
    });
    this.setFilterJSON(s);
  };

  resetLocalStorage = () => {
    this.localStorage.remove(this.getStorageKey(this.organisationId, this.projectId));
  };

  getStorageKey = (organisationId, projectId) => {
    return `audience-readiness-charts-${organisationId}-${projectId}`;
  };
}
