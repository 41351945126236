import React from "react";
import { Link, useParams } from "react-router-dom";
import { AvatarContent } from "../../../../../components/ui/AvatarContent";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { CollapsingText } from "../../../../../components/ui/CollapsingText";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Pill, PillTypes } from "../../../../../components/ui/Pill";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../core/localization/I18n";
import { convertStakeholderToName, getOrgStakeholderLink } from "../../../../../core/util/Helpers";
import { Enums, ProgressStatus, UiSizes } from "../../../../../enums";
import { ProjectTeamCollapsableListView } from "../../../../change/projects/view/ProjectTeam";
import { StakeholderList } from "../../view/components/StakeholderList";

import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../components/ui/Tag";
import { IF } from "../../../../../components/hoc/If";
import { ProjectViewModel } from "../ProjectView_model";

export interface ProjectSidePanelProps {
  parentModel?: ProjectViewModel;
}

export const ProjectSidePanel: React.FC<ProjectSidePanelProps> = ({ parentModel }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const { project } = parentModel;
  if (!project) {
    return <PositionedSpinner />;
  }
  const keyStakeholders =
    project?.keyStakeholders?.filter(e => e.stakeholderType === Enums.StakeholderType.INDIVIDUAL) || [];
  const pillType = project?.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  const ClampButton = props =>
    project.description?.length ? (
      <Button type={ButtonTypes.LINK} className={"mt-1 pl-0 py-0"} onClick={() => props.setIsClamped(!props.isClamped)}>
        {props.isClamped ? I18n.t("phrases.seeMore") : I18n.t("phrases.seeLess")}
      </Button>
    ) : null;

  return (
    <>
      <div className="row project-overview__side-panel__project-status">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.projectStatus")}</h2>
        </div>
      </div>
      <div className="row mt-2 mb-4">
        <div className="col">
          <Pill data-testid="progress-status-label" type={pillType}>
            {Enums.Translator.ProgressStatus(project.progressStatus)}
          </Pill>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-12">
          <h2 className="mb-1">{I18n.t("phrases.tags")}</h2>
        </div>

        <div className="col-12">
          <IF condition={project.tags.length > 0}>
            {project.tags.map(e => (
              <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
            ))}
          </IF>
          <IF condition={project.tags.length === 0}>
            <p className="mb-2">{I18n.t("phrases.noTags")}</p>
          </IF>
        </div>
      </div>
      {project.programme && (
        <>
          <div className="project-overview__side-panel__associated-programme mt-3 row">
            <div className="col">
              <h2 className="mb-0">{I18n.t("entities.programme")}</h2>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <Pill data-testid="progress-status-label" type={PillTypes.GRAY}>
                {project.programme?.name ? project.programme.name : I18n.t("phrases.standaloneProject")}
              </Pill>
            </div>
          </div>
        </>
      )}

      <div className="row project-overview__side-panel__overview mt-4">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.overview")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <CollapsingText lineClampLength={1} clampIndicator={<ClampButton />}>
            {project.description?.length ? (
              <div
                className="project-overview__side-panel__overview-description"
                dangerouslySetInnerHTML={{ __html: project.description }}
              />
            ) : (
              <div>{I18n.t("phrases.noOverview")}</div>
            )}
          </CollapsingText>
        </div>
      </div>

      <div className="row project-overview__side-panel__objectives mt-4">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.objectives")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div
            className="project-overview__side-panel__objectives-text"
            dangerouslySetInnerHTML={{ __html: project.objectivesText }}
          />
        </div>
      </div>

      <div className="row project-overview__side-panel__owner mt-4">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.projectSponsor")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col mt-2">
          {project.stakeholderOwner ? (
            <Link to={getOrgStakeholderLink(organisationId, project.stakeholderOwner?.id)}>
              <AvatarContent
                type="primary"
                avatarProps={{
                  size: UiSizes.MD,
                  imgSrc: project.stakeholderOwner?.profileImageUrl
                }}
              >
                <h4 className="mb-0">{convertStakeholderToName(project.stakeholderOwner)} </h4>
              </AvatarContent>
            </Link>
          ) : (
            <span className="mb-0">{I18n.t("phrases.noStakeholderOwner")}</span>
          )}
        </div>
      </div>

      <div className="row project-overview__side-panel__project-team mt-4">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.projectTeam")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col mt-2">
          <ProjectTeamCollapsableListView users={project.projectTeam} />
        </div>
      </div>

      <div className="row project-overview__side-panel__locations mt-4">
        <div className="col">
          <h2 className="mb-0">{I18n.t("phrases.locations")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex mt-2">
          {project.locations.length ? (
            <>
              <Icon symbol={IconSymbols.Location} className="mr-2" />
              <h4 className="mb-0 d-inline-block">{project.locations.map(location => location.name).join(", ")}</h4>
            </>
          ) : (
            <span>{I18n.t("phrases.na")}</span>
          )}
        </div>
      </div>

      <StakeholderList
        projectId={+projectId}
        stakeholders={keyStakeholders}
        label={I18n.t("phrases.keyStakeholders")}
      />
      {/* <StakeholderList
        projectId={+projectId}
        stakeholders={keyAudienceStakeholders}
        label={I18n.t("phrases.keyAudiences")}
      /> */}
    </>
  );
};
